import { Page, PageContainer } from 'common/Page'

import { DeploymentHeader } from './components/DeploymentHeader'
import { DeploymentCardLicenses } from './components/licenses/DeploymentCardLicenses'

const DeploymentPageSettings: React.FC = () => {
  return (
    <Page title="Deployment Licenses">
      <PageContainer>
        <DeploymentHeader />
        <DeploymentCardLicenses />
      </PageContainer>
    </Page>
  )
}

export default DeploymentPageSettings
