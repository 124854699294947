import { Box } from '@mui/material'
import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { InlineCode } from 'common/InlineCode'
import { useCopyLicenseToClipboard } from 'common/license/useLicenseCopy'
import { Section } from 'common/Section'
import { readableFileSize } from 'common/utils/human-readable'
import { formatDistanceToNow, isPast, parseISO } from 'date-fns/esm'
import { useCopyToClipboard } from 'react-use'
import { Button, Icon, Table } from 'semantic-ui-react'

import { useRouteDeployment } from '../useRouteDeployment'

const gbToBytes = (gb?: number) => (gb ?? 0) * 1000 * 1000 * 1000

const CopyLicenseButton: React.FC<{
  isActive: boolean
  license: { id: string; deploymentId: string; license: string }
}> = props => {
  const { spacing } = useTheme()
  const { isActive, license } = props
  const { icon, copyLicense } = useCopyLicenseToClipboard(license)
  return isActive ? (
    <Button
      onClick={() => copyLicense()}
      size="tiny"
      color="green"
      icon={icon}
      style={{ padding: spacing(2, 3) }}
      content="Copy"
    />
  ) : (
    <Icon name="remove" />
  )
}

export const DeploymentCardLicenses: React.FC = () => {
  const { colors, spacing } = useTheme()
  const { deployment } = useRouteDeployment()
  const [, copyToClipboard] = useCopyToClipboard()

  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>Licenses</CardHeader>
          <Box color={colors.gray[600]}>
            You will need to contact sales to generate a new license once your
            current one has expired.
          </Box>
        </CardContent>
        <Table
          style={{
            border: 'none',
            borderTop: `1px solid ${colors.gray[300]}`,
            marginTop: 0
          }}
          selectable
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Expiration</Table.HeaderCell>
              <Table.HeaderCell>Flags</Table.HeaderCell>
              <Table.HeaderCell>Max Users</Table.HeaderCell>
              <Table.HeaderCell>Max Teams</Table.HeaderCell>
              <Table.HeaderCell>Max Registered Models</Table.HeaderCell>
              <Table.HeaderCell>Max Storage</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {deployment?.licenses?.nodes?.map((r, i) => {
              const expiresAt = parseISO(r.expiresAt)
              const isActive = !isPast(expiresAt) && i === 0
              const flags = r.flags.map(f => f.name).join(', ')
              return (
                <Table.Row
                  key={r.id}
                  style={{ color: isActive ? undefined : colors.gray[400] }}
                >
                  <Table.Cell>
                    <InlineCode
                      style={{ cursor: 'copy' }}
                      onClick={() => copyToClipboard(r.id)}
                    >
                      {r.id.substring(0, 13)}...
                    </InlineCode>
                  </Table.Cell>
                  <Table.Cell>
                    {formatDistanceToNow(expiresAt, { addSuffix: true })}
                  </Table.Cell>
                  <Table.Cell>
                    {flags ? (
                      flags
                    ) : (
                      <i style={{ color: colors.gray[400] }}>No flags</i>
                    )}
                  </Table.Cell>
                  <Table.Cell>{r.maxUsers}</Table.Cell>
                  <Table.Cell>{r.maxViewOnlyUsers}</Table.Cell>
                  <Table.Cell>{r.maxTeams}</Table.Cell>
                  <Table.Cell>{r.maxRegisteredModels}</Table.Cell>
                  <Table.Cell>
                    {readableFileSize(gbToBytes(r.maxStorageGb), true)}
                  </Table.Cell>
                  <Table.Cell style={{ width: spacing(30) }}>
                    <CopyLicenseButton isActive={isActive} license={r} />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Card>
    </Section>
  )
}
