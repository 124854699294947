/* eslint-disable jsx-a11y/label-has-associated-control */
import { useViewer } from 'common/hooks/useViewer'
import { DeploymentType } from 'generated/deploy'
import { Form, Grid } from 'semantic-ui-react'

import { FormFooter, useDeployForm } from '../DeployForm'
import { NextButton } from './ButtonHelper'
import {
  DeployOptionRadio,
  DeployOptionRadioProps
} from './inputs/DeployOptionRadio'

const DeployTypeRadio: React.FC<{
  onChange?: (value: DeploymentType) => void
  value?: DeploymentType
}> = props => {
  const { onChange, value } = props

  const Radio: React.FC<
    Pick<DeployOptionRadioProps, 'src' | 'name' | 'value'>
  > = props => (
    <DeployOptionRadio
      {...props}
      selected={value === props.value}
      onClick={onChange}
    />
  )

  const { isSre } = useViewer()
  return (
    <>
      <Grid>
        <Grid.Row columns={3}>
          {isSre ? (
            <>
              <Grid.Column>
                <Radio
                  value={DeploymentType.Aws}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Amazon_Web_Services_Logo.svg/2880px-Amazon_Web_Services_Logo.svg.png"
                  name="Amazon"
                />
              </Grid.Column>
              <Grid.Column>
                <Radio
                  value={DeploymentType.Gcp}
                  src="https://logos-world.net/wp-content/uploads/2021/02/Google-Cloud-Emblem.png"
                  name="Google Cloud Platform"
                />
              </Grid.Column>
              <Grid.Column>
                <Radio
                  value={DeploymentType.Azure}
                  src="https://swimburger.net/media/ppnn3pcl/azure.png"
                  name="Azure"
                />
              </Grid.Column>
            </>
          ) : (
            <>
              To create managed install please request access from the Delivery
              Team.
            </>
          )}
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <Radio
              value={DeploymentType.Manual}
              src="https://www.docker.com/wp-content/uploads/2022/03/vertical-logo-monochromatic.png"
              name="Manual"
            />
          </Grid.Column>
          <Grid.Column>
            <Radio
              value={DeploymentType.TerraformTest}
              src="https://dustindortch.files.wordpress.com/2020/08/terraform-logo.png"
              name="Terraform Test"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export const DeployFormPlatform: React.FC = () => {
  const { state, setState, next } = useDeployForm()
  return (
    <Form onSubmit={next}>
      <Form.Field>
        <label>Choose your provider</label>
      </Form.Field>

      <DeployTypeRadio
        value={state.type}
        onChange={type => setState({ ...state, type: type as any })}
      />

      <FormFooter>
        <NextButton type="submit" />
      </FormFooter>
    </Form>
  )
}
