import { useSegment } from 'common/segment'
import { useCallback } from 'react'
import { useCopyToClipboard } from 'react-use'

type CopyLicenseEventProperties = {
  id: string
  deploymentId: string
  license: string
}

export const useCopyLicenseEvent = () => {
  const { analytics } = useSegment()
  return useCallback(
    (license: CopyLicenseEventProperties) =>
      analytics?.track('Copy License', license),
    [analytics]
  )
}

/**
 * Helper function for copying license to clipboard
 */
export const useCopyLicenseToClipboard = (
  license?: CopyLicenseEventProperties
) => {
  const [{ value }, copyToClipboard] = useCopyToClipboard()
  const icon = value === license?.license ? 'check' : 'clone'
  const copyLicenseEvent = useCopyLicenseEvent()
  return {
    icon,
    copyLicense: async () => {
      if (license == null) return
      copyToClipboard(license.license)
      copyLicenseEvent(license)
    }
  }
}
