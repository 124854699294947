import CheckCircle from '@mui/icons-material/CheckCircle'
import Warning from '@mui/icons-material/Warning'
import { Box } from '@mui/material'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { Flex } from 'common/Flex'
import { useViewer } from 'common/hooks/useViewer'
import { isPast, parseISO } from 'date-fns'
import {
  DeploymentVariablesDocument,
  ReleaseStatus,
  useCreateReleaseMutation,
  useDeploymentLicensesQuery,
  useDeploymentVariablesQuery,
  useReleaseQuery,
  useUpdateDeploymentMutation
} from 'generated/deploy'
import first from 'lodash/first'
import React from 'react'
import { useHistory } from 'react-router'
import { Button } from 'semantic-ui-react'

import { useRouteDeployment } from '../useRouteDeployment'

const useDeploymentLatestRelease = () => {
  const { deployment } = useRouteDeployment()
  const releaseId = first(deployment?.releases)?.id ?? ''
  const { data, loading } = useReleaseQuery({
    variables: { id: releaseId },
    pollInterval: 3000,
    skip: releaseId === ''
  })
  const release = data?.release

  const status = release?.status ?? ReleaseStatus.Canceled
  return {
    release,
    isDestroy: release?.isDestroy ?? false,
    isProcessing: [
      ReleaseStatus.Pending,
      ReleaseStatus.ApplyQueued,
      ReleaseStatus.Applying,
      ReleaseStatus.Planning
    ].includes(status),
    loading
  }
}

const DisableDeletionProtection: React.FC = () => {
  const { id } = useRouteDeployment()
  const [update, { loading }] = useUpdateDeploymentMutation()
  const disableProtection = () => {
    update({
      variables: {
        id,
        data: {
          variables: [
            {
              key: 'deletion_protection',
              value: 'false',
              hcl: true,
              category: 'terraform'
            }
          ]
        }
      },
      refetchQueries: [
        { query: DeploymentVariablesDocument, variables: { id } }
      ]
    })
  }

  return (
    <Button
      onClick={disableProtection}
      loading={loading}
      disabled={loading}
      color="red"
      size="large"
    >
      Delete Deployment
    </Button>
  )
}

const DestroyDeployment: React.FC = () => {
  const { release, isProcessing, isDestroy } = useDeploymentLatestRelease()
  const { id } = useRouteDeployment()
  const history = useHistory()

  const [createRelease] = useCreateReleaseMutation()

  const gotoRelease = () => history.push(`/${id}/${release?.id}`)
  const applyDestroyPlan = () =>
    createRelease({
      variables: {
        input: {
          deploymentId: id,
          message: 'Destroy instance.',
          destroy: true
        }
      }
    })

  return (
    <>
      <Box mb={4}>
        Destroy all provisioned resources. This may take up to 2 hours to
        delete.
        {isProcessing && !isDestroy && (
          <Box fontWeight="bold" mt={2}>
            A release is currently in progress. Please wait for all releases to
            finish before destroying.
          </Box>
        )}
      </Box>
      <Flex alignItems="center">
        <Button
          onClick={applyDestroyPlan}
          loading={isProcessing}
          disabled={isProcessing}
          color="red"
          size="large"
        >
          Destroy Instance
        </Button>
        {release != null && isProcessing && (
          <Button
            onClick={gotoRelease}
            size="large"
            ml={2}
            content="View Release"
            icon="eye"
          />
        )}
      </Flex>
    </>
  )
}

const DeleteDeployment: React.FC = () => {
  const { id, deployment } = useRouteDeployment()
  const { data } = useDeploymentLicensesQuery({
    variables: { deploymentId: id }
  })
  const { isViewerAdmin } = useViewer()
  const allLicenseExpired =
    data?.deployment?.licenses?.nodes.every(d =>
      isPast(parseISO(d.expiresAt))
    ) ?? false
  const canDelete =
    isViewerAdmin ||
    deployment?.terraformWorkspaceId != null ||
    allLicenseExpired

  return (
    <>
      <Box mb={4}>
        {!allLicenseExpired && 'Some licenes have not expired yet.'}
      </Box>
      <Button color="red" size="large" disabled={canDelete}>
        Delete Deployment
      </Button>
    </>
  )
}

const useIsDeletionProtectionEnabled = () => {
  const { id } = useRouteDeployment()
  const variablesQuery = useDeploymentVariablesQuery({ variables: { id } })
  const variables = variablesQuery.data?.deployment?.variables ?? []
  const value =
    variables.find(v => v.key === 'deletion_protection')?.value ?? 'true'
  return value == null || value === 'true'
}

const useDetermineDeletionStep = () => {
  const { deployment } = useRouteDeployment()
  const deletionProtectionEnabled = useIsDeletionProtectionEnabled()

  const { isDestroy, isProcessing, loading } = useDeploymentLatestRelease()
  const isDeploymentDestroyed = isDestroy && !isProcessing

  if (deployment?.terraformWorkspaceId == null) return 2
  if (loading || deletionProtectionEnabled) return 0
  if (!isDeploymentDestroyed) return 1

  return 2
}

export const DeploymentSettingsDelete: React.FC = () => {
  const activeStep = useDetermineDeletionStep()
  const { spacing, colors } = useTheme()

  const { isViewerAdmin } = useViewer()

  const icon = (num: number) => {
    if (activeStep < num) return <Warning style={{ color: colors.gray[500] }} />
    if (activeStep === num)
      return <Warning style={{ color: colors.red[500] }} />
    return <CheckCircle style={{ color: colors.gray[500] }} />
  }

  return (
    <Card>
      <CardContent>
        <CardHeader>Destruction and Deletion</CardHeader>
        {isViewerAdmin ? (
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel style={{ marginLeft: spacing(0.5) }} icon={icon(0)}>
                <h4>1. Disable deletion protection</h4>
              </StepLabel>
              <StepContent>
                <Box mb={4}>
                  Part of Weights &amp; Biases, are critical to running the
                  application. These resources need to exist indefinitely so we
                  have a deletion protection flag to makes sure these resources
                  are not deleted accidentially.
                </Box>
                <DisableDeletionProtection />
              </StepContent>
            </Step>

            <Step>
              <StepLabel style={{ marginLeft: spacing(0.5) }} icon={icon(1)}>
                <h4>2. Destroy resources</h4>
              </StepLabel>
              <StepContent>
                <DestroyDeployment />
              </StepContent>
            </Step>

            <Step>
              <StepLabel style={{ marginLeft: spacing(0.5) }} icon={icon(2)}>
                <h4>3. Delete deployment</h4>
              </StepLabel>
              <StepContent>
                <DeleteDeployment />
              </StepContent>
            </Step>
          </Stepper>
        ) : (
          <Box>
            Please contact support at Weights &amp; Biases to delete your
            instance.
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
