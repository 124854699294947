import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { Flex, Spacer } from 'common/Flex'
import { useDisclosure } from 'common/hooks/useDisclosure'
import { useOrganizationByIdsLoader } from 'common/hooks/useOrganizationByIdsLoader'
import { useViewer } from 'common/hooks/useViewer'
import { Section } from 'common/Section'
import { formatDistanceToNow, isBefore } from 'date-fns'
import { parseISO } from 'date-fns/esm'
import { DeploymentQuery, useDeploymentStateQuery } from 'generated/deploy'
import React from 'react'
import { Button } from 'semantic-ui-react'

import { Info } from '../Info'
import { DeploymentDialogEdit } from './DeploymentDialogEdit'

const DeploymentEditButton: React.FC<{ deploymentId: string }> = ({
  deploymentId
}) => {
  const { spacing } = useTheme()
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <DeploymentDialogEdit
        open={isOpen}
        onClose={onClose}
        deploymentId={deploymentId}
      />
      <Button
        basic
        size="tiny"
        color="blue"
        style={{ padding: spacing(2, 3) }}
        content="Edit"
        onClick={onOpen}
      />
    </>
  )
}

type DeploymentCardInfoProps = {
  deployment?: DeploymentQuery['deployment']
}

export const DeploymentCardInfo: React.FC<DeploymentCardInfoProps> = ({
  deployment
}) => {
  const createdAt = deployment ? parseISO(deployment.createdAt) : new Date()

  const { data } = useDeploymentStateQuery({
    variables: { id: deployment?.id ?? '' },
    skip: deployment?.id == null
  })

  const { workspace, state } = data?.deployment ?? {}
  const url = state?.outputs?.find(v => v.name === 'url')?.value
  const workspaceUrl =
    workspace != null
      ? `https://app.terraform.io/app/weights-and-biases/workspaces/${workspace?.name}`
      : null

  const isViewerAdmin = useViewer()
  const organizationId = deployment?.organizationId
  const { getOrganization } = useOrganizationByIdsLoader([organizationId])
  const organization =
    organizationId != null ? getOrganization(organizationId) : null

  const isPOCExpired =
    deployment?.pocExpiresAt != null &&
    isBefore(parseISO(deployment.pocExpiresAt), new Date())
  const isContractExpired =
    deployment?.contractExpiresAt != null &&
    isBefore(parseISO(deployment.contractExpiresAt), new Date())
  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>
            <Flex alignItems="center">
              <span>Deployment</span>
              <Spacer />
              {deployment != null && (
                <DeploymentEditButton deploymentId={deployment?.id} />
              )}
            </Flex>
          </CardHeader>
          <Info name="ID" value={deployment?.id} />
          {url && <Info name="URL" value={<a href={url}>{url}</a>} />}
          {isViewerAdmin && workspaceUrl && (
            <Info
              name="Workspace"
              value={<a href={workspaceUrl}>Terraform Workspace</a>}
            />
          )}
          {isViewerAdmin && workspace?.versionControl?.identifier && (
            <Info name="Git Repo" value={workspace.versionControl.identifier} />
          )}
          {isViewerAdmin && workspace?.versionControl?.branch && (
            <Info name="Git Branch" value={workspace.versionControl.branch} />
          )}
          <Info name="Type" value={deployment?.type} />
          <Info name="Name" value={deployment?.name} />
          {deployment?.description && (
            <Info name="Description" value={deployment.description} />
          )}
          {organization && (
            <Info name="Organization" value={organization?.name} />
          )}
          {/* <Info name="Plan" value="Custom Contract" /> */}
          {/* <Info name="Domain" value="http://localhost:8000" /> */}
          <Info
            name="Created"
            value={formatDistanceToNow(createdAt, { addSuffix: true })}
          />
          <Info name="SFDC Account ID" value={deployment?.sfdcAccountId} />
          <Info
            name="SFDC Opportunity ID"
            value={deployment?.sfdcOpportunityId}
          />
          {deployment?.pocExpiresAt != null && (
            <Info
              name={isPOCExpired ? 'POC Expired' : 'POC Expires in'}
              value={formatDistanceToNow(parseISO(deployment.pocExpiresAt), {
                addSuffix: true
              })}
            />
          )}
          {deployment?.contractExpiresAt != null && (
            <Info
              name={
                isContractExpired ? 'Contract Expired' : 'Contract Expires in'
              }
              value={formatDistanceToNow(
                parseISO(deployment.contractExpiresAt),
                { addSuffix: true }
              )}
            />
          )}
        </CardContent>
      </Card>
    </Section>
  )
}
