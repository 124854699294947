import Box from '@mui/material/Box'
import { useTheme } from '@wandb/ui'
import { Card, CardContent } from 'common/Card'
import { Flex, Spacer } from 'common/Flex'
import { ReleaseStatusIcon, statusIcon } from 'common/release/ReleaseStatusIcon'
import { Section } from 'common/Section'
import { pascalCaseToTitleCase } from 'common/utils/casing'
import { readableDuration } from 'common/utils/human-readable'
import { parseISO } from 'date-fns'
import { differenceInMilliseconds, formatDistanceToNow } from 'date-fns/esm'
import { useReleaseQuery } from 'generated/deploy'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import { Info } from '../Info'
import { InfoReleaseChange } from '../InfoReleaseChange'

export const DeploymentSectionLatestRelease: React.FC<{
  releaseId: string
}> = ({ releaseId }) => {
  const { fontSizes, spacing } = useTheme()
  const { data, loading } = useReleaseQuery({
    variables: { id: releaseId },
    pollInterval: 5000
  })
  const release = data?.release
  const location = useLocation()

  const statusStyle = statusIcon[release?.status ?? 'Pending']?.style
  const color = statusStyle?.color ?? 'inherit'

  const createdAt =
    release?.createdAt == null ? new Date() : parseISO(release.createdAt)

  const { appliedAt, erroredAt } = release?.statusTimestamps ?? {}
  let durationValue: React.ReactNode = '-'
  if (release?.createdAt != null)
    durationValue = readableDuration(
      differenceInMilliseconds(
        erroredAt == null
          ? appliedAt == null
            ? new Date()
            : parseISO(appliedAt)
          : parseISO(erroredAt),
        createdAt
      )
    )

  return (
    <Section>
      <Card>
        <CardContent>
          {release && (
            <>
              <Flex alignItems="center">
                <Box fontSize={fontSizes.xl}>{release.message}</Box>
                <Spacer />
                <Box>
                  <ReleaseStatusIcon status={release.status} />{' '}
                  <span style={{ color }}>
                    {pascalCaseToTitleCase(release.status)}
                  </span>
                </Box>
              </Flex>
              <Flex alignItems="center" style={{ marginTop: spacing(1) }}>
                <InfoReleaseChange {...release.plan} />
                <Box padding={spacing(4)} />
                <Info
                  name="Created"
                  value={formatDistanceToNow(createdAt, { addSuffix: true })}
                />
                <Box padding={spacing(4)} />
                <Info name="Duration" value={durationValue} />
                <Box padding={spacing(4)} />
                <Spacer />
                <Button
                  as={Link}
                  to={`${location.pathname}/${release.id}`}
                  size="small"
                >
                  More details
                </Button>
              </Flex>
            </>
          )}
          {!release && loading && <span>Loading latest release...</span>}
        </CardContent>
      </Card>
    </Section>
  )
}
