/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { styled, useTheme } from '@wandb/ui'
import { Flex } from 'common/Flex'
import { useDisclosure } from 'common/hooks/useDisclosure'
import { useViewer } from 'common/hooks/useViewer'
import { useDeploymentsQuery } from 'generated/deploy'
import { useOrganizationsSearchQuery, useViewerQuery } from 'generated/gorilla'
import first from 'lodash/first'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Form, Icon } from 'semantic-ui-react'

import { DeployFormState, FormFooter, useDeployForm } from '../DeployForm'
import { BackButton, NextButton } from './ButtonHelper'
import { LimitsLicenseOrder } from './inputs/DeployLimits'
import { DeployTrialToggle } from './inputs/DeployTrialToggle'
import { ModalNewOrganization } from './ModalNewOrganization'

/**
 * Creates a options array of the users organizations for semantic ui dropdown
 * input.
 *
 * @note If the viewer is an admin it will show all organizations.
 */
const useOrganizationOptions = () => {
  const { isViewerAdmin } = useViewer()

  const [showMyOrgs, setShowMyOrgs] = useState(true)
  const showAllOrgs = isViewerAdmin && !showMyOrgs

  const [search, setSearch] = useState('')
  const organizationsQuery = useOrganizationsSearchQuery({
    variables: { search, first: 25 },
    skip: !showAllOrgs
  })

  const viewerQuery = useViewerQuery({ fetchPolicy: 'cache-only' })
  const viewer = useMemo(() => viewerQuery.data?.viewer, [viewerQuery])

  const organizations = useMemo(
    () =>
      organizationsQuery.data?.organizations.edges.map(d => d.node) ??
      viewer?.organizations,
    [viewer, organizationsQuery]
  )

  return {
    showMyOrgs,
    setShowMyOrgs,
    loading: organizationsQuery.loading || viewerQuery.loading,
    refetch: async () => {
      await viewerQuery.refetch()
      await organizationsQuery.refetch()
    },
    search,
    setSearch,
    options: useMemo(
      () =>
        organizations?.map(d => ({ key: d.id, value: d.id, text: d.name })) ??
        [],
      [organizations]
    )
  }
}

type FormState = Pick<DeployFormState, 'organizationId' | 'trial' | 'name'>

const Alert = styled.div(
  ({ color, theme: { radii, spacing } }) => `
    padding: ${spacing(4)};
    border-radius: ${radii.md};
    background: ${color};
    margin: ${spacing(4, 0)};
  `
)

const useLicenseForm = (orgOptions: { value: string }[]) => {
  const { state, next, previous, setState } = useDeployForm()
  const licenseForm = useForm<FormState>({ defaultValues: state })
  const { getValues, handleSubmit } = licenseForm
  const updateState = () => setState({ ...state, ...getValues() })
  const onSubmit = handleSubmit(() => {
    updateState()
    next()
  })
  const onBack = () => {
    updateState()
    previous()
  }

  // Select the first organization
  const { watch, setValue } = licenseForm
  const { organizationId } = watch()
  const firstOrgId = first(orgOptions)?.value
  useEffect(() => {
    if (organizationId === '' && firstOrgId != null) {
      setValue('organizationId', firstOrgId)
    }
  }, [organizationId, firstOrgId, setValue])

  return { ...licenseForm, onSubmit, updateState, onBack }
}

const useViewerInfo = () => {
  const { viewer, isViewerAdmin } = useViewer()
  const hasOrganizations = (viewer?.organizations.length ?? 1) > 0

  const { data } = useDeploymentsQuery()
  const hasDeployments = useMemo(
    () => (data?.deployments?.nodes.length ?? 0) > 0,
    [data]
  )

  return { hasOrganizations, hasDeployments, isAdmin: isViewerAdmin }
}

export const DeployFormLicense: React.FC = () => {
  const { hasOrder, licenseOrder } = useDeployForm()
  const { loading, refetch, setSearch, options, showMyOrgs, setShowMyOrgs } =
    useOrganizationOptions()

  const { control, onSubmit, onBack, watch, setValue } = useLicenseForm(options)
  const { organizationId } = watch()

  const { colors, spacing, fontSizes } = useTheme()

  const { hasOrganizations, isAdmin, hasDeployments } = useViewerInfo()
  const {
    isOpen: isOpenNewOrg,
    onOpen: onOpenNewOrg,
    onClose: onCloseNewOrg
  } = useDisclosure()

  return (
    <>
      <ModalNewOrganization
        open={isOpenNewOrg}
        onClose={onCloseNewOrg}
        onCreate={async orgId => {
          await refetch()
          setValue('organizationId', orgId)
          setShowMyOrgs(true)
        }}
      />

      <Form onSubmit={onSubmit}>
        {isAdmin && (
          <Alert color={colors.red[100]}>
            <Box
              fontSize={fontSizes.xl}
              padding={spacing(8)}
              textAlign="center"
            >
              If you are creating a deployment for a customer, you should create
              a <Link to="/admin/order/new">License Order</Link>.
            </Box>
          </Alert>
        )}

        {!hasOrganizations && (
          <Alert color={colors.orange[50]}>
            <Icon
              name="exclamation triangle"
              style={{ color: colors.orange[500], marginRight: spacing(3) }}
            />
            You are not part of a Weights &amp; Biases organization. Create a
            new one, or ask your organization&apos;s admin to invite you.
          </Alert>
        )}

        <Form.Field required>
          <label>Owner of license</label>
          <Flex>
            <Controller
              control={control}
              name="organizationId"
              render={({ field: { value, onChange } }) => (
                <>
                  {hasOrganizations && (
                    <>
                      <Dropdown
                        fluid
                        search
                        loading={loading}
                        selection
                        options={options}
                        value={value}
                        style={{ maxWidth: spacing(100) }}
                        onSearchChange={e => setSearch((e.target as any).value)}
                        onChange={(_, { value }) => onChange(value)}
                        placeholder="Select Organization"
                      />
                      <Box width={spacing(4)} />
                    </>
                  )}
                  {(!hasDeployments || isAdmin) && (
                    <Button
                      type="button"
                      icon="plus"
                      color={hasOrganizations ? undefined : 'green'}
                      content="New Organization"
                      onClick={onOpenNewOrg}
                    />
                  )}
                </>
              )}
            />
          </Flex>
          {isAdmin && (
            <Flex alignItems="center" style={{ fontSize: fontSizes.sm }}>
              <Checkbox
                // size="small"
                checked={!showMyOrgs}
                onChange={() => setShowMyOrgs(!showMyOrgs)}
              />
              <span>Select from all organizations</span>
            </Flex>
          )}
        </Form.Field>

        <Form.Field>
          <label>Limitations</label>

          {hasOrder && licenseOrder != null ? (
            <LimitsLicenseOrder />
          ) : (
            <>
              <Controller
                control={control}
                name="trial"
                render={({ field: { value, onChange } }) => (
                  <DeployTrialToggle value={value} onChange={onChange} />
                )}
              />
            </>
          )}
        </Form.Field>

        <FormFooter>
          <BackButton type="button" onClick={onBack} />
          <NextButton type="submit" disabled={!organizationId} />
        </FormFooter>
      </Form>
    </>
  )
}
