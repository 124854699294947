import { Card, CardContent, CardHeader } from 'common/Card'
import { Section } from 'common/Section'
import { readableDuration } from 'common/utils/human-readable'
import { differenceInMilliseconds, parseISO } from 'date-fns'
import { formatDistanceToNow } from 'date-fns/esm'
import { ReleaseQuery } from 'generated/deploy'

import { Info } from '../Info'
import { InfoReleaseChange } from '../InfoReleaseChange'

export const ReleaseCardInfo: React.FC<{
  release: ReleaseQuery['release']
}> = ({ release }) => {
  const createdAt =
    release?.createdAt == null ? new Date() : parseISO(release.createdAt)

  const { appliedAt, erroredAt } = release?.statusTimestamps ?? {}
  let durationValue = '-'
  if (release?.createdAt != null)
    durationValue = readableDuration(
      differenceInMilliseconds(
        erroredAt == null
          ? appliedAt == null
            ? new Date()
            : parseISO(appliedAt)
          : parseISO(erroredAt),
        createdAt
      )
    )

  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>Release</CardHeader>
          <Info name="ID" value={release?.id} />
          <Info name="Status" value={release?.status} />
          <InfoReleaseChange {...release?.plan} />
          <Info
            name="Created"
            value={formatDistanceToNow(createdAt, { addSuffix: true })}
          />
          <Info name="Duration" value={durationValue} />
        </CardContent>
      </Card>
    </Section>
  )
}
