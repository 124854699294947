/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box } from '@mui/material'
import { Card } from 'common/Card'
import { UseFormRegister } from 'react-hook-form'
import { useToggle } from 'react-use'
import { Checkbox, Form } from 'semantic-ui-react'

type TerraformInputOidcProps = {
  register: UseFormRegister<{
    oidcIssuer: string
    oidcClientId: string
    oidcAuthMethod: string
  }>
}

export const TerraformInputSso: React.FC<TerraformInputOidcProps> = ({
  register
}) => {
  const [show, toggle] = useToggle(false)
  const oidcIssuer = register('oidcIssuer')
  const oidcClientId = register('oidcClientId')
  const oidcAuthMethod = register('oidcAuthMethod')
  return (
    <Form.Field>
      <Checkbox toggle onClick={toggle} checked={show} label="Enable OIDC" />
      {show && (
        <Box marginTop={2}>
          <Card>
            <Box margin={6}>
              <Form.Field>
                <label>OIDC Issuer</label>
                <input {...oidcIssuer} />
              </Form.Field>
              <Form.Field>
                <label>OIDC Auth Method</label>
                <select {...oidcAuthMethod}>
                  <option value="implicit">Implicit</option>
                  <option value="pkce">PKCE</option>
                </select>
              </Form.Field>
              <Form.Field>
                <label>OIDC Client ID</label>
                <input {...oidcClientId} />
              </Form.Field>
            </Box>
          </Card>
        </Box>
      )}
    </Form.Field>
  )
}
