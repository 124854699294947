import { Box, InputBase } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import { styled, useTheme } from '@wandb/ui'
import { LicenseFlagValue, useAllFlagsQuery } from 'generated/deploy'
import { useEffect, useMemo } from 'react'

const OptionName: React.FC = ({ children }) => {
  const { fontSizes } = useTheme()
  return <div style={{ fontSize: fontSizes.md }}>{children}</div>
}

const OptionDescription: React.FC = ({ children }) => {
  const { fontSizes, colors, spacing } = useTheme()
  return (
    <div
      style={{
        fontSize: fontSizes.sm,
        color: colors.grey[600],
        marginLeft: spacing(2)
      }}
    >
      {children}
    </div>
  )
}

const OptionChip = styled(Chip)(p => {
  const {
    theme: { fontSizes, spacing }
  } = p
  return `
    font-size: ${fontSizes.md} !important;
    margin: ${spacing(1, 1, 1, 1)} !important;
  `
})

export const LicenseFlagsInput: React.FC<{
  value: LicenseFlagValue[]
  onChange: (v: LicenseFlagValue[]) => void
  selectAll?: boolean
}> = ({ value, onChange, selectAll }) => {
  const allFlagsQuery = useAllFlagsQuery()
  const flags = useMemo(() => allFlagsQuery.data?.flags ?? [], [allFlagsQuery])
  useEffect(() => {
    if (value.length === 0 && flags.length !== 0 && selectAll) {
      onChange(flags.map(f => f.flag))
    }
  }, [value, flags, onChange, selectAll])

  const { spacing } = useTheme()
  const mappedValues = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => value.map(v => flags.find(f => v === f.flag)!).filter(f => f != null),
    [value, flags]
  )
  return (
    <div
      className="ui fluid multiple selection dropdown"
      style={{ padding: spacing(1) }}
    >
      {mappedValues.map(({ flag, name, description }) => {
        return (
          <Tooltip key={flag} title={description ?? ''}>
            <OptionChip
              label={name}
              onDelete={() => onChange(value.filter(f => f !== flag))}
            />
          </Tooltip>
        )
      })}
      <Autocomplete
        id="license-flags"
        loading={allFlagsQuery.loading}
        multiple
        value={mappedValues}
        onChange={(_, newValue) => onChange(newValue.map(v => v.flag))}
        options={flags}
        disableClearable
        disableCloseOnSelect
        getOptionLabel={option => option.flag}
        isOptionEqualToValue={(option, value) => option.flag === value.flag}
        renderOption={(props, { name, description }) => (
          <Box component="li" {...props}>
            <OptionName>{name}</OptionName>
            <OptionDescription>{description}</OptionDescription>
          </Box>
        )}
        renderInput={params => {
          return (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={{
                ...params.inputProps,
                style: { border: 0 }
              }}
              placeholder="Flags"
              style={{ width: '100%' }}
            />
          )
        }}
      />
    </div>
  )
}
