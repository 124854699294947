import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import { styled, useTheme } from '@wandb/ui'
import { useMemo } from 'react'

const Label = styled.h4``

export const DeployStepper: React.FC<{
  activeStep?: number
  steps: Record<string, React.ReactNode>
}> = ({ activeStep, steps }) => {
  const { spacing } = useTheme()
  const stepsEntries = useMemo(() => Object.entries(steps), [steps])
  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {stepsEntries.map(([name, content]) => (
        <Step key={name}>
          <StepLabel>
            <Label>{name}</Label>
          </StepLabel>
          <StepContent>
            <div style={{ margin: spacing(4) }}>{content}</div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  )
}
