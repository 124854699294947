import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { InlineCode } from 'common/InlineCode'
import { Section } from 'common/Section'
import { snakeCaseToTitleCase } from 'common/utils/casing'
import { DeploymentState } from 'generated/deploy'
import { Table } from 'semantic-ui-react'

export const DeploymentStateOutputCard: React.FC<
  Partial<DeploymentState> & { loading?: boolean }
> = ({ outputs, loading }) => {
  const { colors } = useTheme()
  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>Outputs</CardHeader>
        </CardContent>
        <Table
          style={{
            border: 'none',
            borderTop: `1px solid ${colors.gray[300]}`,
            marginTop: 0
          }}
        >
          <Table.Header>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Header>

          <Table.Body>
            {outputs?.map(o => (
              <Table.Row key={o.name}>
                <Table.Cell>{snakeCaseToTitleCase(o.name)}</Table.Cell>
                <Table.Cell>{o.type}</Table.Cell>
                <Table.Cell>
                  <InlineCode>{o.value}</InlineCode>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          {outputs == null && !loading && (
            <CardContent>No outputs found.</CardContent>
          )}
          {outputs == null && <CardContent>No outputs found.</CardContent>}
        </Table>
      </Card>
    </Section>
  )
}
