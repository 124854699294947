/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/ban-types */
import MuiCard, { CardProps } from '@mui/material/Card'
import MuiCardContent, { CardContentProps } from '@mui/material/CardContent'
import { styled, useTheme } from '@wandb/ui'
import { Header, HeaderProps } from 'semantic-ui-react'

export const Card: React.FC<CardProps> = props => (
  <MuiCard variant="outlined" {...props} />
)

export const CardContent: React.FC<CardContentProps> = props => {
  const { spacing } = useTheme()
  return (
    <MuiCardContent {...props} style={{ margin: spacing(4), ...props.style }} />
  )
}

export const CardSection = styled.div`
  margin: ${p => p.theme.spacing(2, 0)};
`

export const CardHeader: React.FC<HeaderProps> = props => {
  return <Header {...props} />
}
