import { styled } from '@wandb/ui'
import { Spacer } from 'common/Flex'
import { useViewer } from 'common/hooks/useViewer'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import * as S from './Navbar.styles'
import { NavbarAvatar } from './NavbarAvatar'
import { NavbarBreadcrumbs } from './NavbarBreadcrumbs'
import { WBLogo } from './WBLogo'

const NavbarButton = styled(Button)`
  margin-right: ${p => p.theme.spacing(8)} !important;
  color: ${p => p.theme.colors.gray[400]} !important;
  background-color: ${p => p.theme.colors.transparent} !important;
  &:hover {
    background-color: ${p => p.theme.colors.whiteAlpha[200]} !important;
  }
`

export const Navbar: React.FC = () => {
  const { isViewerAdmin } = useViewer()
  return (
    <S.NavbarContainer>
      <S.NavbarLeftContainer>
        <WBLogo />
        <S.Header as={Link} to="/">
          Deploy Manager
        </S.Header>
        <NavbarBreadcrumbs />
        <Spacer />
        <div>
          {isViewerAdmin && (
            <>
              <Link to="/admin/orders/new">
                <Button primary>New License Order</Button>
              </Link>
              <Link to="/admin">
                <NavbarButton>Admin Panel</NavbarButton>
              </Link>
            </>
          )}
        </div>
        <NavbarAvatar />
      </S.NavbarLeftContainer>
    </S.NavbarContainer>
  )
}
