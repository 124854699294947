import { Button, ButtonProps } from 'semantic-ui-react'

export const NextButton: React.FC<ButtonProps> = props => {
  return (
    <Button {...props} primary>
      Next
    </Button>
  )
}

export const BackButton: React.FC<ButtonProps> = props => {
  return <Button {...props}>Back</Button>
}
