/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from '@mui/material'
import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader, CardSection } from 'common/Card'
import { useDisclosure } from 'common/hooks/useDisclosure'
import { InlineCode } from 'common/InlineCode'
import { Section } from 'common/Section'

import { DeploymentManualInfo } from './DeploymentManualInfo/DeploymentManualInfo'

export const DeploymentSectionManual: React.FC = () => {
  const { fontWeights, spacing } = useTheme()
  const { isOpen, onOpen } = useDisclosure()
  return (
    <Section>
      <Card>
        {isOpen ? (
          <CardContent>
            <CardHeader>Set up your local instance</CardHeader>
            <CardHeader style={{ fontWeight: fontWeights.normal }}>
              Deploy
            </CardHeader>
            <CardSection>
              <p>
                Weights &amp; Biases Server is our self-hosted distribution of
                Weights &amp; Biases. It offers enterprises a private instance
                of the Weights &amp; Biases application, with no resource limits
                and with additional enterprise-grade architectural features like
                audit logging and SAML single sign-on.
              </p>
              <p>
                The self hosted server is a single Docker image that is simple
                to deploy. Your W&amp;B data is saved on a persistent volume or
                an external database so data can be preserved across container
                versions. The server requires an instance with at least 4 cores
                and 8GB memory.
              </p>
              <DeploymentManualInfo />
            </CardSection>

            <CardHeader style={{ fontWeight: fontWeights.normal }}>
              Configuration
            </CardHeader>
            <CardSection>
              Your W&amp;B Server comes up ready-to-use on boot. However,
              several advanced configuration options are available, at the
              <InlineCode>/system-admin</InlineCode> page on your server once
              it&apos;s up and running. Copy and paste your license to enable
              more users and teams.
            </CardSection>

            <CardSection>
              To learn more about configuration{' '}
              <a href="https://docs.wandb.ai/guides/hosting/setup/configuration">
                checkout the docs
              </a>
              .
            </CardSection>
          </CardContent>
        ) : (
          <CardContent style={{ paddingBottom: spacing(3) }}>
            Still need to setup your local instance?{' '}
            <Link onClick={onOpen} style={{ cursor: 'pointer' }}>
              See the Local Deployment Setup Guide
            </Link>
          </CardContent>
        )}
      </Card>
    </Section>
  )
}
