import Box from '@mui/material/Box'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { Flex, Spacer } from 'common/Flex'
import { useDisclosure } from 'common/hooks/useDisclosure'
import { Section } from 'common/Section'
import { DeploymentType } from 'generated/deploy'
import { Button, Header } from 'semantic-ui-react'

import { useRouteDeployment } from '../useRouteDeployment'

const DeploymentDialogCreateNotification: React.FC<DialogProps> = props => {
  const { spacing } = useTheme()
  return (
    <Dialog {...props} fullWidth maxWidth="sm">
      <DialogTitle>
        <Header style={{ marginTop: spacing(2) }}>Generate New License</Header>
      </DialogTitle>
      <DialogContent>
        This feature is currently in beta. Please contact sales to unlock this
        feature.
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}

export const DeploymentSettingsNotifications: React.FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { deployment } = useRouteDeployment()
  const isDeploymentManual = deployment?.type === DeploymentType.Manual
  const { colors } = useTheme()
  return (
    <Section>
      <DeploymentDialogCreateNotification open={isOpen} onClose={onClose} />
      <Card>
        <CardContent>
          <CardHeader>
            <Flex>
              <Box>Notifications</Box>
              <Spacer />
              <Button
                disabled={isDeploymentManual}
                size="tiny"
                icon="plus"
                content="Create a Notification"
                onClick={onOpen}
              />
            </Flex>
          </CardHeader>

          <Box>
            Notifications allow you to send messages to other applications based
            on deployment events.
          </Box>

          {isDeploymentManual && (
            <Box paddingTop={2} color={colors.red[400]}>
              Manual deployments do not support notifications.
            </Box>
          )}
        </CardContent>
      </Card>
    </Section>
  )
}
