import { useAuth } from 'common/hooks/useAuth'
import { Navbar } from 'common/navbar/Navbar'
import { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Dimmer, Loader, Transition } from 'semantic-ui-react'

/**
 * Format file path to follow react router conventions.
 */
const formatPathToUrl = (path: string) => {
  let url = path
    .replace('./', '/')
    .replace('.tsx', '')
    .replace('.ts', '')
    .replace('index', '')
    .split('/')
    .map(k => (k.startsWith('_') ? k.replace('_', ':') : k))
    .join('/')

  url = url.endsWith('/') ? url.substr(0, url.length - 1) : url
  url = url.length === 0 ? '/' : url

  return url
}

/**
 * Uses Webpack to read all files in directory.
 *
 * @note `require.context` is a special feature supported by webpack's compiler
 * that allows you to get all matching modules starting from some base
 * directory. The intention is to tell webpack at compile time to transform that
 * expression into a dynamic list of all the possible matching module requests
 * that it can resolve, in turn adding them as build dependencies and allowing
 * you to require them at runtime.
 *
 * @requires webpack
 * @see
 * {@link https://stackoverflow.com/questions/54059179/what-is-require-context}
 */
const importAll = () => {
  const req: any = (require as any).context('./pages', true, /\.(ts|tsx)$/)
  const routes: Array<
    {
      path: string
      Page: React.FC
    } & PageProps
  > = []
  let error404: React.FC | null = null

  const keys: string[] = req.keys()
  for (const key of keys.filter(s => !s.includes('components/'))) {
    const module = req(key)
    if (!module.default) continue

    const url = formatPathToUrl(key)
    if (url === '/:404') {
      error404 = module.default
      continue
    }

    routes.push({
      path: url,
      Page: module.default,
      hideNavbar: module.hideNavbar ?? false,
      publicRoute: module.publicRoute ?? false
    })
  }

  return {
    routes: routes.sort((a, b) => a.path.localeCompare(b.path)).reverse(),
    error404
  }
}

const { routes, error404 } = importAll()

type PageProps = { hideNavbar: boolean; publicRoute: boolean }

const PageRender: React.FC<PageProps> = ({
  hideNavbar,
  publicRoute,
  children
}) => {
  const { loading, goToRegister, isLoggedIn } = useAuth()
  useEffect(() => {
    if (loading || isLoggedIn || publicRoute) return
    goToRegister()
  }, [publicRoute, loading, goToRegister, isLoggedIn])

  const showPrivateRoute = !loading && isLoggedIn
  const showContent = publicRoute || showPrivateRoute

  return (
    <>
      {!publicRoute && (
        <Transition visible={loading} animation="fade" duration={500}>
          <Dimmer active={loading} inverted>
            <Loader indeterminate>Weights & Biases Deployments</Loader>
          </Dimmer>
        </Transition>
      )}
      {showContent && !hideNavbar && <Navbar />}
      {showContent && children}
    </>
  )
}

/**
 * File-system based router.
 *
 * When a file is added to the pages directory it's automatically available as a
 * route. The files inside the pages directory can be used to define most common
 * patterns.
 *
 * Each file must export a react component as default. Additional, you may also
 * export a `hideNavbar` and `publicRoute` boolean property.
 *
 * @note The router will automatically route files named index to the root of
 * the directory.
 * @note The router supports nested files expect for file paths that contain
 * `component`. This means we can couple components with pages.
 * @note To match a dynamic segment you can use an underscore prefix.
 *
 * @example
 * pages/home.tsx ➜ /home
 * pages/sub-route/index.tsx ➜ /sub-route
 * pages/_orgId/settings.tsx ➜ /:orgId/settings
 */
export const FileRoutes: React.FC = () => {
  return (
    <Switch>
      {routes.map(({ path, Page, ...props }) => (
        <Route
          key={path}
          exact={path === '/'}
          path={path}
          component={() => (
            <PageRender {...props}>
              <Page />
            </PageRender>
          )}
        />
      ))}
      {error404 && <Route component={error404} />}
    </Switch>
  )
}
