/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'semantic-ui-react'

import { ArrayToObject, ObjectToArray, TerraformConfigFormFC } from './utils'

type TerraformTestFormState = {
  namespace: string
  subdomain: string
}

const variablesObjectToArray: ObjectToArray<TerraformTestFormState> = obj => {
  return [
    {
      key: 'namespace',
      value: obj.namespace,
      category: 'terraform',
      sensitive: false
    },
    {
      key: 'subdomain',
      value: obj.subdomain,
      category: 'terraform',
      sensitive: false
    }
  ]
}
const variablesArrayToObject: ArrayToObject<TerraformTestFormState> = obj => {
  const namespace =
    obj.find(s => s.key === 'namespace' && s.category === 'terraform')?.key ??
    ''
  const subdomain =
    obj.find(s => s.key === 'subdomain' && s.category === 'terraform')?.key ??
    ''
  return { namespace, subdomain }
}

export const TerraformConfigTerraformTest: TerraformConfigFormFC = props => {
  const { variables, onSubmit } = props

  const defaultValues = useMemo(
    () => variablesArrayToObject(variables ?? []),
    [variables]
  )
  const { register, handleSubmit } = useForm<TerraformTestFormState>({
    defaultValues
  })

  const onSubmitForm = handleSubmit(async data => {
    const arr = variablesObjectToArray(data)
    await onSubmit(arr)
  })

  return (
    <Form onSubmit={onSubmitForm}>
      <Form.Field required>
        <label>Namespace</label>
        <input {...register('namespace')} />
      </Form.Field>
      <Form.Field required>
        <label>Subdomain</label>
        <input {...register('subdomain')} />
      </Form.Field>
      <Button
        type="submit"
        icon="key"
        color="green"
        loading={props.loading}
        content={variables == null ? 'Deploy' : 'Apply'}
      />
    </Form>
  )
}
