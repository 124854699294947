import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split
} from '@apollo/client'
import { config } from 'common/config'

/**
 * Use the service value provided in the request context redirect requests to
 * the proper graphql API.
 *
 * @notes GraphQL Code generator will set the context value automatically based
 * on the folder the graphql file is in.
 */
const createLink = () => {
  const gorillaHttp = createHttpLink({
    uri: config.GORILLA_GRAPHQL_API,
    headers: { 'use-admin-privileges': true },
    credentials: 'include'
  })

  const deployHttp = createHttpLink({
    uri: config.DEPLOY_GRAPHQL_API,
    credentials: 'include'
  })

  return split(
    op => op.getContext().service === 'gorilla',
    gorillaHttp,
    deployHttp
  )
}

/**
 * Apollo Graphql Client
 *
 * @see {@link https://www.apollographql.com/docs/react/}
 */
export const client = new ApolloClient({
  link: createLink(),
  cache: new InMemoryCache()
})
