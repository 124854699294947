import { config } from 'common/config'
import { useViewerQuery } from 'generated/gorilla'
import { useHistory } from 'react-router'

const redirect = (url: string) => (window.location.href = url)

export const useAuth = () => {
  const { loading, data } = useViewerQuery()
  const isLoggedIn = data?.viewer != null
  const logout = (redirectTo?: string) =>
    redirect(
      `${config.LOGOUT_REDIRECT}?redirect_to=${encodeURIComponent(
        redirectTo ?? window.location.href
      )}`
    )
  const login = (redirectTo?: string) =>
    redirect(
      `${config.LOGIN_REDIRECT}?redirect_to=${encodeURIComponent(
        redirectTo ?? window.location.href
      )}`
    )

  const history = useHistory()
  const goToRegister = () => history.push('/register')

  return { loading, isLoggedIn, logout, login, goToRegister }
}
