import Box from '@mui/material/Box'
import { styled, useTheme } from '@wandb/ui'
import { Card, CardContent } from 'common/Card'
import { Flex } from 'common/Flex'
import { useViewer } from 'common/hooks/useViewer'
import { Page, PageContainer, PageHeader } from 'common/Page'
import { useDeploymentsQuery } from 'generated/deploy'
import { Button, Header, Icon, Loader } from 'semantic-ui-react'

import { DeployForm, useLicenseOrder } from './components/DeployForm'

const WarnText = styled.div`
  font-size: ${p => p.theme.fontSizes.xl};
  font-weight: ${p => p.theme.fontWeights.semibold};
  letter-spacing: ${p => p.theme.letterSpacings.wide};
`

const WarningContent: React.FC = ({ children }) => {
  const { spacing } = useTheme()
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      style={{
        textAlign: 'center',
        height: spacing(100),
        maxWidth: spacing(150),
        margin: 'auto'
      }}
    >
      {children}
    </Flex>
  )
}

const DeployCardContent: React.FC = () => {
  const { spacing, colors } = useTheme()
  const { isClaimed, deleteParams, hasOrder } = useLicenseOrder()
  const deploymentsQuery = useDeploymentsQuery()
  const hasDeployment =
    (deploymentsQuery.data?.deployments?.nodes.length ?? 0) > 0
  const { isViewerAdmin } = useViewer()

  if (deploymentsQuery.loading) {
    return (
      <Box minHeight={500} padding={8}>
        <Loader indeterminate active inline="centered">
          Loading form...
        </Loader>
      </Box>
    )
  }

  if (isClaimed) {
    return (
      <WarningContent>
        <WarnText>License order is already claimed</WarnText>
        <div style={{ marginTop: spacing(4) }}>
          <Button color="red" onClick={deleteParams}>
            Clear Order
          </Button>
        </div>
      </WarningContent>
    )
  }

  if (!hasOrder && hasDeployment && !isViewerAdmin) {
    return (
      <WarningContent>
        <div>
          <Icon
            name="warning sign"
            style={{ fontSize: '2em', color: colors.red[500] }}
          />
        </div>
        <Header>Your account already has a deployment</Header>
        <span>
          Contact sales if you would like to create mutiple deployments.
        </span>
        <div style={{ marginTop: spacing(10) }}>
          <Button as="a" href="https://wandb.ai/site/contact" size="large">
            Contact sales
            <Icon
              style={{ marginLeft: spacing(4) }}
              name="long arrow alternate right"
            />
          </Button>
        </div>
      </WarningContent>
    )
  }

  return <DeployForm hidePlatformStep={!isViewerAdmin} />
}

const Deploy: React.FC = () => {
  const { spacing } = useTheme()
  return (
    <Page title="New Deployment">
      <PageContainer maxWidth="md">
        <PageHeader style={{ paddingBottom: spacing(4) }}>
          Get a License for W&amp;B Local
        </PageHeader>
        <Card>
          <CardContent>
            <DeployCardContent />
          </CardContent>
        </Card>
      </PageContainer>
    </Page>
  )
}

export default Deploy
export const publicRoute = true
