/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box } from '@mui/material'
import { Card } from 'common/Card'
import { useState } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { Checkbox, Form } from 'semantic-ui-react'

type TerraformInputAuth0Props = {
  register: UseFormRegister<{ auth0Domain: string; auth0ClientId: string }>
}

export const TerraformInputAuth0: React.FC<TerraformInputAuth0Props> = ({
  register
}) => {
  const [show, setShow] = useState(false)
  const toggle = () => setShow(s => !s)
  const domain = register('auth0Domain')
  const clientId = register('auth0ClientId')
  return (
    <Form.Field>
      <Checkbox toggle onClick={toggle} checked={show} label="Enable Auth0" />
      {show && (
        <Box marginTop={2}>
          <Card>
            <Box margin={6}>
              <Form.Field>
                <label>Auth0 Domain</label>
                <input {...domain} />
              </Form.Field>
              <Form.Field>
                <label>Auth0 Client ID</label>
                <input {...clientId} />
              </Form.Field>
            </Box>
          </Card>
        </Box>
      )}
    </Form.Field>
  )
}
