import { styled } from '@wandb/ui'

import { NAVBAR_HEIGHT } from './Navbar.styles'

const SmallDot = styled.div`
  transition-property: transform;
  border-radius: 100%;
  flex-shrink: 0;
  background: ${p => p.theme.colors.yellow[700]};
  height: 6px;
  width: 6px;
  margin: 3px 0;
`

const BigDot = styled.div`
  transition-property: transform;
  border-radius: 100%;
  flex-shrink: 0;
  background: ${p => p.theme.colors.yellow[700]};
  height: 10px;
  width: 10px;
  margin: 2px 0;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
`

const Container = styled.div<{ scale?: number; height?: string }>`
  height: ${p => p.height};
  display: flex;
  justify-content: center;
  cursor: pointer;
  transform: scale(${p => p.scale});
  overflow: hidden;
`

export const WBLogo: React.FC<{ height?: string; scale?: number }> = ({
  scale = 0.5,
  height = NAVBAR_HEIGHT.toString()
}) => {
  return (
    <Container height={height} scale={scale}>
      <Column>
        <SmallDot />
        <BigDot />
        <SmallDot />
        <BigDot />
      </Column>
      <Column style={{ marginTop: 5, marginBottom: -5 }}>
        <SmallDot />
        <SmallDot />
        <BigDot />
        <SmallDot />
      </Column>
      <Column>
        <SmallDot />
        <BigDot />
        <SmallDot />
        <SmallDot />
      </Column>
    </Container>
  )
}
