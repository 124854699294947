/* eslint-disable jsx-a11y/label-has-associated-control */
import Box from '@mui/material/Box'
import { Card } from 'common/Card'
import { useMemo, useState } from 'react'
import { Controller, useForm, UseFormRegister } from 'react-hook-form'
import { Checkbox, Dropdown, Form } from 'semantic-ui-react'

import { TerraformInputAuth0 } from './TerraformInputAuth0'
import {
  ArrayToObject,
  findValue,
  ObjectToArray,
  TerraformConfigFormFC,
  TerraformConfigFormFCProps,
  tshirtSizeOptions,
  variable
} from './utils'

/**
 * The options here should match the options for the `region` variable in
 * aws/single-teant/variables.tf
 *
 * @link https://docs.aws.amazon.com/general/latest/gr/rande.html
 */
const awsRegionsOptions = Object.entries({
  'US East (Ohio)': 'us-east-2',
  'US East (N. Virginia)': 'us-east-1',
  'US West (N. California)': 'us-west-1',
  'US West (Oregon)': 'us-west-2',
  // 'Africa (Cape Town)': 'af-south-1',
  // 'Asia Pacific (Hong Kong)': 'ap-east-1',
  'Asia Pacific (Mumbai)': 'ap-south-1',
  // 'Asia Pacific (Osaka)': 'ap-northeast-3',
  'Asia Pacific (Seoul)': 'ap-northeast-2',
  'Asia Pacific (Singapore)': 'ap-southeast-1',
  'Asia Pacific (Sydney)': 'ap-southeast-2',
  'Asia Pacific (Tokyo)': 'ap-northeast-1',
  'Canada (Central)': 'ca-central-1',
  // 'China (Beijing)': 'cn-north-1',
  // 'China (Ningxia)': 'cn-northwest-1',
  'Europe (Frankfurt)': 'eu-central-1',
  'Europe (Ireland)': 'eu-west-1',
  'Europe (London)': 'eu-west-2',
  'Europe (Milan)': 'eu-south-1',
  // 'Europe (Paris)': 'eu-west-3',
  'Europe (Stockholm)': 'eu-north-1'
  // 'Middle East (Bahrain)': 'me-south-1',
  // 'South America (São Paulo)': 'sa-east-1'
}).map(([text, value]) => ({ value, text: `${text} [${value}]` }))

type TerraformAwsFormState = {
  subdomain: string
  region: string
  allowedInboundCidr: string[]
  allowedInboundIpV6Cidr: string[]
  wandbImage: string

  auth0Domain: string
  auth0ClientId: string

  bucketName: string
  bucketKmsKeyArn: string
  tshirtSize: string
}

const variablesObjectToArray: ObjectToArray<TerraformAwsFormState> = obj => {
  return [
    variable('subdomain', obj.subdomain),
    variable('region', obj.region),
    variable('allowed_inbound_cidr', obj.allowedInboundCidr),
    variable('allowed_inbound_ipv6_cidr', obj.allowedInboundIpV6Cidr),
    variable('auth0_domain', obj.auth0Domain),
    variable('auth0_client_id', obj.auth0ClientId),

    variable('bucket_name', obj.bucketName),
    variable('bucket_kms_key_arn', obj.bucketKmsKeyArn),
    variable('size', obj.tshirtSize)
  ]
}

const variablesArrayToObject: ArrayToObject<
  TerraformAwsFormState
> = variables => {
  const find = (v: string) => findValue(variables, v)
  const allowedInboundCidr = find('allowed_inbound_cidr')
  const allowedInboundIpV6Cidr = find('allowed_inbound_ip_v6_cidr')

  return {
    subdomain: find('subdomain') ?? '',
    region: find('region') ?? '',
    allowedInboundCidr:
      allowedInboundCidr != null
        ? JSON.parse(allowedInboundCidr)
        : ['0.0.0.0/0'],
    allowedInboundIpV6Cidr:
      allowedInboundIpV6Cidr != null
        ? JSON.parse(allowedInboundIpV6Cidr)
        : ['::/0'],
    wandbImage: find('wandb_image') ?? 'wandb/local',
    auth0Domain: find('auth0_domain') ?? '',
    auth0ClientId: find('auth0_client_id') ?? '',
    bucketName: find('bucket_name') ?? '',
    bucketKmsKeyArn: find('bucket_kms_key_arn') ?? '',
    tshirtSize: find('tshirt_size') ?? 'small'
  }
}

const useAwsForm = (props: TerraformConfigFormFCProps) => {
  const { variables, onSubmit } = props
  const defaultValues = useMemo(
    () => variablesArrayToObject(variables ?? []),
    [variables]
  )
  const form = useForm<TerraformAwsFormState>({ defaultValues })

  const onSubmitForm = form.handleSubmit(async data => {
    const arr = variablesObjectToArray(data)
    await onSubmit(arr)
  })
  return { ...form, onSubmitForm, defaultValues }
}

type TerraformInputByobProps = {
  register: UseFormRegister<{ bucketName: string; bucketKmsKeyArn: string }>
}

const TerraformInputAwsByob: React.FC<TerraformInputByobProps> = props => {
  const { register } = props
  const [show, setShow] = useState(false)
  const toggle = () => setShow(s => !s)

  const bucketName = register('bucketName')
  const bucketKmsKeyArn = register('bucketKmsKeyArn')

  return (
    <Form.Field>
      <Checkbox
        toggle
        onClick={toggle}
        checked={show}
        label="Enable bring your own bucket (BYOB)"
      />

      {show && (
        <Box marginTop={2}>
          <Card>
            <Box margin={6}>
              <Form.Field required>
                <label>Bucket Name</label>
                <input {...bucketName} />
              </Form.Field>
              <Form.Field required>
                <label>Bucket KMS Key ARN</label>
                <input {...bucketKmsKeyArn} />
              </Form.Field>
            </Box>
          </Card>
        </Box>
      )}
    </Form.Field>
  )
}

export const TerraformConfigAws: TerraformConfigFormFC = props => {
  const { control, register, onSubmitForm, defaultValues } = useAwsForm(props)
  const [ipCidr, setIpCidr] = useState<string[]>(
    defaultValues.allowedInboundCidr
  )
  const [ipV6Cidr, setIpV6Cidr] = useState<string[]>(
    defaultValues.allowedInboundIpV6Cidr
  )

  const { variables } = props
  const alreadyHasRegion = useMemo(
    () => findValue(variables ?? [], 'region') != null,
    [variables]
  )
  const alreadyHasSubdomain = useMemo(
    () => findValue(variables ?? [], 'subdomain') != null,
    [variables]
  )

  return (
    <Form onSubmit={onSubmitForm}>
      <Form.Group widths="equal">
        <Form.Field required disabled={alreadyHasRegion}>
          <label>Region</label>
          <Controller
            control={control}
            name="region"
            render={({ field: { value, onChange } }) => (
              <Dropdown
                fluid
                search
                selection
                upward
                autoComplete="off"
                options={awsRegionsOptions}
                value={value}
                onChange={(_, { value }) => onChange(value)}
                placeholder="Select Region"
              />
            )}
          />
        </Form.Field>
        <Form.Field required disabled={alreadyHasSubdomain}>
          <label>Subdomain</label>
          <input {...register('subdomain')} />
        </Form.Field>
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Field>
          <label>Allowed IP CIDRs</label>
          <Controller
            control={control}
            name="allowedInboundCidr"
            render={({ field: { value, onChange } }) => (
              <Dropdown
                fluid
                search
                selection
                multiple
                allowAdditions
                autoComplete="off"
                noResultsMessage={null}
                value={value}
                options={ipCidr.map(value => ({ value, text: value }))}
                onAddItem={(_, { value }) =>
                  setIpCidr([...ipCidr, value as string])
                }
                onChange={(_, { value }) => onChange(value)}
                placeholder="IP CIDR"
              />
            )}
          />
        </Form.Field>

        <Form.Field>
          <label>Allowed IPv6 CIDRs</label>
          <Controller
            control={control}
            name="allowedInboundIpV6Cidr"
            render={({ field: { value, onChange } }) => (
              <Dropdown
                fluid
                search
                selection
                multiple
                allowAdditions
                autoComplete="off"
                noResultsMessage={null}
                value={value}
                options={ipV6Cidr.map(value => ({ value, text: value }))}
                onAddItem={(_, { value }) =>
                  setIpV6Cidr([...ipV6Cidr, value as string])
                }
                onChange={(_, { value }) => onChange(value)}
                placeholder="IP v6 CIDR"
              />
            )}
          />
        </Form.Field>
      </Form.Group>

      <Form.Field>
        <label>T-Shirt Size</label>
        <Controller
          control={control}
          name="tshirtSize"
          render={({ field: { value, onChange } }) => (
            <Dropdown
              fluid
              selection
              options={tshirtSizeOptions}
              value={value}
              onChange={(_, { value }) => onChange(value)}
              placeholder="Select T-Shirt Size"
            />
          )}
        />
      </Form.Field>

      <TerraformInputAwsByob register={register as any} />
      <TerraformInputAuth0 register={register as any} />

      {props.footer}
    </Form>
  )
}
