import { Card, CardContent, CardHeader } from 'common/Card'
import { TerraformConfigRender } from 'common/terraform/TerraformConfigRender'
import {
  DeploymentVariableInput,
  useDeploymentVariablesQuery,
  useUpdateDeploymentMutation
} from 'generated/deploy'
import { Button } from 'semantic-ui-react'

import { useRouteDeployment } from '../useRouteDeployment'

export const DeploymentSettingsConfig: React.FC = () => {
  const { id, deployment, loading } = useRouteDeployment()
  const { data } = useDeploymentVariablesQuery({
    variables: { id }
  })

  const [updateDeployment, { loading: updating }] =
    useUpdateDeploymentMutation()

  const update = async (newVariables: DeploymentVariableInput[]) => {
    const data = { variables: newVariables }
    await updateDeployment({ variables: { id, data } })
  }

  const variable = data?.deployment?.variables
  if (deployment == null || variable == null)
    return (
      <Card>
        <CardContent>
          <CardHeader>Configuration</CardHeader>
        </CardContent>
      </Card>
    )

  const { type } = deployment
  return (
    <Card>
      <CardContent>
        <CardHeader>Configuration</CardHeader>
        <TerraformConfigRender
          type={type}
          variables={variable}
          loading={loading}
          onSubmit={update}
          footer={<Button primary loading={updating} content="Apply" />}
        />
      </CardContent>
    </Card>
  )
}
