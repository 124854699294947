/* eslint-disable jsx-a11y/label-has-associated-control */
import { useTheme } from '@wandb/ui'
import { useAuth } from 'common/hooks/useAuth'
import { useViewer } from 'common/hooks/useViewer'
import { useEffect } from 'react'
import { Button, Form, Header, Icon } from 'semantic-ui-react'

import { useDeployForm } from '../DeployForm'

const Feature: React.FC = ({ children }) => {
  const { spacing, colors } = useTheme()
  return (
    <div style={{ margin: spacing(2, 0) }}>
      <Icon
        name="check"
        style={{
          marginTop: 1,
          width: spacing(12),
          color: colors.green[400]
        }}
      />
      <span>{children}</span>
    </div>
  )
}

const LoginForm: React.FC = () => {
  const { spacing, fontSizes } = useTheme()
  const { login } = useAuth()
  return (
    <Form>
      <Header as="h4" style={{ padding: 0, fontSize: fontSizes.lg }}>
        Log in to set up your Weights &amp; Biases deployment.
      </Header>
      <Feature>Choose where to host your install</Feature>
      <Feature>Create and manage teams</Feature>
      <Feature>Connect scalable storage buckets</Feature>
      <Feature>Unlock high availability features</Feature>

      <Button
        primary
        content="Continue"
        style={{ marginTop: spacing(4) }}
        onClick={() => login(window.location.href)}
      />
    </Form>
  )
}

// TODO: Delete once we know we dont need a signup form
// const useSignupForm = () => {
//   const form = useForm<{ username: string; name: string }>()

//   const [createEntity, entity] = useCreateEntityMutation()
//   const [updateUser, user] = useUpdateUserMutation()

//   const { watch } = form
//   const { name, username } = watch()
//   const submitDisabled = !name || !username
//   const loading = user.loading || entity.loading
//   const error = user.error ?? entity.error

//   const { handleSubmit } = form
//   const { next } = useDeployForm()
//   const onSubmit = handleSubmit(async ({ username, name }) => {
//     await createEntity({ variables: { name: username } })
//     await updateUser({ variables: { name } })
//     next()
//   })

//   return { ...form, onSubmit, loading, submitDisabled, error }
// }
// const SignupForm: React.FC = () => {
//   const { colors, spacing, fontSizes } = useTheme()
//   const { register, onSubmit, error, loading, submitDisabled } = useSignupForm()
//   const maxWidth = spacing(75)

//   return (
//     <Form onSubmit={onSubmit}>
//       <Form.Field required style={{ maxWidth }}>
//         <label>Full Name</label>
//         <input {...register('name')} maxLength={64} />
//       </Form.Field>
//       <Form.Field required style={{ maxWidth }}>
//         <label>Username</label>
//         <input {...register('username')} maxLength={64} />
//         <span style={{ fontSize: fontSizes.sm, color: colors.gray[500] }}>
//           Choose your username carefully! It cannot be changed after signup.
//         </span>
//       </Form.Field>

//       <FormFooter>
//         <Button
//           type="submit"
//           primary
//           loading={loading}
//           disabled={submitDisabled}
//           content="Next"
//         />
//       </FormFooter>

//       <div style={{ padding: spacing(4, 0, 0, 0), color: colors.red[300] }}>
//         {(error?.networkError as any)?.result?.errors?.[0]?.message ??
//           error?.message}
//       </div>
//     </Form>
//   )
// }

export const DeployFormAuthenticate: React.FC = () => {
  const { viewer, loading } = useViewer()
  const { next } = useDeployForm()

  const isAlreadyLoggedIn = !loading && viewer != null
  useEffect(() => {
    if (isAlreadyLoggedIn) next()
  }, [isAlreadyLoggedIn, next])

  if (viewer == null) return <LoginForm />

  return null
}
