import { styled, useTheme } from '@wandb/ui'
import { Flex } from 'common/Flex'
import { useState } from 'react'

import { AwsEc2, AwsFargate } from './options/Aws'
import { GcpComputeEngine } from './options/Gcp'
import { Kubernetes } from './options/Kubnernetes'
import { Terraform } from './options/Terraform'
import { WbCli } from './options/WbCli'

const DeployOption = styled.div<{ active?: boolean }>(
  ({
    active,
    theme: { colors, fontSizes, letterSpacings, spacing, radii }
  }) => `
    font-size: ${fontSizes.lg};
    color: ${active ? colors.gray[800] : colors.gray[500]};
    letter-spacing: ${letterSpacings.wide};
    padding: ${spacing(2, 4)};
    cursor: pointer;
    border-top-left-radius: ${radii.lg};
    border-bottom-left-radius: ${radii.lg};
    background-color: ${active ? colors.gray[100] : 'transparent'};

    &:hover {
      color: ${active ? colors.gray[800] : colors.gray[600]};
    }
  `
)

const useInfoOptions = () => {
  return {
    'W&B CLI w/ Docker': <WbCli />,
    Terraform: <Terraform />,
    Kubernetes: <Kubernetes />,
    'AWS Fargate': <AwsFargate />,
    'AWS EC2': <AwsEc2 />,
    'GCP Compute Engine': <GcpComputeEngine />
  }
}

export const DeploymentManualInfo: React.FC = () => {
  const { colors, spacing } = useTheme()
  const options = useInfoOptions()
  const [activeOption, setActiveOption] = useState('W&B CLI w/ Docker')
  return (
    <Flex style={{ margin: spacing(6, 0, 0, 0) }}>
      <div
        style={{
          borderRight: `1px solid ${colors.gray[400]}`,
          flexShrink: 0
        }}
      >
        {Object.keys(options).map(k => (
          <DeployOption
            key={k}
            active={k === activeOption}
            onClick={() => setActiveOption(k)}
          >
            {k}
          </DeployOption>
        ))}
      </div>
      <div style={{ margin: spacing(0, 5), flexGrow: 1, overflowY: 'hidden' }}>
        {(options as any)[activeOption]}
      </div>
    </Flex>
  )
}
