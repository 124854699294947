/* eslint-disable jsx-a11y/label-has-associated-control */
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from '@wandb/ui'
import { useViewer } from 'common/hooks/useViewer'
import {
  DeploymentUpdateInput,
  useDeleteDeploymentMutation,
  useDeploymentQuery,
  useUpdateDeploymentMutation
} from 'generated/deploy'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Button, Form, Header } from 'semantic-ui-react'

export const DeploymentDialogEdit: React.FC<
  DialogProps & { deploymentId: string }
> = ({ deploymentId, ...props }) => {
  const { isSre } = useViewer()
  const {
    data,
    loading: queryLoading,
    refetch
  } = useDeploymentQuery({ variables: { id: deploymentId } })
  const [updateDeployment, { loading: mutationLoading }] =
    useUpdateDeploymentMutation()
  const { handleSubmit, register } = useForm<DeploymentUpdateInput>()
  const onSubmit = handleSubmit(async (data, event) => {
    await updateDeployment({ variables: { id: deploymentId, data } })
    await refetch()
    props.onClose?.(event as any, 'escapeKeyDown')
  })

  const history = useHistory()
  const deployment = data?.deployment
  const [deleteDeployment] = useDeleteDeploymentMutation({
    variables: { id: deploymentId },
    onCompleted: () => history.goBack()
  })

  const { spacing } = useTheme()
  return (
    <Dialog {...props} fullWidth maxWidth="sm">
      <DialogTitle>
        <Header style={{ marginTop: spacing(2) }}>Update Deployment</Header>
      </DialogTitle>

      <DialogContent>
        <Form onSubmit={onSubmit}>
          <Form.Field>
            <label>ID</label>
            <input disabled defaultValue={deployment?.id} />
          </Form.Field>

          <Form.Field>
            <label>Type</label>
            <input disabled defaultValue={deployment?.type} />
          </Form.Field>

          <Form.Field required>
            <label>Name</label>
            <input {...register('name')} defaultValue={deployment?.name} />
          </Form.Field>

          <Form.Field>
            <label>Description</label>
            <textarea
              {...register('description')}
              rows={2}
              defaultValue={deployment?.description ?? ''}
            />
          </Form.Field>

          <DialogActions>
            {isSre && (
              <Button
                type="button"
                color="red"
                loading={queryLoading || mutationLoading}
                content="Update"
                icon="edit"
                onClick={() => deleteDeployment()}
              >
                Delete Deployment
              </Button>
            )}

            <Button
              primary
              loading={queryLoading || mutationLoading}
              content="Update"
              icon="edit"
            />
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
