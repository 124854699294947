import { DeploymentVariable, DeploymentVariableInput } from 'generated/deploy'

export type ObjectToArray<T> = (obj: T) => DeploymentVariableInput[]
export type ArrayToObject<T> = (variables: DeploymentVariable[]) => T

export type TerraformConfigFormFCProps = {
  variables?: DeploymentVariable[]
  loading?: boolean
  onSubmit: (variables: DeploymentVariableInput[]) => Promise<void> | void
  footer?: React.ReactElement
}
export type TerraformConfigFormFC = React.FC<TerraformConfigFormFCProps>

export type TerraformVariableMap<
  T extends Record<string, string | undefined> = Record<
    string,
    string | undefined
  >
> = Record<keyof T, DeploymentVariableInput>

export const findValue = (
  variables: DeploymentVariable[],
  key: string,
  category?: string
) =>
  variables.find(s => s.key === key && s.category === (category ?? 'terraform'))
    ?.value

export const variable = (
  key: string,
  value: unknown,
  category = 'terraform',
  sensitive = false
): DeploymentVariableInput => ({
  key,
  value: typeof value === 'string' ? value : JSON.stringify(value),
  category,
  sensitive,
  hcl: typeof value !== 'string'
})

export const tshirtSizeOptions = [
  { key: 's', text: 'Small', value: 'small' },
  { key: 'm', text: 'Medium', value: 'medium' },
  { key: 'l', text: 'Large', value: 'large' },
  { key: 'xl', text: 'X-Large', value: 'xlarge' },
  { key: 'xxl', text: 'XX-Large', value: 'xxlarge' }
]
