import { styled, useTheme } from '@wandb/ui'
import { Flex, Spacer } from 'common/Flex'

export const Section = styled.section`
  margin: ${p => p.theme.spacing(10, 0)};
`

export const SectionTitle = styled.h3`
  margin: 0;
  padding: 0;
  color: ${p => p.theme.colors.gray[700]};
  font-weight: ${p => p.theme.fontWeights.semibold};
  font-size: ${p => p.theme.fontSizes.xl};
`

export const SectionHeader: React.FC<{
  icon?: string
  children: string
  action?: React.ReactNode
}> = ({ children, action }) => {
  const { spacing } = useTheme()
  return (
    <Flex style={{ margin: spacing(4, 0) }} alignItems="center">
      <SectionTitle>{children}</SectionTitle>
      <Spacer />
      {action}
    </Flex>
  )
}
