import { useTheme } from '@wandb/ui'
import {
  DeploymentVariablesDocument,
  useDeploymentVariablesQuery,
  useUpdateDeploymentMutation,
  useVersionsQuery
} from 'generated/deploy'
import { useMemo } from 'react'
import { Dropdown } from 'semantic-ui-react'

import { useRouteDeployment } from './useRouteDeployment'

const useDeploymentVersion = () => {
  const { id } = useRouteDeployment()
  const { data, loading: variablesLoading } = useDeploymentVariablesQuery({
    variables: { id }
  })
  const variables = data?.deployment?.variables ?? []
  const version = variables.find(v => v.key === 'wandb_version')?.value

  const [update, { loading: updateLoading }] = useUpdateDeploymentMutation()
  const setVersion = (version: string) =>
    update({
      variables: {
        id,
        data: {
          variables: [
            { key: 'wandb_version', value: version, category: 'terraform' }
          ]
        }
      },
      refetchQueries: [
        { query: DeploymentVariablesDocument, variables: { id } }
      ]
    })

  const loading = variablesLoading || updateLoading
  return { version, setVersion, loading }
}

export const DeploymentVersionSelector: React.FC = () => {
  const { spacing } = useTheme()

  const { loading: deploymentLoading } = useRouteDeployment()
  const { data, loading: versionsLoading } = useVersionsQuery()

  const options = useMemo(
    () =>
      (data?.versions?.results ?? [])
        .map(v => ({
          text: v.name,
          value: v.name
        }))
        .filter(v => v.text.includes('.') || v.text.includes('latest')),
    [data]
  )

  const {
    version,
    setVersion,
    loading: loadingVersion
  } = useDeploymentVersion()

  const loading = deploymentLoading || versionsLoading || loadingVersion

  return (
    <Dropdown
      fluid
      search
      value={version ?? 'latest'}
      loading={loading}
      selection
      options={options}
      style={{ maxWidth: spacing(40) }}
      // onSearchChange={e => setSearch((e.target as any).value)}
      onChange={(_, { value }) =>
        typeof value === 'string' && setVersion(value)
      }
    />
  )
}
