/* eslint-disable jsx-a11y/no-autofocus */
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import SendIcon from '@mui/icons-material/Send'
import { Box, Button, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { Spacer } from 'common/Flex'
import { Section } from 'common/Section'
import {
  OrganizationQuery,
  useAddUserToOrganizationMutation
} from 'generated/gorilla'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

const InviteButton: React.FC<{ id: string; name: string }> = ({ id, name }) => {
  const [open, setOpen] = useState(false)
  const { colors } = useTheme()
  const [addUser, { loading }] = useAddUserToOrganizationMutation()
  const { handleSubmit, register } = useForm({ defaultValues: { email: '' } })
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        color="secondary"
      >
        Invite someone
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent sx={{ textAlign: 'center' }}>
          <Box sx={{ marginTop: 5 }}>
            <EmailOutlinedIcon
              sx={{ width: 50, height: 50, color: colors.grey[600] }}
            />
          </Box>
          <Box sx={{ marginBottom: 8 }}>
            <Typography variant="h3" gutterBottom>
              Invite a member to {name}
            </Typography>
          </Box>

          <DialogContentText sx={{ margin: '10px 0' }}>
            Invite users to your organization so they can also manage
            deployments.
          </DialogContentText>

          <form
            onSubmit={handleSubmit(async data => {
              await addUser({
                variables: {
                  input: {
                    userName: data.email.trim(),
                    organizationId: id,
                    userOrgRole: 'admin'
                  }
                }
              })
              setOpen(false)
            })}
          >
            <Box
              sx={{
                display: 'flex',
                marginTop: 2,
                alignItems: 'center',
                gap: 3
              }}
            >
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
                {...register('email')}
              />
              <Spacer />
              <Button
                disabled={loading}
                type="submit"
                variant="contained"
                sx={{ marginTop: 6, padding: '0 25px' }}
                endIcon={<SendIcon sx={{ marginLeft: 1, marginTop: 1 }} />}
              >
                Invite
              </Button>
            </Box>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  )
}

export const OrganizationCardMembers: React.FC<{
  organization: OrganizationQuery['organization']
}> = ({ organization }) => {
  const { members } = organization ?? {}
  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>People</CardHeader>

          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {members
              ?.filter((_, idx) => idx < 10)
              ?.map(u => (
                <Tooltip
                  key={u.id}
                  title={u.user.email ?? u.name ?? u.username}
                >
                  <Avatar
                    sx={{
                      justifyContent: 'center',
                      display: 'flex',
                      margin: 0.5,
                      width: 35,
                      height: 35
                    }}
                    alt={u.name ?? u.username}
                    src={u.photoUrl}
                  />
                </Tooltip>
              ))}
          </Box>
          <Box sx={{ marginBottom: 3 }}>{/* <Button>View all</Button> */}</Box>

          <InviteButton
            id={organization?.id ?? ''}
            name={organization?.name ?? organization?.id ?? '...'}
          />
        </CardContent>
      </Card>
    </Section>
  )
}
