import { styled } from '@wandb/ui'
import { DeploymentType } from 'generated/deploy'
import { Image } from 'semantic-ui-react'

const CardSelect = styled.div<{ selected?: boolean }>`
  margin: auto;
  border: solid
    ${p =>
      p.selected ? p.theme.palette.primary.light : p.theme.colors.gray[400]}
    2px;
  border-radius: 0.25em;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  height: 100%;
  color: ${p =>
    p.selected ? p.theme.colors.gray[900] : p.theme.colors.gray[600]};
`

const DeployName = styled.p<{ selected?: boolean }>`
  padding-bottom: ${p => p.theme.spacing(2)};
  font-size: ${p => p.theme.fontSizes.xl};
  color: ${p => (p.selected ? p.theme.palette.primary.main : 'inherit')};
  font-weight: ${p => (p.selected ? p.theme.fontWeights.semibold : 'inherit')};
`

const DeployImage = styled(Image)<{ selected?: boolean }>`
  max-height: ${p => p.theme.spacing(32)};
  padding: ${p => p.theme.spacing(6)};
  ${p => (p.selected ? '' : `filter: grayscale(100%);  opacity: 0.8;`)}
`

const Subtext = styled.p`
  font-size: ${p => p.theme.fontSizes.sm};
  color: ${p => p.theme.colors.gray[600]};
`

export type DeployOptionRadioProps = {
  src?: string
  name: string
  subtext?: string
  selected?: boolean
  value: DeploymentType
  onClick?: (value: DeploymentType) => void
}

export const DeployOptionRadio: React.FC<DeployOptionRadioProps> = props => {
  const { src, name, subtext, selected, value, onClick } = props
  return (
    <CardSelect selected={selected} onClick={() => onClick?.(value)}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'center',
          alignContent: 'center'
        }}
      >
        <DeployName selected={selected}>{name}</DeployName>
        <Subtext>{subtext}</Subtext>
        <div style={{ flexGrow: 1 }}>
          {src && <DeployImage selected={selected} fluid src={src} />}
        </div>
      </div>
    </CardSelect>
  )
}
