import {
  addWeeks,
  differenceInDays,
  endOfDay,
  format,
  formatDistanceToNow,
  nextTuesday
} from 'date-fns'

export const releaseDate = () => new ReleaseDate()
export const getActiveRelease = (previousReleaseDate?: Date) => {
  const next = releaseDate()
  const previous = releaseDate().previous()

  if (next.isActive()) return next
  if (previousReleaseDate != null && previous.wasActiveOn(previousReleaseDate))
    return next
  if (previous.isActive()) return previous
  return next
}

class ReleaseDate {
  date: Date

  constructor() {
    const today = endOfDay(new Date())
    const tuesday = nextTuesday(today)
    const dayOfMonth = tuesday.getDate()
    const isSecondWeek = 7 < dayOfMonth && dayOfMonth < 15
    const isFourthWeek = 21 < dayOfMonth && dayOfMonth < 28
    this.date = isSecondWeek || isFourthWeek ? tuesday : nextTuesday(tuesday)
  }

  fromatDistance() {
    return formatDistanceToNow(this.date, { addSuffix: true })
  }

  format() {
    return format(this.date, 'EEEE, MMMM dd')
  }

  isActive() {
    const today = endOfDay(new Date())
    const days = differenceInDays(today, this.date)
    return days > -1 && days < 5
  }

  wasActiveOn(date: Date) {
    const today = endOfDay(date)
    const days = differenceInDays(today, this.date)
    return days > -1 && days < 5
  }

  next() {
    const c = this.clone()
    c.date = addWeeks(this.date, 2)
    return c
  }

  previous() {
    const c = this.clone()
    c.date = addWeeks(this.date, -2)
    return c
  }

  clone() {
    const r = new ReleaseDate()
    r.date = this.date
    return r
  }
}
