/* eslint-disable jsx-a11y/label-has-associated-control */
import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { Flex } from 'common/Flex'
import { Section } from 'common/Section'
import {
  DeploymentUpdateInput,
  useUpdateDeploymentMutation
} from 'generated/deploy'
import { useForm } from 'react-hook-form'
import { useCopyToClipboard } from 'react-use'
import { Button, Form } from 'semantic-ui-react'

import { useRouteDeployment } from '../useRouteDeployment'

export const DeploymentSettingsGeneral: React.FC = () => {
  const { deployment, refetch } = useRouteDeployment()
  const [updateDeployment, { loading }] = useUpdateDeploymentMutation()
  const { handleSubmit, register } = useForm<DeploymentUpdateInput>()
  const onSubmit = handleSubmit(async data => {
    if (deployment == null) return
    await updateDeployment({ variables: { id: deployment.id, data } })
    await refetch()
  })

  const [{ value }, copyToClipboard] = useCopyToClipboard()
  const { spacing } = useTheme()

  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>General</CardHeader>
          <Form onSubmit={onSubmit}>
            <Form.Field>
              <label>ID</label>
              <Flex alignItems="center">
                <span>{deployment?.id}</span>
                <span style={{ marginLeft: spacing(3) }}>
                  <Button
                    type="button"
                    onClick={() => copyToClipboard(deployment?.id ?? '')}
                    icon={value ? 'check' : 'clone'}
                    size="mini"
                    content={value ? 'Copied' : undefined}
                  />
                </span>
              </Flex>
            </Form.Field>

            <Form.Field>
              <label>Type</label>
              <span>{deployment?.type}</span>
            </Form.Field>

            <Form.Field required>
              <label>Name</label>
              <input {...register('name')} defaultValue={deployment?.name} />
            </Form.Field>

            <Form.Field>
              <label>Description</label>
              <textarea
                {...register('description')}
                rows={2}
                defaultValue={deployment?.description ?? ''}
              />
            </Form.Field>

            <Button primary loading={loading} content="Save settings" />
          </Form>
        </CardContent>
      </Card>
    </Section>
  )
}
