import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import Typography from '@mui/material/Typography'
import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { useUserByIdsLoader } from 'common/hooks/useUserByIdsLoader'
import { Page, PageContainer } from 'common/Page'
import { Section } from 'common/Section'
import { formatDistanceToNow } from 'date-fns'
import { compareDesc, parseISO } from 'date-fns/esm'
import { useLicenseOrdersQuery } from 'generated/deploy'
import uniq from 'lodash/uniq'
import { useMemo } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import { Header, List } from 'semantic-ui-react'

import { AdminHeader } from './components/AdminHeader'

type OrderEvent = { date: Date; message: React.ReactNode }

const useOrderEvents = () => {
  const { data } = useLicenseOrdersQuery()

  // Get all unqiue user ids from the license orders. We'll need this to display
  // proper names
  const userIds = useMemo(() => {
    const orders = data?.licenseOrders?.nodes ?? []
    return uniq([
      ...(orders.map(s => s.createdByUserId) ?? []),
      ...(orders
        .filter(s => s.claimedByUserId != null)
        .map(s => s.claimedByUserId ?? '') ?? [])
    ])
  }, [data])

  const { getUser } = useUserByIdsLoader(userIds)

  return useMemo(() => {
    const orders = data?.licenseOrders?.nodes ?? []
    const events: Array<OrderEvent> = []

    for (const order of orders) {
      const createdByUser = getUser(order.createdByUserId)
      const claimedByUser = getUser(order.claimedByUserId ?? '')

      if (order.deployment) {
        events.push({
          date: parseISO(order.deployment.createdAt),
          message: (
            <span>
              An order was claimed for{' '}
              <strong>
                <Link to={`/${order.deployment.id}`}>
                  {order.deployment.name}
                </Link>
              </strong>{' '}
              by <strong>{claimedByUser?.username}</strong>
            </span>
          )
        })
      }

      events.push({
        date: parseISO(order.createdAt),
        message: (
          <span>
            {createdByUser != null ? (
              <a
                rel="noreferrer"
                href={`https://wandb.ai/${createdByUser.username}`}
                target="_blank"
              >
                {createdByUser?.name ?? createdByUser?.username}
              </a>
            ) : (
              <strong>{order.createdByUserId}</strong>
            )}{' '}
            created a license order{' '}
            {order.customerEmail && (
              <>
                {'for '}
                <strong>{order.customerEmail}</strong>
              </>
            )}
          </span>
        )
      })
    }
    return events.sort((a, b) => compareDesc(a.date, b.date))
  }, [data, getUser])
}

const QuickLinks: React.FC = () => {
  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>Quick Links</CardHeader>
          <Header size="small">Dashboards</Header>
          <List relaxed>
            <List.Item>
              <List.Content>
                <List.Header
                  as="a"
                  href="https://app.mode.com/wandb/reports/877f340d136e"
                >
                  Local Prospect Customers
                </List.Header>
                <List.Description as="a">
                  Shows recent customers that have signuped using the
                  self-service flow.
                </List.Description>
              </List.Content>
            </List.Item>
          </List>

          <Header size="small">Terraform Modules</Header>
          <List relaxed>
            <List.Item>
              <List.Content>
                <List.Header
                  as="a"
                  href="https://github.com/wandb/terraform-aws-wandb"
                >
                  AWS Module
                </List.Header>
                <List.Description>
                  Modules allows customers to easly deploy an instance of
                  Weights &amp; Biases Local to Amazon Web Services.
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header
                  as="a"
                  href="https://github.com/wandb/terraform-google-wandb"
                >
                  Google Terraform Module
                </List.Header>
                <List.Description>
                  Modules allows customers to easly deploy an instance of
                  Weights &amp; Biases Local to Google Cloud.
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </CardContent>
      </Card>
    </Section>
  )
}

const AdminIndex: React.FC = () => {
  const orderEvents = useOrderEvents()
  const { colors, spacing } = useTheme()
  return (
    <Page title="Admin">
      <PageContainer maxWidth="xl">
        <AdminHeader />

        <QuickLinks />

        <Section>
          <Card>
            <CardContent>
              <CardHeader>License Orders History</CardHeader>

              <Timeline style={{ padding: 0 }}>
                {orderEvents.map((event, i) => (
                  <TimelineItem key={i} style={{ minHeight: spacing(12) }}>
                    <TimelineOppositeContent
                      style={{ flex: 0.1, paddingLeft: 0 }}
                    >
                      <Typography style={{ color: colors.grey[600] }}>
                        {formatDistanceToNow(event.date, { addSuffix: true })}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>{event.message}</TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </CardContent>
          </Card>
        </Section>
      </PageContainer>
    </Page>
  )
}

export default AdminIndex
