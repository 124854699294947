import Dns from '@mui/icons-material/Dns'
import Error from '@mui/icons-material/Error'
import Update from '@mui/icons-material/Update'
import Chip from '@mui/material/Chip'
import { styled, useTheme } from '@wandb/ui'
import { formatDistanceToNow, isPast } from 'date-fns/esm'
import { DeploymentType } from 'generated/deploy'

const Badge = styled(Chip)<{ themeColor: string }>(p => {
  const {
    clickable,
    themeColor,
    theme: { fontSizes, colors, spacing, fontWeights }
  } = p
  return `
    font-weight: ${fontWeights.semibold};
    font-size: ${fontSizes.md} !important;
    padding: ${spacing(0, 1)} !important;
    margin-right: ${spacing(2)} !important;
    color: ${colors.white} !important;
    background-color: ${(colors as any)[themeColor][500]} !important;
    ${
      clickable
        ? `
          &:hover {
            background-color: ${(colors as any)[themeColor][700]} !important;
          }
        `
        : ''
    }
  `
})

export const DeploymentBadgeLicense: React.FC<{
  trial: boolean
  expiresAt: Date
}> = ({ trial, expiresAt }) => {
  const { colors } = useTheme()
  const hasEnded = isPast(expiresAt)
  const timeString = formatDistanceToNow(expiresAt, { addSuffix: true })
  const label = trial
    ? `Trial ${hasEnded ? 'ended' : 'ends'} ${timeString}`
    : `License expires ${timeString}`
  return (
    <Badge
      icon={
        <Error
          sx={{ height: '20px', width: '20px', marginTop: '5px' }}
          style={{ color: colors.white }}
        />
      }
      themeColor={hasEnded ? 'red' : 'grey'}
      clickable
      label={label}
    />
  )
}

export const DeploymentBadgeUpdate: React.FC = () => {
  const { colors } = useTheme()
  return (
    <Badge
      icon={
        <Update
          sx={{ height: '20px', width: '20px', marginTop: '5px' }}
          style={{ color: colors.white }}
        />
      }
      themeColor="green"
      clickable
      label="Update Available"
    />
  )
}

export const deploymentTypeName = (type?: DeploymentType) => {
  switch (type) {
    case DeploymentType.Manual:
      return 'Manual Deployment'
    case DeploymentType.Aws:
      return 'Amazon Web Services'
    case DeploymentType.Gcp:
      return 'Google Cloud Platform'
  }
  return 'Unknown'
}

export const DeploymentBadgeType: React.FC<{ type?: DeploymentType }> = ({
  type
}) => {
  const { colors } = useTheme()
  return (
    <Badge
      icon={
        <Dns
          sx={{ height: '20px', width: '20px', marginTop: '5px' }}
          style={{ color: colors.white }}
        />
      }
      themeColor="blue"
      label={`${deploymentTypeName(type)}`}
    />
  )
}
