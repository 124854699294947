import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import { WBButton } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { Page, PageContainer } from 'common/Page'
import { Section } from 'common/Section'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { useNextLocalReleaseQuery, useVersionsQuery } from 'generated/deploy'
import { first, groupBy } from 'lodash'
import { useToggle } from 'react-use'

import { AdminHeader } from './components/AdminHeader'
import { getActiveRelease } from './components/releaseDateHelper'

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

const removeFixPrefix = (string: string) =>
  string.startsWith('Fix ') ? string.replace('Fix ', '') : string

const TYPE_DISPLAY_NAMES: Record<string, string> = {
  feat: 'Feature',
  fix: 'Fix',
  refactor: 'Refactor'
}

const TriggerReleasButton: React.FC = () => (
  <WBButton variant="outlined" color="success" style={{ marginRight: 5 }}>
    Trigger release
  </WBButton>
)

const TriggerPrereleaseButton: React.FC = () => (
  <WBButton variant="outlined" color="warning" style={{ marginRight: 5 }}>
    Trigger prerelease
  </WBButton>
)

const NextReleaseTimelineItem: React.FC<{ previousRelease: Date }> = ({
  previousRelease
}) => {
  const nextRelease = getActiveRelease(previousRelease)
  const isActive = nextRelease.isActive()

  const { data } = useNextLocalReleaseQuery()
  const { version, cc } = data?.nextLocalRelease ?? {}
  const categories = groupBy(
    cc?.filter(c => TYPE_DISPLAY_NAMES[c.type ?? ''] != null),
    c => TYPE_DISPLAY_NAMES[c.type ?? '']
  )

  const [showReleaseNotes, toggleReleaseNotes] = useToggle(false)
  return (
    <TimelineItem>
      <TimelineOppositeContent style={{ flex: 0.1 }}>
        v{version}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot
          color={isActive ? 'success' : 'secondary'}
          variant="outlined"
        />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        {nextRelease.fromatDistance()} ({nextRelease.format()})
        <Box sx={{ marginTop: 2 }}>
          {isActive ? <TriggerReleasButton /> : <TriggerPrereleaseButton />}
          <WBButton
            variant="outlined"
            onClick={toggleReleaseNotes}
            style={{ marginRight: 5 }}
          >
            Show expected release notes
          </WBButton>
        </Box>
        <Dialog
          open={showReleaseNotes}
          onClose={toggleReleaseNotes}
          keepMounted
        >
          <DialogTitle>Expected Release Notes</DialogTitle>
          <DialogContent>
            {Object.entries(categories).map(([category, notes]) => (
              <Box key={category}>
                <Typography variant="h5">{category}</Typography>
                <ul>
                  {notes.map((n, i) => (
                    <li key={i}>
                      {capitalizeFirstLetter(
                        removeFixPrefix(capitalizeFirstLetter(n.subject ?? ''))
                      )}
                    </li>
                  ))}
                </ul>
              </Box>
            ))}
          </DialogContent>
        </Dialog>
      </TimelineContent>
    </TimelineItem>
  )
}

const AdminReleases: React.FC = () => {
  const { data } = useVersionsQuery()
  const versions =
    data?.versions?.results.filter(v => v.name.includes('.')).slice(0, 10) ?? []
  return (
    <Page title="Local Releases">
      <PageContainer maxWidth="xl">
        <AdminHeader />
        <Section>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
            <Card>
              <CardContent>
                <CardHeader>Release History</CardHeader>
              </CardContent>
              <Timeline>
                <NextReleaseTimelineItem
                  previousRelease={first(versions)?.lastUpdated}
                />
                {versions?.map(v => (
                  <TimelineItem key={v.id}>
                    <TimelineOppositeContent style={{ flex: 0.1 }}>
                      v{v.name}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="success" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {formatDistanceToNow(parseISO(v.lastUpdated), {
                        addSuffix: true
                      })}
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Card>
          </Box>
        </Section>
      </PageContainer>
    </Page>
  )
}

export default AdminReleases
