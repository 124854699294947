import { DeploymentType } from 'generated/deploy'

import { TerraformConfigAws } from './TerraformConfigAws'
import { TerraformConfigAzure } from './TerraformConfigAzure'
import { TerraformConfigGcp } from './TerraformConfigGcp'
import { TerraformConfigTerraformTest } from './TerraformConfigTerrafromTest'
import { TerraformConfigFormFC, TerraformConfigFormFCProps } from './utils'

const ConfigureForms: Record<DeploymentType, TerraformConfigFormFC | null> = {
  [DeploymentType.Manual]: () => null,
  [DeploymentType.Gcp]: TerraformConfigGcp,
  [DeploymentType.Aws]: TerraformConfigAws,
  [DeploymentType.Azure]: TerraformConfigAzure,
  [DeploymentType.TerraformTest]: TerraformConfigTerraformTest
}

type RenderConfigureFormProps = TerraformConfigFormFCProps & {
  type: DeploymentType
}

export const TerraformConfigRender: React.FC<
  RenderConfigureFormProps
> = props => {
  const { type, ...rest } = props
  const ConfigurationForm = ConfigureForms[type]
  return ConfigurationForm && <ConfigurationForm {...rest} />
}
