import { HighlightedCode } from 'common/HighlightedCode'

import { InfoSection } from './Utils'

export const AwsFargate: React.FC = () => {
  return (
    <InfoSection>
      Type Fargate in the AWS console, or go directly to the ECS management
      page. Click Get Started to create a new ECS service using Fargate.
      <ul>
        <li>
          <strong>Container Definition:</strong> Choose &quot;custom&quot; and
          click &quot;Configure&quot;. From here name your container wandb-local
          and set the image name to wandb/local:latest. Finally add port 8080 to
          the port mappings.
        </li>
        <li>
          <strong>Task Definition:</strong> Click Edit and make sure to give the
          task at least 8GB of ram and 4 vCPUs
        </li>
        <li>
          <strong>Your Service:</strong> You&apos;ll likely want to create an
          ALB that can terminate SSL and forward requests to port 8080 of this
          service.
        </li>
        <li>
          <strong>IAM Permissions:</strong> If you plan to use a cloud file
          backend (this is optional), make sure your instance has an IAM role
          that allows it to access S3 and subscribe to SQS.
        </li>
      </ul>
      Once the service is provisioned you can access it via your ALB or directly
      via the IP and PORT of your instance. Your instance is usable from boot,
      but for advanced options, you may now proceed to configuring your
      instance.
    </InfoSection>
  )
}

export const AwsEc2: React.FC = () => {
  return (
    <>
      <InfoSection>
        You can run wandb/local on any EC2 instance that also has Docker
        installed. We suggest at least 8GB of RAM and 4vCPU&apos;s. Simply run
        the following command to launch the container:
      </InfoSection>
      <HighlightedCode language="bash">
        {`docker run --rm -d \\
  -v wandb:/vol -p 8080:8080 \\
  --name wandb-local wandb/local`}
      </HighlightedCode>
    </>
  )
}
