import { foundations, styled } from '@wandb/ui'

export const NAVBAR_HEIGHT = foundations.spacing(15)

export const NavbarContainer = styled.div`
  display: flex;
  align-items: flex-start;
  background: ${p => p.theme.colors.gray[900]};
  width: 100%;
  z-index: 2000;
  justify-content: space-between;
  height: ${NAVBAR_HEIGHT};
  color: ${p => p.theme.colors.gray[500]};
  transition: margin-top 0.2s;
`

export const NavbarLeftContainer = styled.div`
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  flex-grow: 1;
`

export const NavbarBreadcrumbWrapper = styled.div`
  padding: 0 16px;

  a {
    text-decoration: none;
    color: ${p => p.theme.colors.gray[500]} !important;
    &:hover {
      color: ${p => p.theme.colors.gray[400]} !important;
    }
  }

  i {
    color: ${p => p.theme.colors.gray[600]} !important;
  }
`

export const Header = styled.h1`
  font-weight: bold;
  font-size: 1.2em;
  letter-spacing: ${p => p.theme.letterSpacings.wide};
  margin: 0;
  color: ${p => p.theme.colors.gray[400]};
  :hover {
    color: ${p => p.theme.colors.gray[200]};
  }
`
