/* eslint-disable jsx-a11y/label-has-associated-control */
import { MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Select from '@mui/material/Select'
import { useTheme } from '@wandb/ui'
import { Card, CardContent } from 'common/Card'
import { Flex } from 'common/Flex'
import { useDisclosure } from 'common/hooks/useDisclosure'
import { useNotAdminRedirect } from 'common/hooks/useNotAdminRedirect'
import { InlineCode } from 'common/InlineCode'
import { LicenseFlagsInput } from 'common/license/LicenseFlagsInput'
import { Page, PageContainer, PageHeader } from 'common/Page'
import { endOfDay, format, parse } from 'date-fns/esm'
import {
  LicenseFlagValue,
  LicenseOrderCreateInput,
  useCreateLicenseOrderMutation
} from 'generated/deploy'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Checkbox, Form, Grid, Header } from 'semantic-ui-react'

const GB_TO_BYTES = 1000000000

type DeployOrderFormState = Omit<LicenseOrderCreateInput, 'expiresAt'> & {
  notes: string
  flags: LicenseFlagValue[]
  trial: boolean
  customerEmail: string
  maxTeams: number
  maxUsers: number
  maxViewOnlyUsers: number
  maxRegisteredModels: number
  maxStorageGb: number
  expiresAt: string
  pocExpiresAt: string | null
  contractExpiresAt: string | null
  sfdcAccountId: string
  sfdcOpportunityId: string
  weaveLimitBytes: BigInt | null
  weaveOverageCostCents: number | null
  contractStartDate: string | null
}

const useDeployOrderForm = () => {
  const form = useForm<DeployOrderFormState>({
    defaultValues: {
      trial: true,
      flags: [
        LicenseFlagValue.Notifications,
        LicenseFlagValue.Management,
        LicenseFlagValue.Scalable,
        LicenseFlagValue.Byob
      ],
      maxUsers: 20,
      maxViewOnlyUsers: 0,
      maxStorageGb: 1000000,
      maxTeams: 1,
      maxRegisteredModels: 5,
      expiresAt: format(new Date(), 'yyyy-MM-dd'),
      pocExpiresAt: format(new Date(), 'yyyy-MM-dd'),
      contractExpiresAt: null,
      sfdcAccountId: '',
      sfdcOpportunityId: '',
      weaveLimitBytes: 10,
      weaveOverageCostCents: 0.6,
      contractStartDate: null
    }
  })

  const [licenseOrderId, setLicenseOrderId] = useState('')
  const orderUrl = `${window.location.origin}/deploy?order=${licenseOrderId}`
  const dialog = useDisclosure({
    onClose: () => {
      form.reset()
      setLicenseOrderId('')
    }
  })
  const [createOrder, { error }] = useCreateLicenseOrderMutation()
  const onSubmit = form.handleSubmit(async form => {
    const {
      expiresAt,
      pocExpiresAt,
      contractExpiresAt,
      contractStartDate,
      weaveOverageCostCents,
      weaveLimitBytes,
      ...rest
    } = form
    const expiredAtDate = parse(expiresAt, 'yyyy-MM-dd', new Date())
    const pocExpiresAtDate = parse(pocExpiresAt, 'yyyy-MM-dd', new Date())
    const contractExpiresAtDate = contractExpiresAt
      ? parse(contractExpiresAt, 'yyyy-MM-dd', new Date())
      : undefined
    const contractStartsAtDate = contractStartDate
      ? parse(contractStartDate, 'yyyy-MM-dd', new Date())
      : undefined
    const weaveLimitInBytes = weaveLimitBytes
      ? Number(weaveLimitBytes) * GB_TO_BYTES
      : null
    const weaveOverageCostPerMbInCents = weaveOverageCostCents
      ? Math.round(weaveOverageCostCents * 100)
      : null
    const data: LicenseOrderCreateInput = {
      ...rest,
      flags: form.flags,
      expiresAt: endOfDay(expiredAtDate),
      pocExpiresAt: endOfDay(pocExpiresAtDate),
      contractExpiresAt:
        contractExpiresAtDate != null ? endOfDay(contractExpiresAtDate) : null,
      contractStartDate: contractStartsAtDate
        ? endOfDay(contractStartsAtDate)
        : null,
      weaveLimitBytes: weaveLimitInBytes,
      weaveOverageCostCents: weaveOverageCostPerMbInCents
    }
    const result = await createOrder({ variables: { data } })
    setLicenseOrderId(result.data?.createLicenseOrder?.id ?? '')
    dialog.onOpen()
  })
  return { ...form, onSubmit, ...dialog, orderUrl, error }
}

const DeployOrder: React.FC = () => {
  useNotAdminRedirect()

  const {
    onSubmit,
    control,
    register,
    isOpen,
    onClose,
    orderUrl,
    watch,
    error,
    setValue
  } = useDeployOrderForm()

  const {
    customerEmail,
    trial,
    weaveLimitBytes,
    weaveOverageCostCents,
    contractStartDate
  } = watch()
  const { fontWeights, colors, spacing } = useTheme()

  const [isWeaveEnabled, setIsWeaveEnabled] = useState(false)

  const showContractStartDateError =
    isWeaveEnabled &&
    weaveLimitBytes &&
    weaveOverageCostCents &&
    !contractStartDate

  return (
    <Page title="New Deployment">
      <PageContainer maxWidth="md">
        <PageHeader style={{ paddingBottom: spacing(2) }}>
          New License Order
        </PageHeader>
        <Box pb={spacing(8)} color={colors.gray[600]}>
          A license order allows a W&amp;B admin to create a custom license for
          a customer. This order generates a URL that the customer uses to
          create a local license and deployment. Once the customer clicks the
          link, they will need to complete a form that will, ask them to
          register/signup, create an organization and select which type
          deployment they would like to have.
        </Box>
        <Card>
          <CardContent style={{ margin: spacing(8) }}>
            <Form onSubmit={onSubmit}>
              <Form.Field>
                <Controller
                  control={control}
                  name="trial"
                  render={({ field: { value, onChange } }) => (
                    <Flex alignItems="center">
                      <Checkbox
                        checked={value}
                        onChange={() => onChange(!value)}
                        label="Trial"
                        style={{ fontWeight: fontWeights.bold }}
                      />
                      <p
                        style={{
                          paddingLeft: spacing(4),
                          color: colors.gray[500]
                        }}
                      >
                        Is the customer on a trial?
                      </p>
                    </Flex>
                  )}
                />
              </Form.Field>

              <Form.Field>
                <label>Customer Email</label>
                <input {...register('customerEmail')} />
              </Form.Field>

              <Form.Field required>
                <label>Internal Notes</label>
                <textarea {...register('notes')} rows={2} />
              </Form.Field>

              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Field required={true}>
                      <label>SFDC Account Id</label>
                      <input
                        {...register('sfdcAccountId', { required: true })}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field required={true}>
                      <label>SFDC Opportunity Id</label>
                      <input
                        {...register('sfdcOpportunityId', { required: true })}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Form.Field>
                <label>Flags</label>
                <Controller
                  control={control}
                  name="flags"
                  render={({ field: { value, onChange } }) => (
                    <LicenseFlagsInput
                      value={value as LicenseFlagValue[]}
                      onChange={onChange}
                    />
                  )}
                />
              </Form.Field>

              <Form.Field>
                <Grid>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <Form.Field>
                        <label>Seats</label>
                        <input
                          {...register('maxUsers', { valueAsNumber: true })}
                          type="number"
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>View-Only Seats</label>
                        <input
                          {...register('maxViewOnlyUsers', {
                            valueAsNumber: true
                          })}
                          type="number"
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Teams</label>
                        <input
                          {...register('maxTeams', { valueAsNumber: true })}
                          type="number"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <Form.Field>
                        <label>Storage (GB)</label>
                        <input
                          {...register('maxStorageGb', { valueAsNumber: true })}
                          type="number"
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Registered Models</label>
                        <Select
                          defaultValue={5}
                          style={{ minWidth: spacing(55), height: spacing(11) }}
                          {...register('maxRegisteredModels', {
                            valueAsNumber: true
                          })}
                        >
                          <MenuItem value={1_000_000}>
                            Unlimited - 1,000,000
                          </MenuItem>
                          <MenuItem value={5}>Trial - 5</MenuItem>
                          <MenuItem value={2}>Default - 2</MenuItem>
                        </Select>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field required={true}>
                        <label>License Expiration Date</label>
                        <input
                          {...register('expiresAt', { required: true })}
                          type="date"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      {trial && (
                        <Form.Field required={true}>
                          <label>PoC Expiration Date</label>
                          <input
                            {...register('pocExpiresAt', { required: trial })}
                            type="date"
                          />
                        </Form.Field>
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Contract Start Date</label>
                        <input
                          {...register('contractStartDate', {
                            required:
                              isWeaveEnabled &&
                              weaveLimitBytes &&
                              weaveOverageCostCents
                                ? 'Contract Start Date is required when Weave is enabled with limits'
                                : false
                          })}
                          type="date"
                        />
                        {showContractStartDateError && (
                          <p
                            style={{
                              color: colors.red[500],
                              fontSize: '0.8rem'
                            }}
                          >
                            *Contract Start Date is required when Weave is
                            enabled with limits
                          </p>
                        )}
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Contract Expiration Date</label>
                        <input {...register('contractExpiresAt')} type="date" />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <Form.Field>
                        <Checkbox
                          label="Enable Weave"
                          checked={isWeaveEnabled}
                          onChange={() => {
                            const updateIsWeaveEnabled = !isWeaveEnabled
                            setIsWeaveEnabled(updateIsWeaveEnabled)
                            if (updateIsWeaveEnabled) {
                              setValue('weaveLimitBytes', 10)
                              setValue('weaveOverageCostCents', 0.6)
                            } else {
                              setValue('weaveLimitBytes', null)
                              setValue('weaveOverageCostCents', null)
                            }
                          }}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  {isWeaveEnabled && (
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <Form.Field>
                          <label>Weave Limit (GB)</label>
                          <input
                            {...register('weaveLimitBytes', {
                              setValueAs: value =>
                                value ? BigInt(value) : null
                            })}
                            type="number"
                            disabled={!isWeaveEnabled}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <label>Weave Cost Per MB (USD)</label>
                          <input
                            {...register('weaveOverageCostCents')}
                            type="number"
                            step="0.01"
                            disabled={!isWeaveEnabled}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              </Form.Field>

              <Button icon="plus" content="Create" color="green" />
              {error && (
                <div style={{ color: colors.red[500], padding: spacing(4, 0) }}>
                  {error.graphQLErrors.map(s => (
                    <div key={s.name}>{s.message}</div>
                  ))}
                </div>
              )}
            </Form>
          </CardContent>
        </Card>

        <Dialog open={isOpen} onClose={onClose}>
          <DialogTitle>
            <Header style={{ marginTop: spacing(2) }}>
              License Order Created
            </Header>
          </DialogTitle>

          <DialogContent>
            {customerEmail && (
              <p>
                An email has been sent to{' '}
                <InlineCode>{customerEmail}</InlineCode>
              </p>
            )}
            <code>{orderUrl}</code>
          </DialogContent>
          <DialogActions>
            <Button
              icon="clone"
              content="Copy"
              primary
              onClick={() => navigator.clipboard.writeText(orderUrl)}
            />
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
    </Page>
  )
}

export default DeployOrder
