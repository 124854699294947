import { styled } from '@wandb/ui'
import { useAuth } from 'common/hooks/useAuth'
import { useViewerQuery } from 'generated/gorilla'
import { Dropdown, Image } from 'semantic-ui-react'

const AvatarWrapper = styled.div`
  margin-right: ${p => p.theme.spacing(2)};
`

const Menu = styled(Dropdown.Menu)`
  margin: ${p => p.theme.spacing(1, 2, 0, 0)} !important;
  background-color: ${p => p.theme.colors.gray[800]} !important;
  padding-bottom: ${p => p.theme.spacing(2)};
`

const Divider = styled(Dropdown.Divider)`
  margin: ${p => p.theme.spacing(1, 0)};
  border-color: ${p => p.theme.colors.gray[700]} !important;
`

const Item = styled(Dropdown.Item)(
  ({ theme: { colors, spacing, lineHeights } }) => `
    line-height: ${lineHeights[5]} !important;
    padding: ${spacing(3, 5)};
    color: ${colors.gray[400]};
    &:hover {
      background-color: ${colors.gray[700]};
      color: ${colors.gray[400]};
    }
  `
)

export const NavbarAvatar: React.FC = () => {
  const { data, loading } = useViewerQuery()
  const { logout } = useAuth()

  const viewer = data?.viewer
  if (viewer == null || loading) return null
  return (
    <Dropdown
      icon={null}
      direction="left"
      trigger={
        <AvatarWrapper>
          <Image src={viewer.photoUrl} avatar />
        </AvatarWrapper>
      }
    >
      <Menu>
        <a href={`https://wandb.ai/${viewer.username}`}>
          <Item>Profile</Item>
        </a>
        <a href={`https://wandb.ai/settings`}>
          <Item>Settings</Item>
        </a>
        <Divider />
        <a href={`https://docs.wandb.ai/`}>
          <Item>Documentation</Item>
        </a>
        <a href={`https://wandb.ai/fully-connected`}>
          <Item>Fully Connected</Item>
        </a>
        <a href={`https://community.wandb.ai/`}>
          <Item>Community</Item>
        </a>
        <Divider />
        <div onMouseDown={() => logout()} role="button" tabIndex={0}>
          <Item>Log out</Item>
        </div>
      </Menu>
    </Dropdown>
  )
}
