import Box from '@mui/material/Box'
import { Page, PageContainer } from 'common/Page'
import { useDeploymentStateQuery } from 'generated/deploy'

import { DeploymentHeader } from './components/DeploymentHeader'
import { DeploymentStateOutputCard } from './components/state/DeploymentStateOutputCard'
import { DeploymentStateResourceCard } from './components/state/DeploymentStateResourceCard'
import { useRouteDeploymentParams } from './components/useRouteDeployment'

const DeploymentPageState: React.FC = () => {
  const { deployment } = useRouteDeploymentParams()

  const { data, loading } = useDeploymentStateQuery({
    variables: { id: deployment }
  })

  const state = data?.deployment?.state

  return (
    <Page title="Deployment Config">
      <PageContainer>
        <DeploymentHeader />
        {state == null ? (
          <Box textAlign="center" p={10}>
            This deployment does not have any state.
          </Box>
        ) : (
          <>
            <DeploymentStateOutputCard {...state} loading={loading} />
            <DeploymentStateResourceCard {...state} loading={loading} />
          </>
        )}
      </PageContainer>
    </Page>
  )
}

export default DeploymentPageState
