import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import FiberManualRecord from '@mui/icons-material/FiberManualRecord'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import Box from '@mui/material/Box'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { styled, useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { Flex } from 'common/Flex'
import { useDisclosure } from 'common/hooks/useDisclosure'
import { Section } from 'common/Section'
import { snakeCaseToTitleCase } from 'common/utils/casing'
import { ReleaseQuery } from 'generated/deploy'
import { Button, Header } from 'semantic-ui-react'

const ReleaseProgressHeader = styled(Header)`
  font-size: ${p => p.theme.fontSizes.lg} !important;
  padding-top: ${p => p.theme.spacing(2)} !important;
`

const Alert = styled.div(
  ({ theme: { radii, spacing } }) => `
    padding: ${spacing(4)};
    border-radius: ${radii.md};
    margin: ${spacing(4, 0)};
  `
)

const AlertError: React.FC<{
  '@message': string
  diagnostic?: { detail: string }
}> = props => {
  const { colors, fontWeights } = useTheme()
  return (
    <Alert style={{ background: colors.red[50], color: colors.red[800] }}>
      <Flex>
        <ErrorOutline />
        <Box flexGrow={1} ml={4}>
          <Box fontWeight={fontWeights.bold} mb={2}>
            {props['@message']}
          </Box>
          <Box>{props?.diagnostic?.detail}</Box>
        </Box>
      </Flex>
    </Alert>
  )
}

const LogLine: React.FC<{
  '@level': string
  '@message': string
  '@timestamp': string
}> = props => {
  const { colors } = useTheme()
  const levelColors: Record<string, string> = {
    info: colors.blue[200],
    error: colors.red[200]
  }

  return (
    <tr>
      <td style={{ color: levelColors[props['@level'] ?? 'info'] }}>
        {props['@level']}
      </td>
      <td>{props['@message']}</td>
    </tr>
  )
}

const Logs: React.FC<{ logs: any[] }> = ({ logs }) => {
  return (
    <table
      style={{
        display: 'block',
        overflow: 'auto',
        whiteSpace: 'nowrap'
      }}
    >
      <tbody>
        {logs.map((l, idx) => (
          <LogLine key={idx} {...l} />
        ))}
      </tbody>
    </table>
  )
}

const DialogLogs: React.FC<DialogProps & { logs: any[] }> = props => {
  const { logs, ...rest } = props
  const { colors, spacing } = useTheme()
  return (
    <Dialog {...rest} fullWidth maxWidth="md">
      <DialogContent
        style={{
          color: colors.white,
          background: colors.grey[900],
          padding: spacing(4)
        }}
      >
        <Logs logs={logs} />
      </DialogContent>
    </Dialog>
  )
}

const ApplyEvent: React.FC<{ release: ReleaseQuery['release'] }> = ({
  release
}) => {
  const { fontSizes, colors } = useTheme()

  const planStatus = release?.plan?.status
  const applyStatus = release?.apply?.status

  const isPlanFinished = planStatus === 'finished'
  const isPlanError = planStatus === 'errored' || planStatus === 'unreachable'
  const isApplyFinished = applyStatus === 'finished'
  const isApplyError =
    applyStatus === 'errored' || applyStatus === 'unreachable'

  const applyVariant = isPlanFinished ? 'filled' : 'outlined'
  const applyIconColor = isPlanError
    ? undefined
    : isApplyFinished
    ? 'primary'
    : 'secondary'
  const ApplyStatusIcon = !isPlanFinished
    ? FiberManualRecord
    : isApplyFinished
    ? Check
    : isApplyError
    ? Close
    : FiberManualRecord

  const { isOpen, onOpen, onClose } = useDisclosure()
  const logs = release?.apply?.logs

  return (
    <TimelineItem>
      <TimelineOppositeContent style={{ flex: 0, paddingLeft: 0 }} />
      <TimelineSeparator>
        <TimelineDot color={applyIconColor} variant={applyVariant}>
          <ApplyStatusIcon
            style={{
              fontSize: fontSizes.md,
              color: isPlanError
                ? 'white'
                : applyVariant === 'filled'
                ? 'white'
                : undefined
            }}
          />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <ReleaseProgressHeader
          style={{ color: isPlanError ? colors.grey[500] : undefined }}
        >
          Apply {isPlanError ? 'will not run' : ''}
        </ReleaseProgressHeader>

        {logs
          ?.filter(l => l['@level'] === 'error')
          .map((l, idx) => (
            <AlertError key={idx} {...l} />
          ))}
        {logs && logs.length > 0 && (
          <>
            <Button onClick={onOpen} size="small">
              Show Logs
            </Button>
            <DialogLogs open={isOpen} onClose={onClose} logs={logs} />
          </>
        )}
      </TimelineContent>
    </TimelineItem>
  )
}

const PlanEvent: React.FC<{ release: ReleaseQuery['release'] }> = ({
  release
}) => {
  const { fontSizes } = useTheme()

  const planStatus = release?.plan?.status

  const isPlanFinished = planStatus === 'finished'
  const isPlanError = planStatus === 'errored' || planStatus === 'unreachable'

  const planIconColor = isPlanFinished ? 'primary' : 'secondary'
  const planVariant = isPlanFinished || isPlanError ? 'filled' : 'outlined'
  const PlanStatusIcon = isPlanFinished
    ? Check
    : isPlanError
    ? Close
    : FiberManualRecord

  const { isOpen, onOpen, onClose } = useDisclosure()
  const logs = release?.plan?.logs

  return (
    <TimelineItem>
      <TimelineOppositeContent style={{ flex: 0, paddingLeft: 0 }} />
      <TimelineSeparator>
        <TimelineDot
          color={planIconColor}
          variant={isPlanFinished || isPlanError ? 'filled' : 'outlined'}
        >
          <PlanStatusIcon
            style={{
              fontSize: fontSizes.md,
              color: planVariant === 'filled' ? 'white' : undefined
            }}
          />
        </TimelineDot>
      </TimelineSeparator>
      <TimelineContent>
        <ReleaseProgressHeader>
          Plan {snakeCaseToTitleCase(planStatus ?? '')} <span></span>
        </ReleaseProgressHeader>
        {logs
          ?.filter(l => l['@level'] === 'error')
          .map((l, idx) => (
            <AlertError key={idx} {...l} />
          ))}
        {logs && logs.length > 0 && (
          <>
            <Button onClick={onOpen} size="small">
              Show Logs
            </Button>
            <DialogLogs open={isOpen} onClose={onClose} logs={logs} />
          </>
        )}
      </TimelineContent>
    </TimelineItem>
  )
}

export const ReleaseCardProgress: React.FC<{
  release: ReleaseQuery['release']
}> = ({ release }) => (
  <Section>
    <Card>
      <CardContent>
        <CardHeader>Progress</CardHeader>
        <Timeline style={{ padding: 0 }}>
          <ApplyEvent release={release} />
          <PlanEvent release={release} />
        </Timeline>
      </CardContent>
    </Card>
  </Section>
)
