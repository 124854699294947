import Box from '@mui/material/Box'
import { useTheme } from '@wandb/ui'
import { Flex, Spacer } from 'common/Flex'
import { useViewer } from 'common/hooks/useViewer'
import { PageHeader } from 'common/Page'
import { parseISO } from 'date-fns/esm'
import { DeploymentType } from 'generated/deploy'
import { Link, useLocation } from 'react-router-dom'
import { Tab } from 'semantic-ui-react'

import { DeploymentBadgeLicense, DeploymentBadgeType } from './DeploymentBadges'
import { DeploymentVersionSelector } from './DeploymentVersionSelector'
import {
  useRouteDeployment,
  useRouteDeploymentParams
} from './useRouteDeployment'

export const DeploymentHeaderTabs: React.FC = () => {
  const { deployment: deploymentId } = useRouteDeploymentParams()
  const { deployment } = useRouteDeployment()
  const deploymentPath = `/${deploymentId}`
  const panes: Array<any> = [
    {
      menuItem: {
        as: Link,
        content: 'Overview',
        to: deploymentPath,
        excat: true
      }
    }
  ]

  if (deployment?.type === DeploymentType.Manual)
    panes.push({
      menuItem: {
        as: Link,
        content: 'Licenses',
        to: `${deploymentPath}/licenses`
      }
    })

  if (deployment?.type !== DeploymentType.Manual) {
    panes.push({
      menuItem: {
        as: Link,
        content: 'State',
        to: `${deploymentPath}/state`
      }
    })

    panes.push({
      menuItem: {
        as: Link,
        content: 'Settings',
        to: `${deploymentPath}/settings`
      }
    })
  }

  const { pathname } = useLocation()
  const activeIndex = panes.findIndex(s =>
    s.menuItem.excat
      ? s.menuItem.to === pathname
      : pathname.startsWith(s.menuItem.to)
  )

  return (
    <Tab
      renderActiveOnly={false}
      activeIndex={activeIndex}
      menu={{ secondary: true, pointing: true }}
      panes={panes}
    />
  )
}

export const DeploymentHeader: React.FC = () => {
  const { deployment, license, loading } = useRouteDeployment()
  const { colors, spacing } = useTheme()
  const { isViewerAdmin } = useViewer()
  return (
    <Box>
      <Flex alignItems="center">
        <PageHeader>{deployment?.name}</PageHeader>
        <Box color={colors.gray[600]} margin={spacing(0, 5)}>
          {deployment?.description}
        </Box>

        {isViewerAdmin &&
          !loading &&
          deployment?.type !== DeploymentType.Manual && (
            <>
              <Spacer />
              <Box pr={4}>Version</Box>
              <DeploymentVersionSelector />
            </>
          )}
      </Flex>

      <Flex style={{ margin: spacing(4, 0) }}>
        <DeploymentBadgeType type={deployment?.type} />
        {/* <DeploymentBadgeUpdate /> */}
        {license?.expiresAt && (
          <DeploymentBadgeLicense
            trial={license.trial}
            expiresAt={parseISO(license.expiresAt)}
          />
        )}
      </Flex>

      <DeploymentHeaderTabs />
    </Box>
  )
}
