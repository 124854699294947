import { CardContent, Container } from '@mui/material'
import Box from '@mui/material/Box'
import { useTheme } from '@wandb/ui'
import { Card } from 'common/Card'
import { useOrganizationByIdsLoader } from 'common/hooks/useOrganizationByIdsLoader'
import { Page, PageContainer, PageHeader } from 'common/Page'
import { Section, SectionHeader } from 'common/Section'
import { useDeploymentsQuery, useLicenseOrdersQuery } from 'generated/deploy'
import first from 'lodash/first'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Placeholder } from 'semantic-ui-react'

import { DeploymentRow } from '../common/DeploymentRow'
import { useViewer } from '../common/hooks/useViewer'
import { GettingStarted } from './components/GettingStarted'
import { PlaceholderList } from './components/Placeholders'

const WelcomeHeader: React.FC<{ name?: string | null }> = ({ name }) => {
  return name == null ? (
    <Placeholder>
      <Placeholder.Header>
        <Placeholder.Line as="h1" length="full" />
      </Placeholder.Header>
    </Placeholder>
  ) : (
    <PageHeader>Welcome, {name}</PageHeader>
  )
}

const useDeployments = () => {
  const { data, loading } = useDeploymentsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60 * 1000
  })
  const deployments = data?.deployments?.nodes ?? []
  return { loading, hasDeployments: deployments.length !== 0, deployments }
}

const useLicenseOrders = () => {
  const { viewer } = useViewer()
  const { data } = useLicenseOrdersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        customerEmail: { equals: viewer?.email ?? '' },
        deploymentId: { equals: null }
      }
    },
    skip: viewer?.email == null
  })
  return { firstOrder: first(data?.licenseOrders?.nodes ?? []) }
}

const Index: React.FC = () => {
  const { viewer } = useViewer()
  const { isViewerAdmin } = useViewer()
  const { loading, deployments, hasDeployments } = useDeployments()
  const { colors, spacing, fontSizes, fontWeights } = useTheme()
  const { firstOrder } = useLicenseOrders()

  const orgIds = deployments.map(d => d.organizationId)
  const { getOrganization } = useOrganizationByIdsLoader(orgIds)

  return (
    <Page title="Deployments">
      <PageContainer>
        <WelcomeHeader name={viewer?.name ?? viewer?.username} />
        {firstOrder && (
          <Section>
            <Card>
              <Box padding={spacing(10)}>
                <Container maxWidth="sm">
                  <Box textAlign="center" padding={spacing(4)}>
                    <Box
                      fontSize={fontSizes.xl2}
                      mb={spacing(4)}
                      fontWeight={fontWeights.bold}
                    >
                      Set up a licensed deployment
                    </Box>
                    <Box mb={spacing(4)}>
                      The W&amp;B team has created a license order for you. Get
                      started setting up your custom deployment and generate a
                      license key.
                    </Box>
                    <Button
                      as={Link}
                      to={`/deploy?order=${firstOrder.id}`}
                      color="green"
                      size="large"
                    >
                      Create a Custom Deployment
                    </Button>
                  </Box>
                </Container>
              </Box>
            </Card>
          </Section>
        )}

        {(hasDeployments || loading) && (
          <Section>
            <SectionHeader
              action={
                (!hasDeployments || isViewerAdmin) && (
                  <Link to="/deploy">
                    <Button
                      size="small"
                      primary
                      icon="rocket"
                      content="New Deployment"
                    />
                  </Link>
                )
              }
            >
              Deployments
            </SectionHeader>

            <Card>
              {loading ? (
                <Box padding={spacing(4)}>
                  <PlaceholderList />
                </Box>
              ) : (
                <>
                  {deployments.map((d, i) => (
                    <CardContent
                      key={d.id}
                      style={{
                        padding: 0,
                        borderTop:
                          i === 0 ? undefined : `1px solid ${colors.grey[300]}`
                      }}
                    >
                      <DeploymentRow
                        {...d}
                        organizationName={
                          getOrganization(d.organizationId)?.name
                        }
                      />
                    </CardContent>
                  ))}
                </>
              )}
            </Card>
          </Section>
        )}

        <GettingStarted />
      </PageContainer>
    </Page>
  )
}

export default Index
