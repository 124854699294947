import { useUserByIdQuery } from 'generated/gorilla'
import uniq from 'lodash/uniq'
import { useCallback, useMemo } from 'react'

/**
 * Hook to get gorilla users from an array of user ids.
 *
 * @param ids array of user ids to query for
 */
export const useUserByIdsLoader = (userIds?: (string | null | undefined)[]) => {
  // Get all unqiue user ids from the license orders. We'll need this to display
  // proper names
  const ids = useMemo(() => {
    return uniq(userIds?.filter(s => s != null) ?? []) as string[]
  }, [userIds])

  const { data, loading } = useUserByIdQuery({ variables: { ids } })
  const users = useMemo(() => data?.users?.edges.map(e => e.node), [data])

  const getUser = useCallback(
    (id: string) => users?.find(s => s?.id === id),
    [users]
  )

  return { data, users, getUser, loading }
}
