import { useTheme } from '@wandb/ui'

export const Info: React.FC<{ name: string; value?: React.ReactNode }> = ({
  name,
  value
}) => {
  const { spacing, colors, fontWeights } = useTheme()
  return (
    <div style={{ margin: spacing(2, 0) }}>
      <div style={{ fontWeight: fontWeights.bold, color: colors.grey[600] }}>
        {name}
      </div>
      <div>{value}</div>
    </div>
  )
}
