import { useTheme } from '@wandb/ui'
import { Card, CardContent } from 'common/Card'
import { useUserByIdsLoader } from 'common/hooks/useUserByIdsLoader'
import { Page, PageContainer } from 'common/Page'
import { ReactTable } from 'common/ReactTable'
import { Section } from 'common/Section'
import { formatDistanceToNow } from 'date-fns'
import { parseISO } from 'date-fns/esm'
import {
  Deployment,
  LicenseOrder,
  LicenseOrdersDocument,
  useDeleteLicenseOrderMutation,
  useLicenseOrdersQuery
} from 'generated/deploy'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Column, useTable } from 'react-table'
import { CellProps } from 'react-table'
import { Button } from 'semantic-ui-react'

import { AdminHeader } from '../components/AdminHeader'

type LicenseOrderCellFC<T> = React.FC<CellProps<LicenseOrder, T>>

const LicenseOrderCellDateDistance: LicenseOrderCellFC<string> = ({
  value
}) => {
  const dateParsed = parseISO(value)
  return <>{formatDistanceToNow(dateParsed, { addSuffix: true })}</>
}

const LicenseOrderCellDeployment: LicenseOrderCellFC<
  undefined | Partial<Deployment>
> = ({ value }) => {
  const { colors } = useTheme()
  return value == null ? (
    <span style={{ color: colors.grey[600] }}>-</span>
  ) : (
    <Link to={`/${value.id}`} target="_blank">
      {value.name}
    </Link>
  )
}

const LicenseOrderCellLink: LicenseOrderCellFC<string> = ({ value, row }) => {
  const orderUrl = `${window.location.origin}/deploy?order=${value}`
  const [copied, setCopied] = useState(false)
  const onCopyLink = () => {
    setCopied(true)
    navigator.clipboard.writeText(orderUrl)
    setTimeout(() => setCopied(false), 1500)
  }

  const [deleteLicenseOrder] = useDeleteLicenseOrderMutation({
    variables: { id: row.original.id },
    refetchQueries: [LicenseOrdersDocument]
  })
  const onDeleteLink = () => deleteLicenseOrder()

  return (
    <>
      <Button
        size="mini"
        icon={copied ? 'check' : 'clone'}
        content="Copy Link"
        onClick={onCopyLink}
        disabled={row.values.deployment}
      />
      <Button
        size="mini"
        icon="delete"
        color="red"
        content="Delete"
        onClick={onDeleteLink}
        disabled={row.values.deployment}
      />
    </>
  )
}

const useOrdersTable = () => {
  const { data } = useLicenseOrdersQuery()
  const orders = useMemo(
    () => (data?.licenseOrders?.nodes ?? []) as unknown as LicenseOrder[],
    [data?.licenseOrders?.nodes]
  )
  const { getUser } = useUserByIdsLoader(orders.map(o => o.createdByUserId))
  const columns = useMemo<Array<Column<LicenseOrder>>>(
    () => [
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: LicenseOrderCellDateDistance
      },
      { Header: 'Customer Email', accessor: 'customerEmail' },
      { Header: 'Notes', accessor: 'notes' },
      {
        Header: 'Deployment',
        accessor: 'deployment',
        Cell: LicenseOrderCellDeployment
      },
      {
        Header: 'Creator',
        accessor: 'createdByUserId',
        Cell: ({ value }) => {
          const user = getUser(value)
          return <>{user?.username ?? '?'}</>
        }
      },
      { Header: 'Link', accessor: 'id', Cell: LicenseOrderCellLink }
    ],
    [getUser]
  )

  return useTable({ data: orders, columns })
}

const AdminOrders: React.FC = () => {
  const table = useOrdersTable()
  const { colors } = useTheme()
  return (
    <Page title="Admin Orders">
      <PageContainer maxWidth="xl">
        <AdminHeader />
        <Section>
          <Card>
            <CardContent>
              <Button
                as={Link}
                primary
                to="/admin/orders/new"
                content="New Order"
              />
            </CardContent>
            <ReactTable
              {...table}
              style={{
                border: 'none',
                borderTop: `1px solid ${colors.gray[300]}`,
                marginTop: 0
              }}
            />
          </Card>
        </Section>
      </PageContainer>
    </Page>
  )
}

export default AdminOrders
