import { useLocation } from 'react-router-dom'

interface stateType {
  referrer: any
}

export const useGetReferrer = (decode = true) => {
  const location = useLocation<stateType>()
  const queryString = location.search ?? ''
  const queryParams = new URLSearchParams(queryString)
  const ref = queryParams.get('ref') ?? ''
  return decode ? atob(ref) : ref
}
