import { CreateLocalLicenseOrganizationInput } from 'generated/gorilla'
import { useCallback } from 'react'

import { useSegment } from './segment'

export enum OrganizationCreationFailedReason {
  AlreadyExists = 'ALREADY_EXISTS',
  Unknown = 'UNKNOWN'
}

type OrganizationCreateFailedEventProperties =
  CreateLocalLicenseOrganizationInput & {
    statusCode: number
    reason: OrganizationCreationFailedReason
  }

export const useOrganizationCreateFailedSegmentEvent = () => {
  const { analytics } = useSegment()
  return useCallback(
    (org: OrganizationCreateFailedEventProperties) =>
      analytics?.track('Create Organization Failed', org),
    [analytics]
  )
}
