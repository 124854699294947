import { HighlightedCode } from 'common/HighlightedCode'
import { InlineCode } from 'common/InlineCode'
import { Table } from 'semantic-ui-react'

import { InfoSection } from './Utils'

export const WbCli: React.FC = () => {
  return (
    <>
      <p>
        To run the W&amp;B server locally you&apos;ll need to have Docker
        installed. Then simply run:
      </p>
      <HighlightedCode language="bash" showCopyButton>
        {`wandb server start`}
      </HighlightedCode>
      <p>
        We are pushing new versions of wandb/local to dockerhub regularly. To
        upgrade you can run:
      </p>
      <HighlightedCode language="bash" showCopyButton>
        {`wandb server start --upgrade`}
      </HighlightedCode>

      <InfoSection>
        Running <InlineCode>wandb server start</InlineCode> also configures your
        local machine to push metrics to{' '}
        <a href="http://localhost:8080">http://localhost:8080</a>.
      </InfoSection>

      <InfoSection>
        <Table size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Arguments</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>--upgrade</Table.Cell>
              <Table.Cell>Upgrades local instance</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>--port</Table.Cell>
              <Table.Cell>Sets the port of local install</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>--host</Table.Cell>
              <Table.Cell>Configure DNS with your local instance</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </InfoSection>
    </>
  )
}
