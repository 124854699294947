import { useDeploymentQuery } from 'generated/deploy'
import first from 'lodash/first'
import { useParams } from 'react-router-dom'

export const useRouteDeploymentParams = () =>
  useParams<{ deployment: string }>()

/**
 * Get the deployment from the router
 */
export const useRouteDeployment = () => {
  const { deployment: id } = useRouteDeploymentParams()
  const { data, ...rest } = useDeploymentQuery({
    variables: { id },
    pollInterval: 10_000
  })
  const deployment = data?.deployment
  const license = first(deployment?.licenses?.nodes ?? [])
  return { id, ...data, ...rest, license }
}
