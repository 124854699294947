import { styled, useTheme } from '@wandb/ui'
import { Flex, Spacer } from 'common/Flex'
import { ReleaseStatusIcon } from 'common/release/ReleaseStatusIcon'
import { DeepPartial } from 'common/types'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'
import {
  Deployment,
  DeploymentType,
  Release,
  useDeploymentLicenseLazyQuery
} from 'generated/deploy'
import first from 'lodash/first'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Header, Label } from 'semantic-ui-react'

import { useDisclosure } from './hooks/useDisclosure'
import { useCopyLicenseEvent } from './license/useLicenseCopy'
import { RedirectDialog } from './RedirectDialog'

const Name: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { fontSizes } = useTheme()
  return (
    <Header
      style={{
        fontSize: fontSizes.lg,
        padding: 0,
        margin: 0,
        display: 'inline-block'
      }}
    >
      {children}
    </Header>
  )
}

const Row = styled.div`
  cursor: pointer;
  padding: ${p => p.theme.spacing(4)};
  border-radius: ${p => p.theme.radii.sm};

  :hover {
    background: ${p => p.theme.colors.grey[50]};
  }
`

const Description = styled.p`
  color: ${p => p.theme.colors.gray[600]};
`

const Info = styled.div`
  width: ${p => p.theme.spacing(45)};
  text-align: center;
  :first-child {
    text-align: left;
  }
  :last-child {
    text-align: right;
  }
  margin: ${p => p.theme.spacing(0, 2)};
`

const InfoLabel = styled.div`
  text-transform: uppercase;
  color: ${p => p.theme.colors.gray[500]};
  font-size: ${p => p.theme.fontSizes.xs};
`

const InfoValue = styled.div`
  color: ${p => p.theme.colors.gray[700]};
`

const CopyLicenseButton: React.FC<{ deploymentId?: string }> = ({
  deploymentId
}) => {
  const [copied, setCopied] = useState(false)
  const copyLicenseEvent = useCopyLicenseEvent()
  const [getLicense, { loading, data }] = useDeploymentLicenseLazyQuery()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const copy = () => {
    if (deploymentId == null) return
    setCopied(true)
    getLicense({ variables: { deploymentId } })
    setTimeout(() => setCopied(false), 2000)
  }

  useEffect(() => {
    const licenses = data?.deployment?.licenses?.nodes
    const license = first(licenses)
    if (license != null && copied) {
      navigator.clipboard.writeText(license.license)
      copyLicenseEvent(license)
    }
  }, [data, copied, copyLicenseEvent])

  return (
    <>
      <RedirectDialog
        onClick={e => e.stopPropagation()}
        open={isOpen}
        onClose={onClose}
      />
      <Button
        onClick={e => {
          e.stopPropagation()
          copy()
          onOpen()
        }}
        as={Label}
        loading={loading}
        icon={copied ? 'check' : 'clone'}
        content="Copy License"
      />
    </>
  )
}

export const deploymentTypeName = (type?: DeploymentType) => {
  switch (type) {
    case DeploymentType.Manual:
      return 'Manual Deployment'
    case DeploymentType.Aws:
      return 'Amazon Web Services'
    case DeploymentType.Gcp:
      return 'Google Cloud Platform'
    case DeploymentType.TerraformTest:
      return 'Terraform Test'
  }
  return 'Unknown'
}

export type DeploymentRowProps = DeepPartial<
  Deployment & { organizationName: string }
>
export const DeploymentRow: React.FC<DeploymentRowProps> = deployment => {
  const release: Partial<Release> = first(deployment.releases as any) ?? {}
  const history = useHistory()
  const { spacing } = useTheme()
  return (
    <Row onClick={() => history.push(`/${deployment.id}`)}>
      <Flex alignItems="center" style={{ marginBottom: spacing(4) }}>
        {release.status && (
          <ReleaseStatusIcon status={release.status} size="big" />
        )}

        <div style={{ margin: spacing(0, 2) }}>
          <div>
            <Name>{deployment.name}</Name>
          </div>
          <Description>{deployment.description}</Description>
        </div>
        <Spacer />
        <CopyLicenseButton deploymentId={deployment.id} />
      </Flex>

      <Flex alignItems="center" justifyContent="space-between">
        <Info>
          <InfoLabel>Type</InfoLabel>
          <InfoValue>{deploymentTypeName(deployment.type)}</InfoValue>
        </Info>

        {release.createdAt && (
          <Info>
            <InfoLabel>Deployed</InfoLabel>
            <InfoValue>
              {formatDistanceToNow(parseISO(release.createdAt), {
                addSuffix: true
              })}
            </InfoValue>
          </Info>
        )}

        <Info>
          <InfoLabel>Organization</InfoLabel>
          <InfoValue onClick={e => e.stopPropagation()}>
            {deployment.organizationName ?? <i>Unknown</i>}
          </InfoValue>
        </Info>
      </Flex>
    </Row>
  )
}
