/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-key */

import { useTheme } from '@wandb/ui'
import { TableInstance } from 'react-table'
import { Table } from 'semantic-ui-react'

export type ReactTableProps<D extends object> = Pick<
  TableInstance<D>,
  'getTableProps' | 'headerGroups' | 'getTableBodyProps' | 'rows' | 'prepareRow'
>

export function ReactTable<D extends object>(
  props: ReactTableProps<D> & { style?: any }
) {
  const { headerGroups, rows, prepareRow, getTableProps, getTableBodyProps } =
    props
  const { fontSizes } = useTheme()
  return (
    <Table
      celled
      compact
      {...getTableProps()}
      style={{ border: 'none', ...props.style }}
    >
      {headerGroups.length > 0 && (
        <Table.Header>
          {headerGroups.map(headerGroup => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Table.HeaderCell
                  {...column.getHeaderProps()}
                  style={{ fontSize: fontSizes.md }}
                >
                  {column.render('Header')}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
      )}
      <Table.Body {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          // const isLast = rowIndex === rows.length - 1
          return (
            <Table.Row {...row.getRowProps()}>
              {row.cells.map(cell => (
                <Table.Cell
                  {...cell.getCellProps()}
                  style={{ fontSize: fontSizes.md }}
                >
                  {cell.render('Cell')}
                </Table.Cell>
              ))}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}
