import { useOrganizationsByIdQuery } from 'generated/gorilla'
import uniq from 'lodash/uniq'
import { useCallback, useMemo } from 'react'

/**
 * Hook to get gorilla organizations from an array of organizations ids.
 *
 * @param ids array of organizations ids to query for
 */
export const useOrganizationByIdsLoader = (
  organizationsIds?: (string | null | undefined)[]
) => {
  // Get all unqiue organizations ids from the license orders. We'll need this
  // to display proper names
  const ids = useMemo(() => {
    return uniq(organizationsIds?.filter(s => s != null) ?? []) as string[]
  }, [organizationsIds])

  const { data, loading } = useOrganizationsByIdQuery({
    variables: { ids },
    skip: ids.length === 0
  })
  const organizations = useMemo(
    () => data?.organizations?.edges.map(e => e.node),
    [data]
  )

  const getOrganization = useCallback(
    (id: string) => organizations?.find(s => s?.id === id),
    [organizations]
  )

  return { data, organizations, getOrganization, loading }
}
