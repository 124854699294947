import Box from '@mui/material/Box'
import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader, CardSection } from 'common/Card'
import { HighlightedCode } from 'common/HighlightedCode'
import { Link } from 'react-router-dom'
import { Button, Grid, Icon, List } from 'semantic-ui-react'

export const GettingStarted: React.FC = () => {
  const { spacing, fontSizes, colors } = useTheme()
  return (
    <Grid style={{ marginTop: spacing(4) }}>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Card>
            <CardContent>
              <CardHeader>Enterprise Deployments</CardHeader>

              <CardSection>
                Run a W&amp;B server locally on your own infrastructure with a
                trial license.
              </CardSection>

              <List
                bulleted
                style={{
                  fontSize: fontSizes.lg,
                  color: colors.gray[700],
                  marginBottom: spacing(24)
                }}
              >
                <List.Item>Storage petabytes of data</List.Item>
                <List.Item>Single Sign On support</List.Item>
                <List.Item>
                  Dedicated Machine Learning Engineer to ensure success
                </List.Item>
                <List.Item>Flexible deployment options</List.Item>
                <List.Item>Support SLA</List.Item>
                <List.Item>Service account for CI workflows</List.Item>
              </List>
              <Button
                as={Link}
                to="/deploy"
                fluid
                primary
                icon="rocket"
                size="large"
                content="Get a Trial License"
              />
            </CardContent>
          </Card>
          <Card style={{ marginTop: spacing(8) }}>
            <CardContent>
              <CardHeader>New to have W&amp;B Server?</CardHeader>
              <CardSection>
                <Box>
                  <a
                    href="https://docs.wandb.ai/guides/hosting"
                    style={{ fontSize: '1.15em' }}
                  >
                    Learn more about private hosting
                    <Icon
                      style={{ marginLeft: spacing(2), fontSize: '0.75em' }}
                      name="arrow right"
                    />
                  </a>
                </Box>
                <Box style={{ paddingTop: spacing(2) }}>
                  <Link to="/deploy" style={{ fontSize: '1.15em' }}>
                    Generate a license
                    <Icon
                      style={{ marginLeft: spacing(2), fontSize: '0.75em' }}
                      name="arrow right"
                    />
                  </Link>
                </Box>
              </CardSection>
            </CardContent>
          </Card>
        </Grid.Column>
        <Grid.Column>
          <Card>
            <CardContent>
              <CardHeader>W&amp;B Server Quickstart</CardHeader>
              <CardSection>
                <List ordered>
                  <List.Item>
                    On any machine with{' '}
                    <a href="https://www.docker.com/">Docker</a> and{' '}
                    <a href="https://www.python.org/">Python</a> installed, run:
                    <HighlightedCode language="bash" showCopyButton>
                      {`pip install wandb
wandb server start`}
                    </HighlightedCode>
                  </List.Item>
                  <List.Item>
                    Generate a{' '}
                    <Link to="deploy" style={{ fontSize: '1.15em' }}>
                      free license
                    </Link>
                    .
                  </List.Item>
                  <List.Item>
                    Add it to your server settings.
                    <img
                      style={{ marginTop: spacing(2) }}
                      width="100%"
                      alt="How to set license information"
                      src="https://1039519455-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F-Lqya5RvLedGEWPhtkjU-1972196547%2Fuploads%2FnSOt1OYNLj1UpLRI299a%2FLicense.gif?alt=media&token=898e73c2-cca6-45ff-9ee0-85d501804cf0"
                    />
                  </List.Item>
                </List>
              </CardSection>
            </CardContent>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
