import { ApolloProvider } from '@apollo/client'
import { Theme, ThemeProvider } from '@wandb/ui'
import { SegmentProvider } from 'common/segment'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { WBTheme } from 'theme'

import { client } from './apollo'
import App from './App'
import reportWebVitals from './reportWebVitals'

const overwriteWandbUiTheme: Partial<Theme> = {
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          marginTop: '4px',
          height: '29px',
          width: '30px'
        }
      }
    }
  }
}

const Providers: React.FC = ({ children }) => (
  <ThemeProvider muiThemeOverwrite={overwriteWandbUiTheme}>
    <ApolloProvider client={client}>
      <SegmentProvider>{children}</SegmentProvider>
    </ApolloProvider>
  </ThemeProvider>
)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Providers>
        <WBTheme />
        <App />
      </Providers>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function to
// log results (for example: reportWebVitals(console.log)) or send to an
// analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
