import Container from '@mui/material/Container'
import { styled } from '@wandb/ui'
import { useEffect } from 'react'
import { Header } from 'semantic-ui-react'

export const PageContainer = styled(Container)`
  padding: ${p => p.theme.spacing(10)};
`
export const Page: React.FC<{ title: string }> = ({ title, children }) => {
  useEffect(() => {
    document.title = `${title} – Weights & Biases`
  }, [title])
  return <>{children}</>
}

export const PageHeader = styled(Header)`
  margin: 0 !important;
  font-size: ${p => p.theme.fontSizes.xl3} !important;
  color: ${p => p.theme.colors.gray[800]} !important;
`
