/* eslint-disable jsx-a11y/label-has-associated-control */
import { MenuItem } from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Select from '@mui/material/Select'
import { useTheme } from '@wandb/ui'
import { Card, CardContent } from 'common/Card'
import { Flex } from 'common/Flex'
import { useDisclosure } from 'common/hooks/useDisclosure'
import { useNotAdminRedirect } from 'common/hooks/useNotAdminRedirect'
import { InlineCode } from 'common/InlineCode'
import { LicenseFlagsInput } from 'common/license/LicenseFlagsInput'
import { Page, PageContainer, PageHeader } from 'common/Page'
import { format, parse } from 'date-fns/esm'
import {
  DeploymentType,
  LicenseFlagValue,
  LicenseOrderCreateInput,
  useCreateDeploymentMutation
} from 'generated/deploy'
import {
  useAddUserToOrganizationMutation,
  useCreateOrganizationMutation,
  useOrganizationLazyQuery,
  useOrganizationsSearchLazyQuery
} from 'generated/gorilla'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { Button, Checkbox, Form, Grid, Header } from 'semantic-ui-react'
import { isPresent } from 'ts-is-present'

type DeployOrderFormState = Omit<LicenseOrderCreateInput, 'expiresAt'> & {
  flags: LicenseFlagValue[]
  organizationName: string
  trial: boolean
  customerEmail: string
  maxTeams: number
  maxUsers: number
  maxViewOnlyUsers: number
  maxRegisteredModels: number
  maxStorageGb: number
  expiresAt: string
}

const useUpsertOrganization = () => {
  const [getOrganization] = useOrganizationLazyQuery()
  const [createOrganization, r] = useCreateOrganizationMutation()
  return [
    useCallback(
      async (name: string) => {
        const orgResult = await getOrganization({ variables: { name } })
        const existingOrg = orgResult.data?.organization
        if (existingOrg != null) {
          return existingOrg.id
        }
        const { data } = await createOrganization({
          variables: { input: { newOrganizationName: name } }
        })
        return data?.createLocalLicenseOrganization?.organization.id
      },
      [createOrganization, getOrganization]
    ),
    r
  ] as const
}

const useUpsertOrganizationMember = () => {
  const [getOrganization] = useOrganizationLazyQuery()
  const [addUser, r] = useAddUserToOrganizationMutation()
  return [
    useCallback(
      async (orgName: string, email: string) => {
        const { data } = await getOrganization({ variables: { name: orgName } })
        const organization = data?.organization
        if (organization == null) return null
        const { members, pendingInvites } = organization
        const user =
          members.find(m => m.user.email === email) ??
          pendingInvites.map(p => p.toUser?.email === email)
        if (user == null) return null
        await addUser({
          variables: {
            input: {
              userName: email,
              organizationId: organization.id,
              userOrgRole: 'admin'
            }
          }
        })
      },
      [addUser, getOrganization]
    ),
    r
  ] as const
}

const useDeployOrderForm = () => {
  const form = useForm<DeployOrderFormState>({
    defaultValues: {
      trial: true,
      flags: [],
      maxUsers: 20,
      maxViewOnlyUsers: 0,
      organizationName: '',
      maxStorageGb: 1_000_000,
      maxTeams: 1,
      maxRegisteredModels: 5,
      expiresAt: format(new Date(), 'yyyy-MM-dd')
    }
  })

  const [deploymentId, setDeploymentId] = useState('')
  const orderUrl = `${window.location.origin}/=${deploymentId}`
  const dialog = useDisclosure({
    onClose: () => {
      form.reset()
      setDeploymentId('')
    }
  })

  const [upsertOrganization, upsertResult] = useUpsertOrganization()
  const [upsertOrganizationUser, userResult] = useUpsertOrganizationMember()
  const [createDeployment, deploymentResult] = useCreateDeploymentMutation()

  const onSubmit = form.handleSubmit(async form => {
    const { expiresAt, organizationName, customerEmail, ...rest } = form
    const expiredAtDate = parse(expiresAt, 'yyyy-MM-dd', new Date())
    const orgId = await upsertOrganization(organizationName)
    if (orgId == null) return
    await upsertOrganizationUser(orgId, customerEmail)
    const { data } = await createDeployment({
      variables: {
        data: {
          name: 'Production',
          organizationId: orgId,
          type: DeploymentType.Manual,
          license: {
            ...rest,
            expiresAt: expiredAtDate
          }
        }
      }
    })
    setDeploymentId(data?.createDeployment?.id ?? '')
    dialog.onOpen()
  })

  const error = userResult.error ?? upsertResult.error ?? deploymentResult.error
  return { ...form, onSubmit, ...dialog, orderUrl, error }
}

const DeployOrder: React.FC = () => {
  useNotAdminRedirect()

  const {
    onSubmit,
    control,
    register,
    isOpen,
    onClose,
    orderUrl,
    watch,
    error
  } = useDeployOrderForm()

  const { customerEmail } = watch()
  const { fontWeights, colors, spacing } = useTheme()

  const [getOrganizations] = useOrganizationsSearchLazyQuery()
  const loadOptions = useCallback(
    async (name: string) => {
      const { data } = await getOrganizations({
        variables: { search: name, first: 4 }
      })
      return (
        data?.organizations.edges
          .map(d => d.node)
          .filter(isPresent)
          .flatMap(d => ({ label: d.name, value: d.name })) ?? []
      )
    },
    [getOrganizations]
  )

  return (
    <Page title="New Deployment">
      <PageContainer maxWidth="md">
        <PageHeader style={{ paddingBottom: spacing(2) }}>
          New License
        </PageHeader>

        <Box pb={spacing(8)} color={colors.gray[600]}>
          Create deployments on behalf of customers.
        </Box>

        <Card>
          <CardContent style={{ margin: spacing(8) }}>
            <Form onSubmit={onSubmit}>
              <Form.Field>
                <Controller
                  control={control}
                  name="trial"
                  render={({ field: { value, onChange } }) => (
                    <Flex alignItems="center">
                      <Checkbox
                        checked={value}
                        onChange={() => onChange(!value)}
                        label="Trial"
                        style={{ fontWeight: fontWeights.bold }}
                      />
                      <p
                        style={{
                          paddingLeft: spacing(4),
                          color: colors.gray[500]
                        }}
                      >
                        Is the customer on a trial?
                      </p>
                    </Flex>
                  )}
                />
              </Form.Field>
              <Form.Field required>
                <label>Organization</label>
                <Flex>
                  <Controller
                    control={control}
                    name="organizationName"
                    render={({ field: { value, onChange } }) => (
                      <Box sx={{ width: 300 }}>
                        <AsyncCreatableSelect
                          cacheOptions
                          value={
                            value == null ? undefined : { label: value, value }
                          }
                          // value={value}
                          onChange={v => onChange(v?.value ?? '')}
                          // onChange={item => onChange(item)}
                          loadOptions={loadOptions}
                        />
                      </Box>
                    )}
                  />
                </Flex>
              </Form.Field>
              <Form.Field>
                <label>Customer Email</label>
                <input
                  {...register('customerEmail')}
                  style={{ maxWidth: 300 }}
                />
              </Form.Field>
              <Form.Field>
                <label>Flags</label>
                <Controller
                  control={control}
                  name="flags"
                  render={({ field: { value, onChange } }) => (
                    <LicenseFlagsInput
                      value={value as LicenseFlagValue[]}
                      onChange={onChange}
                      selectAll
                    />
                  )}
                />
              </Form.Field>
              <Form.Field>
                <Grid>
                  <Grid.Row columns={5}>
                    <Grid.Column>
                      <Form.Field>
                        <label>Seats</label>
                        <input
                          {...register('maxUsers', { valueAsNumber: true })}
                          type="number"
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>View-Only Seats</label>
                        <input
                          {...register('maxViewOnlyUsers', {
                            valueAsNumber: true
                          })}
                          type="number"
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Teams</label>
                        <input
                          {...register('maxTeams', { valueAsNumber: true })}
                          type="number"
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Storage (GB)</label>
                        <input
                          {...register('maxStorageGb', { valueAsNumber: true })}
                          type="number"
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>Registered Models</label>
                        <Select
                          defaultValue={5}
                          style={{ minWidth: spacing(55), height: spacing(11) }}
                          {...register('maxRegisteredModels', {
                            valueAsNumber: true
                          })}
                        >
                          <MenuItem value={1_000_000}>
                            Unlimited - 1,000,000
                          </MenuItem>
                          <MenuItem value={5}>Trial - 5</MenuItem>
                          <MenuItem value={2}>Default - 2</MenuItem>
                        </Select>
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={4}>
                    <Grid.Column>
                      <Form.Field>
                        <label>Expiration Date</label>
                        <input {...register('expiresAt')} type="date" />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Button icon="plus" content="Create" color="green" />
              {error && (
                <div style={{ color: colors.red[500], padding: spacing(4, 0) }}>
                  {error.graphQLErrors.map(s => (
                    <div key={s.name}>{s.message}</div>
                  ))}
                </div>
              )}
            </Form>
          </CardContent>
        </Card>

        <Dialog open={isOpen} onClose={onClose}>
          <DialogTitle>
            <Header style={{ marginTop: spacing(2) }}>
              License Order Created
            </Header>
          </DialogTitle>

          <DialogContent>
            {customerEmail && (
              <p>
                An email has been sent to{' '}
                <InlineCode>{customerEmail}</InlineCode>
              </p>
            )}
            <code>{orderUrl}</code>
          </DialogContent>
          <DialogActions>
            <Button
              icon="clone"
              content="Copy"
              primary
              onClick={() => navigator.clipboard.writeText(orderUrl)}
            />
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
    </Page>
  )
}

export default DeployOrder
