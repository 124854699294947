import Box from '@mui/material/Box'
import { useTheme } from '@wandb/ui'
import { Flex } from 'common/Flex'
import { Page, PageContainer } from 'common/Page'
import { DeploymentType } from 'generated/deploy'
import first from 'lodash/first'

import { DeploymentHeader } from './components/DeploymentHeader'
import { DeploymentCardInfo } from './components/overview/DeploymentCardInfo'
import { DeploymentCardLicense } from './components/overview/DeploymentCardLicense'
import { DeploymentSectionLatestRelease } from './components/overview/DeploymentSectionLatestRelease'
import { DeploymentSectionLicense } from './components/overview/DeploymentSectionLicense'
import { DeploymentSectionManual } from './components/overview/DeploymentSectionManual'
import { DeploymentSectionReleases } from './components/overview/DeploymentSectionReleases'
import { useRouteDeployment } from './components/useRouteDeployment'

const DeploymentPage: React.FC = () => {
  const { deployment } = useRouteDeployment()
  const { spacing } = useTheme()
  return (
    <Page title="Deployment">
      <PageContainer>
        <DeploymentHeader />

        <Flex>
          <Box flexGrow={1}>
            {deployment?.type === DeploymentType.Manual && (
              <>
                <DeploymentSectionLicense deployment={deployment} />
                <DeploymentSectionManual />
              </>
            )}

            {(deployment?.releases?.length ?? 0) !== 0 && (
              <>
                <DeploymentSectionLatestRelease
                  releaseId={first(deployment?.releases)?.id ?? ''}
                />
                <DeploymentSectionReleases deployment={deployment} />
              </>
            )}
          </Box>

          <Box flexShrink={0} maxWidth={spacing(150)} marginLeft={spacing(10)}>
            <DeploymentCardInfo deployment={deployment} />
            {(deployment?.licenses?.nodes ?? []).length > 0 && (
              <DeploymentCardLicense deployment={deployment} />
            )}
          </Box>
        </Flex>
      </PageContainer>
    </Page>
  )
}

export default DeploymentPage
