import { useTheme } from '@wandb/ui'
import { Flex } from 'common/Flex'
import { WBLogo } from 'common/navbar/WBLogo'
import { Page, PageContainer } from 'common/Page'
import { Header } from 'semantic-ui-react'

/**
 * Temp page used to while the deployment api is worked on
 */
const ContactSales: React.FC = () => {
  const { spacing, colors, fontSizes, letterSpacings } = useTheme()

  return (
    <Page title="Local Sign Up">
      <div
        style={{
          position: 'absolute',
          color: colors.white,
          minWidth: '100vw',
          minHeight: '100vh',
          backgroundColor: colors.gray[900]
        }}
      >
        <PageContainer maxWidth="xs">
          <Flex
            style={{ transform: 'scale(1.6)', margin: spacing(14, 0, 8, 0) }}
            justifyContent="center"
            alignItems="center"
          >
            <WBLogo />
            <h1 style={{ margin: spacing(0, 8, 0, 0), fontSize: fontSizes.xl }}>
              Weights & Biases Local
            </h1>
          </Flex>

          <div style={{ fontSize: fontSizes.xl }}>
            <Header
              style={{
                color: colors.white,
                padding: spacing(10, 0, 0, 0),
                textAlign: 'center',
                letterSpacing: letterSpacings.wide
              }}
            >
              Contact sales to get a license.
            </Header>

            <Header style={{ textAlign: 'center' }}>
              <a
                href="emailto:sales@wandb.com"
                style={{ color: colors.blue[300] }}
              >
                sales@wandb.com
              </a>
            </Header>
          </div>
        </PageContainer>
      </div>
    </Page>
  )
}

export const publicRoute = true
export const hideNavbar = true
export default ContactSales
