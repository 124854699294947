import { useGetReferrer } from 'common/RedirectHelper'
import {
  DeploymentCreateInput,
  DeploymentType,
  useCreateDeploymentFromOrderMutation,
  useCreateDeploymentMutation,
  useCreateDeploymentTrialMutation,
  useLicenseOrderQuery
} from 'generated/deploy'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { DeployStepper } from './DeployStepper'
import { DeployFormAuthenticate } from './form/DeployFormAuthenticate'
import { DeployFormCreate } from './form/DeployFormCreate'
import { DeployFormLicense } from './form/DeployFormLicense'
import { DeployFormPlatform } from './form/DeployFormPlatform'

export type DeployFormState = DeploymentCreateInput & { trial: boolean }

export type DeployFormContextState = {
  step: number
  setState: React.Dispatch<DeployFormState>
  state: DeployFormState
  next: () => void
  previous: () => void
}

export function useDeployFormState(): DeployFormContextState {
  const [step, setStep] = useState(0)
  const next = useCallback(() => setStep(s => s + 1), [])
  const previous = useCallback(() => setStep(s => s - 1), [])

  const [state, setState] = useState<DeployFormState>({
    type: DeploymentType.Manual,
    trial: true,
    organizationId: '',
    name: 'Production',
    description: ''
  })

  return { state, setState, step, next, previous }
}

const DeployFormContext = createContext<DeployFormContextState>({} as any)

export const DeployFormProvider = DeployFormContext.Provider

/**
 * Helper hook for creating deployments.
 */
const useCreateDeployment = (state: DeployFormState) => {
  const order = useLicenseOrder()
  const [createDeploymentFromOrder, deploymentFromOrder] =
    useCreateDeploymentFromOrderMutation()
  const [createDeploymentTrial, deploymentTrial] =
    useCreateDeploymentTrialMutation()
  const [createDeployment, deployment] = useCreateDeploymentMutation()

  const create = async (newestState?: Partial<DeployFormState>) => {
    const { trial, ...data } = { ...state, ...newestState }
    const { hasOrder, licenseOrder } = order

    if (hasOrder && licenseOrder != null) {
      const result = await createDeploymentFromOrder({
        variables: { data: { ...data, licenseOrderId: licenseOrder.id } }
      })
      return result.data?.createDeploymentFromOrder?.id
    }

    if (trial) {
      const result = await createDeploymentTrial({
        variables: { data }
      })
      return result.data?.createDeploymentTrial?.id
    }
    const result = await createDeployment({ variables: { data } })
    return result.data?.createDeployment?.id
  }

  const loading =
    deploymentFromOrder.loading || deploymentTrial.loading || deployment.loading
  const error =
    deploymentFromOrder.error || deploymentTrial.error || deployment.error
  return { createDeployment: create, loading, error }
}

export const useLicenseOrder = () => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const orderId = query.get('order')
  const hasOrder = orderId != null

  const { data } = useLicenseOrderQuery({
    variables: { id: orderId ?? '' },
    skip: !hasOrder
  })
  const history = useHistory()
  const deleteParams = () => {
    query.delete('order')
    history.replace({ search: query.toString() })
  }
  const licenseOrder = useMemo(() => data?.licenseOrder, [data])
  const isClaimed = hasOrder && licenseOrder?.deploymentId != null
  return { hasOrder, licenseOrder, isClaimed, deleteParams }
}

export const useDeployForm = () => {
  const referrer = useGetReferrer(false)

  const form = useContext(DeployFormContext)
  const { error, loading, createDeployment } = useCreateDeployment(form.state)

  const history = useHistory()
  const onSubmit = async (state?: Partial<DeployFormState>) => {
    const deploymentId = await createDeployment(state)
    if (deploymentId != null)
      history.push({
        pathname: `/${deploymentId}`,
        search: `?ref=${referrer}`,
        state: { referrer }
      })
  }

  return { ...form, ...useLicenseOrder(), loading, onSubmit, error }
}

export const FormFooter = styled.div`
  margin-top: ${p => p.theme.spacing(8)};
`

export type DeployFormProps = {
  hideAuthenticateStep?: boolean
  hidePlatformStep?: boolean
}

export const DeployForm: React.FC<DeployFormProps> = props => {
  const { hideAuthenticateStep, hidePlatformStep } = props
  const form = useDeployFormState()

  const steps: Record<string, JSX.Element> = {}
  if (!hideAuthenticateStep) steps.Authenticate = <DeployFormAuthenticate />
  if (!hidePlatformStep) steps['Choose Platform'] = <DeployFormPlatform />
  steps['Basic Information'] = <DeployFormLicense />
  steps['Get License'] = <DeployFormCreate />

  return (
    <DeployFormProvider value={form}>
      <DeployStepper activeStep={form.step} steps={steps} />
    </DeployFormProvider>
  )
}
