import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { InlineCode } from 'common/InlineCode'
import { Section } from 'common/Section'
import { DeploymentState } from 'generated/deploy'
import { Table } from 'semantic-ui-react'

export const DeploymentStateResourceCard: React.FC<
  Partial<DeploymentState> & { loading?: boolean }
> = ({ resources, loading }) => {
  const { colors } = useTheme()
  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>Resources</CardHeader>
        </CardContent>

        <Table
          style={{
            border: 'none',
            borderTop: `1px solid ${colors.gray[300]}`,
            marginTop: 0
          }}
        >
          <Table.Header>
            <Table.HeaderCell>Module</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Count</Table.HeaderCell>
          </Table.Header>

          <Table.Body>
            {resources?.map(o => (
              <Table.Row key={o.name}>
                <Table.Cell>{o.module}</Table.Cell>
                <Table.Cell>
                  <InlineCode>
                    {o.type}.{o.name}
                  </InlineCode>
                </Table.Cell>
                <Table.Cell>{o.count}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          {resources == null && !loading && (
            <CardContent>No resources found.</CardContent>
          )}
          {resources == null && <CardContent>No resources found.</CardContent>}
        </Table>
      </Card>
    </Section>
  )
}
