/* eslint-disable jsx-a11y/label-has-associated-control */
import Box from '@mui/material/Box'
import { useTheme } from '@wandb/ui'
import { Flex } from 'common/Flex'
import { TerraformConfigRender } from 'common/terraform/TerraformConfigRender'
import { DeploymentType } from 'generated/deploy'
import { useForm } from 'react-hook-form'
import { Button, Divider, Form } from 'semantic-ui-react'

import { DeployFormState, FormFooter, useDeployForm } from '../DeployForm'
import { BackButton } from './ButtonHelper'

type FormInfo = Pick<DeployFormState, 'name' | 'description'> & {
  branch: string
}

const useFormInfo = () => {
  const { state, setState, onSubmit } = useDeployForm()
  const form = useForm<FormInfo>({ defaultValues: state })
  const onSubmitInfo = form.handleSubmit(data => {
    setState({ ...state, ...data })
    onSubmit(data)
  })
  return { ...form, onSubmitInfo }
}

const deploymentTypeNames: Record<DeploymentType, string> = {
  [DeploymentType.Aws]: 'Amazon Web Services',
  [DeploymentType.Gcp]: 'Google Cloud Platform',
  [DeploymentType.Manual]: 'Manual',
  [DeploymentType.TerraformTest]: 'Testing Terraform',
  [DeploymentType.Azure]: 'Azure'
}

export const DeployFormCreate: React.FC = () => {
  const { state, setState, onSubmit, error, loading, previous } =
    useDeployForm()

  const { watch, register, onSubmitInfo, getValues } = useFormInfo()

  const name = watch('name')
  const submitDisabled = !name || loading

  const { colors, spacing } = useTheme()

  return (
    <>
      <Form onSubmit={onSubmitInfo}>
        {/* <Header>Infomation</Header> */}
        <Form.Field required>
          <label>Name of Instance</label>
          <input {...register('name')} placeholder="Name of your deployment" />
        </Form.Field>
        <Form.Field>
          <label>Description</label>
          <textarea
            {...register('description')}
            placeholder="Add a description to share any details"
            rows={2}
          />
        </Form.Field>

        {state.type === DeploymentType.Manual ? (
          <FormFooter>
            <BackButton type="button" onClick={previous} />

            <Button
              type="submit"
              icon="key"
              color="green"
              loading={loading}
              disabled={submitDisabled}
              content="Generate License Key"
            />
          </FormFooter>
        ) : (
          <Form.Field>
            <label>Branch</label>
            <input {...register('branch')} placeholder="master" />
          </Form.Field>
        )}
      </Form>

      {state.type !== DeploymentType.Manual && (
        <>
          <Flex style={{ margin: spacing(6, 0) }} alignItems="center">
            <Divider style={{ flexGrow: 1 }} />
            <Box marginY={2} marginX={4} color={colors.grey[500]}>
              {deploymentTypeNames[state.type]} Configuration
            </Box>
            <Divider style={{ flexGrow: 1 }} />
          </Flex>

          <TerraformConfigRender
            type={state.type}
            loading={loading}
            onSubmit={variables => {
              const { branch, ...info } = getValues()
              const workspace =
                branch == null || branch === ''
                  ? undefined
                  : { versionControl: { branch } }
              const data = { ...info, variables, workspace }
              setState({ ...state, ...data })
              onSubmit(data)
            }}
            footer={
              <FormFooter>
                <BackButton type="button" onClick={previous} />
                <Button
                  type="submit"
                  icon="key"
                  color="green"
                  loading={loading}
                  content="Deploy"
                />
              </FormFooter>
            }
          />
        </>
      )}

      <div style={{ padding: spacing(4, 0, 0, 0), color: colors.red[300] }}>
        {error?.message}
      </div>
    </>
  )
}
