import { useCallback, useState } from 'react'

export interface UseDisclosureProps {
  defaultIsOpen?: boolean
  onClose?(): void
  onOpen?(): void
}

/**
 * Used to help handle common open, close, or toggle scenarios. It can be used
 * to control feedback component such as Modal, AlertDialog, Drawer, etc.
 */
export const useDisclosure = (props?: UseDisclosureProps) => {
  const { onClose: onCloseProp, onOpen: onOpenProp } = props ?? {}
  const [isOpen, setIsOpen] = useState(props?.defaultIsOpen || false)

  const onClose = useCallback(() => {
    setIsOpen(false)
    onCloseProp?.()
  }, [onCloseProp])

  const onOpen = useCallback(() => {
    setIsOpen(true)
    onOpenProp?.()
  }, [onOpenProp])

  const onToggle = useCallback(() => {
    const action = isOpen ? onClose : onOpen
    action()
  }, [isOpen, onOpen, onClose])

  return { isOpen, onOpen, onClose, onToggle }
}
