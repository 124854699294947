import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle
} from '@mui/material'
import { useTheme } from '@wandb/ui'
import { useCallback } from 'react'
import { Button, Header } from 'semantic-ui-react'

import { useGetReferrer } from './RedirectHelper'

export const RedirectDialog: React.FC<DialogProps> = props => {
  const referrer = useGetReferrer(true)
  const redirect = useCallback(
    () => window.location.replace(referrer),
    [referrer]
  )
  const { spacing } = useTheme()
  return (
    <Dialog {...props} fullWidth maxWidth="xs">
      <DialogTitle>
        <Header style={{ marginTop: spacing(2) }}>Copied</Header>
      </DialogTitle>
      <DialogContent>
        {referrer ? (
          <p>
            Deployment found at {`${referrer}`} <br /> Confirm to redirect
          </p>
        ) : (
          <p>Paste license into your local deployment system settings page</p>
        )}
      </DialogContent>
      <DialogActions style={{ margin: spacing(2) }}>
        <Button
          onClick={e => props.onClose?.(e as any, 'escapeKeyDown')}
          content="Close"
          secondary
        />
        {referrer && (
          <Button
            onClick={() => {
              redirect()
            }}
            primary
            color="green"
            content="Confirm"
          ></Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
