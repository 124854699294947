/* eslint-disable jsx-a11y/label-has-associated-control */
import { DialogTitle } from '@mui/material'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useTheme } from '@wandb/ui'
import {
  OrganizationCreationFailedReason,
  useOrganizationCreateFailedSegmentEvent
} from 'common/useOrganizationCreateSegmentEvent'
import {
  CreateLocalLicenseOrganizationInput,
  useCreateOrganizationMutation
} from 'generated/gorilla'
import { useForm } from 'react-hook-form'
import { Button, Form, Header } from 'semantic-ui-react'

export const ModalNewOrganization: React.FC<
  DialogProps & { onCreate?: (orgId: string) => void }
> = props => {
  const [createOrganization, { error }] = useCreateOrganizationMutation()
  const { spacing, colors } = useTheme()

  const { register, handleSubmit, setError } =
    useForm<CreateLocalLicenseOrganizationInput>()

  const trackOrgFailedEvent = useOrganizationCreateFailedSegmentEvent()
  const onSubmit = handleSubmit(async (input, event) => {
    try {
      const result = await createOrganization({ variables: { input } })
      const orgId = result.data?.createLocalLicenseOrganization?.organization.id
      if (orgId != null) props.onCreate?.(orgId)
      props.onClose?.(event as any, 'escapeKeyDown')
    } catch (e: any) {
      const { statusCode } = e.networkError
      const isInvalidInput = statusCode === 409
      const reason = isInvalidInput
        ? OrganizationCreationFailedReason.AlreadyExists
        : OrganizationCreationFailedReason.Unknown

      trackOrgFailedEvent({
        ...input,
        statusCode,
        reason
      })

      setError('newOrganizationName', {
        message: isInvalidInput
          ? 'Organization already exists.'
          : 'Error while trying to create organization'
      })
    }
  })

  return (
    <Dialog {...props} fullWidth maxWidth="sm">
      <Form>
        <DialogTitle>
          <Header style={{ marginTop: spacing(2) }}>New Organization</Header>
        </DialogTitle>
        <DialogContent>
          <Form.Field required>
            <label>Organization Name</label>
            <input {...register('newOrganizationName')} />
          </Form.Field>

          <DialogActions>
            {error && (
              <div style={{ color: colors.red[500], padding: spacing(4, 2) }}>
                Invalid organization name or organization already exists.
              </div>
            )}
            <Button
              type="button"
              onClick={onSubmit}
              color="green"
              content="Create"
              icon="plus"
            />
          </DialogActions>
        </DialogContent>
      </Form>
    </Dialog>
  )
}
