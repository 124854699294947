/* eslint-disable jsx-a11y/label-has-associated-control */
import Box from '@mui/material/Box'
import { Card } from 'common/Card'
import { useMemo, useState } from 'react'
import { Controller, useForm, UseFormRegister } from 'react-hook-form'
import { Checkbox, Dropdown, Form } from 'semantic-ui-react'

import { TerraformInputSso } from './TerraformInputOidc'
import {
  ArrayToObject,
  findValue,
  ObjectToArray,
  TerraformConfigFormFC,
  TerraformConfigFormFCProps,
  tshirtSizeOptions,
  variable
} from './utils'

/**
 * The options here should match the options for the `region` variable in
 * google/single-teant/variables.tf
 *
 * @link https://cloud.google.com/compute/docs/regions-zones
 */
const awsRegionsOptions = Object.entries({
  'US East (South Carolina)': 'us-east1',
  'US East (Northern Virginia)': 'us-east4',
  'US Central (Iowa)': 'us-central1',
  'US West (Oregon)': 'us-west1',
  'US West (Los Angeles)': 'us-west2',
  'US West (Las Vegas)': 'us-west4',
  // 'North America (Montréal)': 'northamerica-northeast1',
  'North America (Toronto)': 'northamerica-northeast2',
  'Australia (Sydney)': 'australia-southeast1',
  'Europe (Belgium)': 'europe-west1',
  'Europe (London)': 'europe-west2',
  'Europe (Frankfurt)': 'europe-west3',
  'Europe (Netherlands)': 'europe-west4',
  'Asia (Tokyo)': 'asia-northeast1',
  'Asia (Seoul)': 'asia-northeast3'
  // 'Europe (Netherlands)': 'europe-west4',
  // 'Europe (Paris)': 'europe-west9'
}).map(([text, value]) => ({ value, text: `${text} [${value}]` }))

type TerraformGcpFormState = {
  subdomain: string
  region: string
  oidcIssuer: string
  oidcClientId: string
  oidcAuthMethod: string
  bucketName: string
  sqlDefaultEncryption: boolean
  bucketDefaultEncryption: boolean
  tshirtSize: string
}

const variablesObjectToArray: ObjectToArray<TerraformGcpFormState> = obj => {
  return [
    variable('subdomain', obj.subdomain),
    variable('region', obj.region),
    // variable('allowed_inbound_cidr', obj.allowedInboundCidr),
    // variable('allowed_inbound_ipv6_cidr', obj.allowedInboundIpV6Cidr),

    variable('oidc_issuer', obj.oidcIssuer),
    variable('oidc_client_id', obj.oidcClientId),
    variable('oidc_auth_method', obj.oidcAuthMethod),

    variable('bucket_name', obj.bucketName),
    variable('sql_default_encryption', obj.sqlDefaultEncryption),
    variable('bucket_default_encryption', obj.bucketDefaultEncryption),
    variable('size', obj.tshirtSize)
  ]
}

/**
 * Convert terraform enviroment variables into form state object
 */
const variablesArrayToObject: ArrayToObject<
  TerraformGcpFormState
> = variables => {
  const find = (v: string) => findValue(variables, v)

  return {
    subdomain: find('subdomain') ?? '',
    region: find('region') ?? '',

    wandbImage: find('wandb_image') ?? 'wandb/local',

    oidcAuthMethod: find('oidc_auth_method') ?? '',
    oidcClientId: find('oidc_client_id') ?? '',
    oidcIssuer: find('oidc_issuer') ?? '',

    bucketName: find('bucket_name') ?? '',
    sqlDefaultEncryption: true,
    bucketDefaultEncryption: true,
    tshirtSize: find('tshirt_size') ?? 'small'
  }
}

type TerraformInputByobProps = {
  register: UseFormRegister<{ bucketName: string }>
}

const TerraformInputGcpByob: React.FC<TerraformInputByobProps> = props => {
  const { register } = props
  const [show, setShow] = useState(false)
  const toggle = () => setShow(s => !s)

  const bucketName = register('bucketName')

  return (
    <Form.Field>
      <Checkbox
        toggle
        onClick={toggle}
        checked={show}
        label="Enable bring your own bucket (BYOB)"
      />

      {show && (
        <Box marginTop={2}>
          <Card>
            <Box margin={6}>
              <Form.Field required>
                <label>Bucket Name</label>
                <input {...bucketName} />
              </Form.Field>
            </Box>
          </Card>
        </Box>
      )}
    </Form.Field>
  )
}

const useGcpForm = (props: TerraformConfigFormFCProps) => {
  const { variables, onSubmit } = props
  const defaultValues = useMemo(
    () => variablesArrayToObject(variables ?? []),
    [variables]
  )
  const form = useForm<TerraformGcpFormState>({ defaultValues })

  const onSubmitForm = form.handleSubmit(async data => {
    const arr = variablesObjectToArray(data)
    await onSubmit(arr)
  })
  return { ...form, onSubmitForm, defaultValues }
}

export const TerraformConfigGcp: TerraformConfigFormFC = props => {
  const { control, register, onSubmitForm } = useGcpForm(props)

  const { variables } = props
  const alreadyHasRegion = useMemo(
    () => findValue(variables ?? [], 'region') != null,
    [variables]
  )
  const alreadyHasSubdomain = useMemo(
    () => findValue(variables ?? [], 'subdomain') != null,
    [variables]
  )

  return (
    <Form onSubmit={onSubmitForm}>
      <Form.Group widths="equal">
        <Form.Field required disabled={alreadyHasRegion}>
          <label>Region</label>
          <Controller
            control={control}
            name="region"
            render={({ field: { value, onChange } }) => (
              <Dropdown
                fluid
                search
                selection
                upward
                autoComplete="off"
                options={awsRegionsOptions}
                value={value}
                onChange={(_, { value }) => onChange(value)}
                placeholder="Select Region"
              />
            )}
          />
        </Form.Field>
        <Form.Field required disabled={alreadyHasSubdomain}>
          <label>Subdomain</label>
          <input {...register('subdomain')} />
        </Form.Field>
      </Form.Group>

      <Form.Field>
        <label>T-Shirt Size</label>
        <Controller
          control={control}
          name="tshirtSize"
          render={({ field: { value, onChange } }) => (
            <Dropdown
              fluid
              selection
              options={tshirtSizeOptions}
              value={value}
              onChange={(_, { value }) => onChange(value)}
              placeholder="Select T-Shirt Size"
            />
          )}
        />
      </Form.Field>

      <TerraformInputGcpByob register={register as any} />
      <TerraformInputSso register={register as any} />

      {props.footer}
    </Form>
  )
}
