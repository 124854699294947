import { CardContent as MuiCardContent } from '@mui/material'
import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { DeploymentRow } from 'common/DeploymentRow'
import { useOrganizationByIdsLoader } from 'common/hooks/useOrganizationByIdsLoader'
import { Section } from 'common/Section'
import { useDeploymentsQuery } from 'generated/deploy'

import { useRouteOrganizationParams } from './useRouteOrganization'

const useDeployments = () => {
  const { orgId } = useRouteOrganizationParams()
  const { data, loading } = useDeploymentsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60 * 1000,
    variables: {
      where: {
        organizationId: { equals: orgId }
      }
    }
  })
  const deployments = data?.deployments?.nodes ?? []
  return { loading, hasDeployments: deployments.length !== 0, deployments }
}

export const OrganizationCardDeployments: React.FC = () => {
  const { deployments } = useDeployments()
  const { colors } = useTheme()
  const orgIds = deployments.map(d => d.organizationId)
  const { getOrganization } = useOrganizationByIdsLoader(orgIds)
  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>Deployments</CardHeader>
        </CardContent>
        {deployments.map(d => (
          <MuiCardContent
            key={d.id}
            style={{
              padding: 0,
              borderTop: `1px solid ${colors.grey[300]}`
            }}
          >
            <DeploymentRow
              {...d}
              organizationName={getOrganization(d.organizationId)?.name}
            />
          </MuiCardContent>
        ))}
      </Card>
    </Section>
  )
}
