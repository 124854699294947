import Box from '@mui/material/Box'
import { useTheme } from '@wandb/ui'
import { Flex } from 'common/Flex'
import { Page, PageContainer } from 'common/Page'
import { Header } from 'semantic-ui-react'

import { OrganizationCardDeployments } from './components/OrganizationCardDeployments'
import { OrganizationCardInfo } from './components/OrganizationCardInfo'
import { OrganizationCardMembers } from './components/OrganizationCardMembers'
import { useRouteOrganization } from './components/useRouteOrganization'

const OrganizationPage: React.FC = () => {
  const { organization } = useRouteOrganization()
  const { spacing } = useTheme()

  return (
    <Page title={organization?.name ?? 'Loading'}>
      <PageContainer>
        <Flex alignItems="center">
          <Header size="large">{organization?.name}</Header>
        </Flex>

        <Flex>
          <Box flexGrow={1}>
            <OrganizationCardDeployments />
          </Box>
          <Box flexShrink={0} width={spacing(90)} marginLeft={spacing(10)}>
            <OrganizationCardInfo organization={organization} />
            <OrganizationCardMembers organization={organization} />
          </Box>
        </Flex>
      </PageContainer>
    </Page>
  )
}

export default OrganizationPage
