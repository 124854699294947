import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import { useTheme } from '@wandb/ui'
import { Flex } from 'common/Flex'
import { Page, PageContainer } from 'common/Page'
import { ReleaseStatusIcon, statusIcon } from 'common/release/ReleaseStatusIcon'
import { useReleaseQuery } from 'generated/deploy'
import { useParams } from 'react-router-dom'
import { Header } from 'semantic-ui-react'

import { ReleaseCardInfo } from './components/release/ReleaseCardInfo'
import { ReleaseCardProgress } from './components/release/ReleaseCardProgress'

const useReleaseParams = () => useParams<{ release: string }>()

const ReleasePage: React.FC = () => {
  const { release: id } = useReleaseParams()
  const { data } = useReleaseQuery({ variables: { id } })
  const { spacing, fontSizes } = useTheme()
  const release = data?.release
  const statusStyle = release ? statusIcon[release?.status]?.style : {}
  const color = statusStyle?.color ?? 'inherit'

  return (
    <Page title="Release">
      <PageContainer>
        <Flex alignItems="center">
          <Chip
            style={{ backgroundColor: color, fontSize: fontSizes.xl }}
            icon={
              <ReleaseStatusIcon
                status={release?.status}
                style={{ color: 'white', paddingLeft: spacing(3) }}
              />
            }
            label={
              <span style={{ color: 'white', fontSize: fontSizes.lg }}>
                {release?.status}
              </span>
            }
          />
          <Header
            style={{
              fontSize: fontSizes.xl2,
              margin: 0,
              paddingLeft: spacing(5)
            }}
          >
            {release?.message ?? 'No message has been set for release.'}
          </Header>
        </Flex>

        <Flex>
          <Box flexGrow={1}>
            <ReleaseCardProgress release={release} />
          </Box>
          <Box flexShrink={0} width={spacing(85)} marginLeft={spacing(10)}>
            <ReleaseCardInfo release={release} />
          </Box>
        </Flex>
      </PageContainer>
    </Page>
  )
}

export default ReleasePage
