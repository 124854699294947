import { Link } from '@mui/material'

export const Terraform: React.FC = () => {
  return (
    <>
      <p>
        You can find more information in the follow terraform module
        repositories:
      </p>
      <p>
        <Link href="https://github.com/wandb/terraform-aws-wandb">
          AWS Module
        </Link>
      </p>
      <p>
        <Link href="https://github.com/wandb/terraform-google-wandb">
          Google Module
        </Link>
      </p>
    </>
  )
}
