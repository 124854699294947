import { useTheme } from '@wandb/ui'

import { Info } from './Info'

export const InfoReleaseChange: React.FC<{
  resourceAdditions?: number | null
  resourceChanges?: number | null
  resourceDestructions?: number | null
}> = props => {
  const { resourceAdditions, resourceChanges, resourceDestructions } = props
  const { colors, spacing } = useTheme()
  return (
    <Info
      name="Resource Changes"
      value={
        <>
          <span style={{ color: colors.green[500] }}>
            +{resourceAdditions ?? 0}
          </span>
          <span
            style={{
              marginLeft: spacing(2),
              color: colors.blue[500]
            }}
          >
            ~{resourceChanges ?? 0}
          </span>
          <span
            style={{
              marginLeft: spacing(2),
              color: colors.red[500]
            }}
          >
            -{resourceDestructions ?? 0}
          </span>
        </>
      }
    />
  )
}
