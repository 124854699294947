import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'
import pick from 'lodash/pick'
import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import { useViewer } from './hooks/useViewer'

const writeKey = '9zUeEVWEZzXdis8uS6DvyUtfORfVGeD5'

const loadAnalytics = async () =>
  writeKey == null ? null : AnalyticsBrowser.load({ writeKey })

const useInitSegment = () => {
  const { loading, value, error } = useAsync(() => loadAnalytics())
  const [analytics, context] = useMemo(() => value ?? [], [value])

  return { loading, error, analytics, context }
}

const SegmentContext = createContext<ReturnType<typeof useInitSegment>>({
  loading: true,
  analytics: undefined,
  error: undefined,
  context: undefined
})

const useIdentity = (analytics: Analytics | undefined) => {
  const { viewer } = useViewer()
  useEffect(() => {
    if (viewer == null) return
    analytics?.identify(
      viewer.id,
      pick(viewer, [
        'id',
        'name',
        'email',
        'orgType',
        'username',
        'flags',
        'admin',
        'authId',
        'accountType'
      ])
    )
  }, [analytics, viewer])
}

const usePageViewTracker = (analytics: Analytics | undefined) => {
  const { pathname } = useLocation()
  const { viewer } = useViewer()

  useEffect(() => {
    if (analytics == null) return
    const { id, email, username } = viewer ?? {}
    analytics.page({ userId: id, email, username })
  }, [analytics, viewer, pathname])
}

export const SegmentProvider: React.FC = ({ children }) => {
  const value = useInitSegment()

  const { analytics } = value
  useIdentity(analytics)
  usePageViewTracker(analytics)

  return (
    <SegmentContext.Provider value={value}>{children}</SegmentContext.Provider>
  )
}

export const useSegment = () => useContext(SegmentContext)
