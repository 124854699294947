import { useOrganizationQuery } from 'generated/gorilla'
import { useParams } from 'react-router-dom'

export const useRouteOrganizationParams = () => useParams<{ orgId: string }>()

/**
 * Get the organization from the router
 */
export const useRouteOrganization = () => {
  const { orgId: id } = useRouteOrganizationParams()
  const { data, ...rest } = useOrganizationQuery({
    variables: { id },
    pollInterval: 10_000
  })

  return { ...data, ...rest }
}
