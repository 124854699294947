import Box from '@mui/material/Box'
import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { useDisclosure } from 'common/hooks/useDisclosure'
import { useCopyLicenseToClipboard } from 'common/license/useLicenseCopy'
import { RedirectDialog } from 'common/RedirectDialog'
import { Section } from 'common/Section'
import { readableFileSize } from 'common/utils/human-readable'
import { DeploymentQuery, LicenseFlagValue } from 'generated/deploy'
import first from 'lodash/first'
import { Button, Grid, Icon } from 'semantic-ui-react'

const gbToBytes = (gb?: number) => (gb ?? 0) * 1000 * 1000 * 1000

const Feature: React.FC<{ enabled?: boolean | null }> = ({
  enabled,
  children
}) => {
  const { spacing, colors, fontSizes } = useTheme()
  return (
    <Box color={enabled ? undefined : colors.grey[500]} fontSize={fontSizes.lg}>
      <Icon
        style={{
          marginTop: spacing(1),
          width: spacing(8),

          color: enabled ? colors.green[500] : undefined
        }}
        name={enabled ? 'check' : enabled === null ? 'minus' : 'times'}
      />
      {children}
    </Box>
  )
}

export const DeploymentSectionLicense: React.FC<{
  deployment: DeploymentQuery['deployment']
}> = ({ deployment }) => {
  const { spacing } = useTheme()
  const license = first(deployment?.licenses?.nodes)

  const flags = license?.flags.map(f => f.flag)
  const scalable = flags?.includes(LicenseFlagValue.Scalable)
  const notifications = flags?.includes(LicenseFlagValue.Notifications)
  const management = flags?.includes(LicenseFlagValue.Management)

  const storageInBytes = gbToBytes(license?.maxStorageGb ?? 0)
  const storageFormatted = readableFileSize(storageInBytes, true, 0)

  const { icon, copyLicense } = useCopyLicenseToClipboard(license)

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>Add License to your Local Instance</CardHeader>

          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Feature enabled>
                  Create up to <strong>{license?.maxTeams} teams</strong>
                </Feature>
                <Feature enabled>
                  Create up to <strong>{license?.maxUsers} users</strong>
                </Feature>
                <Feature enabled>
                  Store up to <strong>{storageFormatted}</strong> of data
                </Feature>
                <Feature enabled>
                  {license?.maxRegisteredModels &&
                  license?.maxRegisteredModels >= 1000000 ? (
                    <>
                      Create an <strong>unlimited</strong> number of Registered
                      Models
                    </>
                  ) : (
                    <>
                      Create up to{' '}
                      <strong>{license?.maxRegisteredModels}</strong> Registered
                      Models
                    </>
                  )}
                </Feature>
              </Grid.Column>
              <Grid.Column>
                <Feature enabled={scalable}>
                  <strong>Scale</strong> horizontally or vertically
                </Feature>
                <Feature enabled={notifications}>
                  Receive <strong>notifications</strong> in other applications
                </Feature>
                <Feature enabled={management}>
                  Access <strong>management tools</strong> to gain insight
                </Feature>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <RedirectDialog open={isOpen} onClose={onClose} />
          <Button
            color="green"
            style={{ marginTop: spacing(8) }}
            onClick={() => {
              copyLicense()
              onOpen()
            }}
            icon={icon}
            content="Copy License"
          />
        </CardContent>
      </Card>
    </Section>
  )
}
