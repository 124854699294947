import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { ReleaseStatusIcon, statusIcon } from 'common/release/ReleaseStatusIcon'
import { Section } from 'common/Section'
import { pascalCaseToTitleCase } from 'common/utils/casing'
import { readableDuration } from 'common/utils/human-readable'
import {
  differenceInMilliseconds,
  formatDistanceToNow,
  parseISO
} from 'date-fns'
import { DeploymentQuery, ReleaseStatus } from 'generated/deploy'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useInterval } from 'react-use'
import { Table } from 'semantic-ui-react'

export const DeploymentSectionReleases: React.FC<{
  deployment: DeploymentQuery['deployment']
}> = ({ deployment }) => {
  const releases = deployment?.releases
  const history = useHistory()
  const { colors, spacing } = useTheme()
  const [now, setNow] = useState(new Date())
  useInterval(() => setNow(new Date()), 1000)
  return (
    <Section>
      <Card>
        <CardContent style={{ padding: spacing(2) }}>
          <CardHeader>Releases</CardHeader>
        </CardContent>
        <Table
          style={{
            border: 'none',
            borderTop: `1px solid ${colors.gray[300]}`,
            marginTop: 0
          }}
          selectable
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Version</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell>Duration</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {releases?.map(r => {
              const createdAt = parseISO(r.createdAt) ?? new Date()
              const createdAtAgo = formatDistanceToNow(createdAt, {
                addSuffix: true
              })
              const statusStyle = statusIcon[r.status]?.style
              const color = statusStyle?.color ?? 'inherit'

              const { appliedAt, erroredAt } = r.statusTimestamps ?? {}
              let durationValue: React.ReactNode = '-'
              const showDuration =
                r.createdAt != null && r.status !== ReleaseStatus.Canceled
              if (showDuration)
                durationValue = readableDuration(
                  differenceInMilliseconds(
                    erroredAt == null
                      ? appliedAt == null
                        ? now
                        : parseISO(appliedAt)
                      : parseISO(erroredAt),
                    createdAt
                  )
                )

              return (
                <Table.Row
                  onClick={() => history.push(`/${deployment?.id}/${r.id}`)}
                  key={r.id}
                  style={{ cursor: 'pointer' }}
                >
                  <Table.Cell style={{ width: spacing(52), color }}>
                    <ReleaseStatusIcon status={r.status} />{' '}
                    {pascalCaseToTitleCase(r.status)}
                  </Table.Cell>
                  <Table.Cell>{r.message}</Table.Cell>
                  <Table.Cell>{createdAtAgo}</Table.Cell>
                  <Table.Cell>{durationValue}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Card>
    </Section>
  )
}
