import { styled, useTheme } from '@wandb/ui'
import { formatDistanceToNow, isBefore, parseISO } from 'date-fns/esm'
import { Grid, Icon } from 'semantic-ui-react'

import { useDeployForm } from '../../DeployForm'

export const Feature: React.FC<{ enabled?: boolean | null }> = ({
  enabled,
  children
}) => {
  const { spacing, colors } = useTheme()
  return (
    <div style={{ color: enabled ? undefined : colors.grey[500] }}>
      <Icon
        style={{
          marginTop: 1,
          width: spacing(6),
          color: enabled ? colors.green[500] : undefined
        }}
        name={enabled ? 'check' : enabled === null ? 'minus' : 'times'}
      />
      {children}
    </div>
  )
}

export const LimitsBackground = styled.div`
  margin: ${p => p.theme.spacing(1, 0)};
  padding: ${p => p.theme.spacing(6)};
  border-radius: ${p => p.theme.radii.md};
  background-color: ${p => p.theme.colors.gray[50]};
`

export const LimitsFeatures = styled.div`
  margin: ${p => p.theme.spacing(8, 0)};
`

export const LimitsFooter = styled.div`
  margin: ${p => p.theme.spacing(0, 0, 0, 1)};
`

/**
 * Displays license order limits attached to the deploy form.
 */
export const LimitsLicenseOrder: React.FC = () => {
  const { colors, fontSizes, fontWeights, spacing } = useTheme()
  const { licenseOrder } = useDeployForm()
  const isLicenseExpired =
    licenseOrder?.expiresAt != null &&
    isBefore(parseISO(licenseOrder.expiresAt), new Date())

  return (
    <LimitsBackground style={{ backgroundColor: colors.green[50] }}>
      <div
        style={{
          fontSize: fontSizes.lg,
          fontWeight: fontWeights.bold,
          marginLeft: spacing(1)
        }}
      >
        {licenseOrder?.trial ? (
          <span>
            <Icon name="info circle" style={{ color: colors.green[700] }} />{' '}
            Your custom trial has unlocked the following features:
          </span>
        ) : (
          <span>
            <Icon name="lock open" style={{ color: colors.green[700] }} /> Your
            license order has unlocked the following features:
          </span>
        )}
      </div>
      <LimitsFeatures>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Feature enabled>
                <strong>Scale</strong> horizontally or vertically
              </Feature>
              <Feature enabled>
                Create up to <strong>{licenseOrder?.maxTeams} teams</strong>
              </Feature>
              <Feature enabled>
                Create up to <strong>{licenseOrder?.maxUsers} user</strong>
              </Feature>
            </Grid.Column>
            <Grid.Column>
              <Feature enabled>Cloud storage buckets</Feature>
              <Feature enabled>High availability</Feature>
              {licenseOrder?.maxViewOnlyUsers ?? (
                <Feature enabled>
                  Create up to{' '}
                  <strong>
                    {licenseOrder?.maxViewOnlyUsers} view-only user
                  </strong>
                </Feature>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </LimitsFeatures>
      <LimitsFooter>
        {licenseOrder?.trial && licenseOrder.expiresAt && (
          <div>
            Your trial {isLicenseExpired ? 'expired' : 'expires in'}{' '}
            {formatDistanceToNow(parseISO(licenseOrder.expiresAt), {
              addSuffix: true
            })}
          </div>
        )}
        <div style={{ color: colors.gray[600], fontSize: fontSizes.sm }}>
          If the information above is incorrect, please contact your Weights &
          Biases representative.
        </div>
      </LimitsFooter>
    </LimitsBackground>
  )
}
