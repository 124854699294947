import 'semantic-ui-css/semantic.min.css'

import { foundations } from '@wandb/ui'
import Zendesk from 'react-zendesk'

import { FileRoutes } from './routes'

const ZENDESK_KEY = 'a0a3439e-c6f9-4864-8d97-4bc7bef38531'
const ZENDESK_SETTINGS = {
  color: {
    theme: foundations.colors.gray[700]
  },
  launcher: {
    chatLabel: {
      'en-US': 'Need Help'
    }
  },
  contactForm: {
    fields: [
      { id: 'description', prefill: { '*': 'My pre-filled description' } }
    ]
  }
}

function App() {
  return (
    <>
      <Zendesk defer zendeskKey={ZENDESK_KEY} {...ZENDESK_SETTINGS} />
      <FileRoutes />
    </>
  )
}

export default App
