import Switch from '@mui/material/Switch'
import { styled, useTheme } from '@wandb/ui'
import { Flex } from 'common/Flex'
import { Grid, Icon } from 'semantic-ui-react'

import { Feature } from './DeployLimits'

const LinkArrow: React.FC = () => {
  const { spacing } = useTheme()
  return (
    <Icon
      name="long arrow alternate right"
      fitted
      style={{ paddingLeft: spacing(1) }}
    />
  )
}

const ContactSalesLink: React.FC = () => {
  const { fontWeights } = useTheme()
  return (
    <a
      href="https://wandb.ai/site/contact"
      style={{ fontWeight: fontWeights.semibold }}
    >
      Contact sales <LinkArrow />
    </a>
  )
}

const EnterpriseLink: React.FC = () => {
  const { fontWeights, spacing } = useTheme()
  return (
    <a
      href="https://wandb.ai/site/enterprise"
      style={{ fontWeight: fontWeights.semibold, marginLeft: spacing(8) }}
    >
      Learn more about Enterprise <LinkArrow />
    </a>
  )
}

const LimitsBackground = styled.div`
  margin: ${p => p.theme.spacing(1, 0)};
  padding: ${p => p.theme.spacing(6)};
  border-radius: ${p => p.theme.radii.md};
  background-color: ${p => p.theme.colors.gray[50]};
`

const LimitsFeatures = styled.div`
  margin: ${p => p.theme.spacing(8, 0)};
`

const LimitsFooter = styled.div`
  margin: ${p => p.theme.spacing(0, 0, 0, 1)};
`

export const DeployLicenseOrderLimits: React.FC = () => {
  return (
    <LimitsBackground>
      <LimitsFeatures></LimitsFeatures>
    </LimitsBackground>
  )
}

export const DeployTrialToggle: React.FC<{
  value: boolean
  onChange?: (v: boolean) => void
}> = ({ value, onChange }) => {
  const { spacing, colors, fontSizes, fontWeights } = useTheme()
  return (
    <LimitsBackground>
      <Flex>
        <Switch
          size="small"
          checked={value}
          onChange={() => onChange?.(!value)}
          name="Trial"
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        <div>
          <p
            style={{
              marginLeft: spacing(2),
              fontSize: fontSizes.lg,
              fontWeight: fontWeights.semibold
            }}
          >
            30 day trial
            <span
              style={{
                marginLeft: spacing(3),
                fontSize: fontSizes.md,
                fontWeight: fontWeights.normal,
                color: colors.gray[600]
              }}
            >
              Unlock features that let you scale Weights & Biases
            </span>
          </p>
        </div>
      </Flex>

      <LimitsFeatures>
        {value ? (
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Feature enabled>
                  <strong>Scale</strong> horizontally or vertically
                </Feature>
                <Feature enabled>
                  Create up to <strong>10 teams</strong>
                </Feature>
                <Feature enabled>
                  Create up to <strong>100 users</strong>
                </Feature>
              </Grid.Column>
              <Grid.Column>
                <Feature enabled>Cloud storage buckets</Feature>
                <Feature enabled>High availability</Feature>
                <Feature enabled={null}>Service-level agreement</Feature>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Feature enabled>
                  <strong>Single</strong> deployable instance
                </Feature>
                <Feature enabled>
                  Create up to <strong>1 user</strong>
                </Feature>
                <Feature>No team creation</Feature>
              </Grid.Column>
              <Grid.Column>
                <Feature>Cloud storage buckets</Feature>
                <Feature>High availability</Feature>
                <Feature>Service-level agreement</Feature>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </LimitsFeatures>

      <LimitsFooter>
        <Flex>
          <ContactSalesLink />
          <EnterpriseLink />
        </Flex>

        {value && (
          <div style={{ color: colors.grey[600] }}>
            After your 30 day trial period, you will be required to contact
            sales for renewal.
          </div>
        )}
      </LimitsFooter>
    </LimitsBackground>
  )
}
