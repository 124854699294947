import { Card } from 'common/Card'
import { useOrganizationByIdsLoader } from 'common/hooks/useOrganizationByIdsLoader'
import { Page, PageContainer } from 'common/Page'
import { ReactTable } from 'common/ReactTable'
import { Section } from 'common/Section'
import { formatDistanceToNow, parseISO } from 'date-fns'
import {
  Deployment,
  DeploymentType,
  useDeploymentsQuery
} from 'generated/deploy'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Column, useTable } from 'react-table'

import { AdminHeader } from './components/AdminHeader'

const useDeploymentTable = () => {
  const { data } = useDeploymentsQuery({
    variables: {
      includeWorkspace: true,
      where: {
        type: {
          in: [DeploymentType.Azure, DeploymentType.Aws, DeploymentType.Gcp]
        }
      }
    }
  })

  const deployments = useMemo(
    () => (data?.deployments?.nodes ?? []) as unknown as Deployment[],
    [data]
  )

  const { getOrganization } = useOrganizationByIdsLoader(
    deployments.map(d => d.organizationId)
  )

  const columns = useMemo<Array<Column<Deployment>>>(
    () => [
      {
        Header: 'Deployment',
        accessor: 'organizationId',
        Cell: ({ value, row }) => (
          <>
            <Link to={`/org/${value}`}>
              {getOrganization(value)?.name ?? <i>Unknown Organization</i>}
            </Link>{' '}
            /{' '}
            <Link to={`/${row.original.id}`} target="_blank">
              {row.original.name}
            </Link>
          </>
        )
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Terraform',
        accessor: 'workspace',
        Cell: ({ value }) =>
          value == null ? (
            '-'
          ) : (
            <a
              href={`https://app.terraform.io/app/weights-and-biases/workspaces/${value.name}`}
            >
              {value}
            </a>
          )
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: ({ value }) => <>{formatDistanceToNow(parseISO(value))}</>
      }
    ],
    [getOrganization]
  )

  return useTable({
    data: deployments,
    columns
  })
}

const AdminLogs: React.FC = () => {
  return (
    <Page title="Logs">
      <PageContainer maxWidth="xl">
        <AdminHeader />
        <Section>
          <Card>
            <ReactTable
              {...useDeploymentTable()}
              style={{
                borderTop: 'none',
                borderBottom: 'none',
                marginTop: 0
              }}
            />
          </Card>
        </Section>
      </PageContainer>
    </Page>
  )
}
export default AdminLogs
