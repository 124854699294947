import Box from '@mui/material/Box'
import { PageHeader } from 'common/Page'
import { Link, useLocation } from 'react-router-dom'
import { Tab } from 'semantic-ui-react'

const AdminPanelTabs: React.FC = () => {
  const adminPath = `/admin`
  const panes = [
    {
      menuItem: {
        as: Link,
        content: 'Overview',
        to: adminPath,
        excat: true
      }
    },
    {
      menuItem: {
        as: Link,
        content: 'Orders',
        to: `${adminPath}/orders`
      }
    },
    {
      menuItem: {
        as: Link,
        content: 'Search',
        to: `${adminPath}/search`
      }
    },
    {
      menuItem: {
        as: Link,
        content: 'Managed Installs',
        to: `${adminPath}/managed-installs`
      }
    }
  ]

  const { pathname } = useLocation()
  const activeIndex = panes.findIndex(s =>
    s.menuItem.excat
      ? s.menuItem.to === pathname
      : pathname.startsWith(s.menuItem.to)
  )

  return (
    <Tab
      renderActiveOnly={false}
      activeIndex={activeIndex}
      menu={{ secondary: true, pointing: true }}
      panes={panes}
    />
  )
}

export const AdminHeader: React.FC = () => {
  return (
    <>
      <PageHeader>Admin Panel</PageHeader>
      <Box pt={4}>
        <AdminPanelTabs />
      </Box>
    </>
  )
}
