import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { useTheme } from '@wandb/ui'
import { Card } from 'common/Card'
import { Page, PageContainer } from 'common/Page'
import { Section } from 'common/Section'
import { parseISO } from 'date-fns'
import { formatDistanceToNowStrict } from 'date-fns/esm'
import { useMemo, useState } from 'react'

import { AdminHeader } from './components/AdminHeader'

const parse = (r: string) => {
  try {
    return JSON.parse(r)
  } catch {
    return r
  }
}

const rowHasError = (r: any) => r.message.toLowerCase().includes('error')

const Row: React.FC<{ row: any }> = ({ row }) => {
  const { colors } = useTheme()
  const timeAgo = formatDistanceToNowStrict(parseISO(row.time))
  const hasError = rowHasError(row)
  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        backgroundColor: hasError ? colors.red[100] : null
      }}
    >
      <TableCell sx={{ width: 80 }}>{timeAgo}</TableCell>
      <TableCell>{row.message}</TableCell>
    </TableRow>
  )
}

const Logs: React.FC<{ content: string }> = ({ content }) => {
  const lines = useMemo(
    () =>
      content
        .split('\n')
        .map(parse)
        .filter(r => typeof r === 'object')
        .filter(r => rowHasError(r))
        .reverse(),
    [content]
  )
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Time Ago</TableCell>
          <TableCell>Message</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lines.map((row: any, idx) => (
          <Row key={idx} row={row} />
        ))}
      </TableBody>
    </Table>
  )
}

const AdminLogs: React.FC = () => {
  const [content, setContent] = useState('')
  const handleFile = (file?: File | null) => {
    if (file == null) return
    const fr = new FileReader()
    fr.onloadend = (e: any) => setContent(e.target.result)
    fr.readAsText(file)
  }

  return (
    <Page title="Logs">
      <PageContainer maxWidth="xl">
        <AdminHeader />
        <Section>
          <Button variant="contained" component="label">
            Upload File
            <input
              type="file"
              hidden
              onChange={e => handleFile(e.target?.files?.[0])}
            />
          </Button>
        </Section>
        <Section>
          <Card>
            <Logs content={content} />
          </Card>
        </Section>
      </PageContainer>
    </Page>
  )
}
export default AdminLogs
