import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useViewer } from './useViewer'

export const useNotAdminRedirect = () => {
  const { isViewerAdmin } = useViewer()
  const history = useHistory()
  useEffect(() => {
    if (!isViewerAdmin) history.push('/')
  }, [history, isViewerAdmin])
}
