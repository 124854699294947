import { foundations } from '@wandb/ui'
import { ReleaseStatus } from 'generated/deploy'
import { Icon, IconProps } from 'semantic-ui-react'

const pending: Partial<IconProps> = {
  name: 'clock outline',
  style: { color: foundations.colors.gray[700] }
}

const loading: Partial<IconProps> = {
  name: 'circle notch',
  style: { color: foundations.colors.blue[500] },
  loading: true
}

const success: Partial<IconProps> = {
  name: 'check circle outline',
  style: { color: foundations.colors.green[500] }
}

const error: Partial<IconProps> = {
  name: 'times circle outline',
  style: { color: foundations.colors.red[500] }
}

const canceled: Partial<IconProps> = {
  name: 'times circle outline',
  style: { color: foundations.colors.gray[500] }
}

export const statusIcon: Record<ReleaseStatus, Partial<IconProps> | undefined> =
  {
    Pending: pending,
    Applied: success,
    ApplyQueued: loading,
    Applying: loading,
    Canceled: canceled,
    Confirmed: success,
    CostEstimated: loading,
    CostEstimating: loading,
    Discarded: error,
    Errored: error,
    ForceCanceled: error,
    PlanQueued: pending,
    Planned: success,
    PlannedAndFinished: success,
    Planning: loading,
    PolicyChecked: pending,
    PolicyChecking: pending,
    PolicyOverride: pending,
    PolicySoftFailed: pending
  }

export const ReleaseStatusIcon: React.FC<
  IconProps & { status?: ReleaseStatus }
> = props => {
  return (
    <Icon {...statusIcon[props.status ?? ReleaseStatus.Pending]} {...props} />
  )
}
