import Container from '@mui/material/Container'
import { Page, PageContainer } from 'common/Page'

import { DeploymentHeader } from './components/DeploymentHeader'
import { DeploymentSettingsConfig } from './components/settings/DeploymentSettingsConfig'
import { DeploymentSettingsDelete } from './components/settings/DeploymentSettingsDelete'
import { DeploymentSettingsGeneral } from './components/settings/DeploymentSettingsGeneral'
import { DeploymentSettingsNotifications } from './components/settings/DeploymentSettingsNotifications'
import { useRouteDeployment } from './components/useRouteDeployment'

const DeploymentPageSettings: React.FC = () => {
  const { deployment } = useRouteDeployment()
  return (
    <Page title="Deployment Settings">
      <PageContainer>
        <DeploymentHeader />
        <Container maxWidth="md">
          <DeploymentSettingsGeneral />
          {deployment?.terraformWorkspaceId && (
            <>
              <DeploymentSettingsConfig />
              <DeploymentSettingsNotifications />
            </>
          )}
          <DeploymentSettingsDelete />
        </Container>
      </PageContainer>
    </Page>
  )
}

export default DeploymentPageSettings
