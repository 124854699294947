import { HighlightedCode } from 'common/HighlightedCode'

import { InfoSection } from './Utils'

export const Kubernetes: React.FC = () => {
  return (
    <div>
      <InfoSection>
        The following k8s yaml can be customized but should serve as a basic
        foundation for configuring local in kubernetes. It&apos;s important to
        note you must specify a bucket to avoid needing a persistent volume.
      </InfoSection>
      <HighlightedCode language="yaml" showCopyButton>
        {`apiVersion: apps/v1
kind: Deployment
metadata:
  name: wandb
  labels:
    app: wandb
spec:
  strategy:
    type: RollingUpdate
  replicas: 1
  selector:
    matchLabels:
      app: wandb
  template:
    metadata:
      labels:
        app: wandb
    spec:
      containers:
        - name: wandb
          env:
            - name: LICENSE
              value: XXXXXXXXXXXXXXX
            - name: BUCKET
              value: s3://YOUR_BUCKET_NAME
            - name: BUCKET_QUEUE
              value: sqs://YOUR_QUEUE_NAME
            - name: AWS_REGION
              value: us-west2
          imagePullPolicy: IfNotPresent
          image: wandb/local:latest
          ports:
            - name: http
              containerPort: 8080
              protocol: TCP
          volumeMounts:
            - name: wandb
              mountPath: /vol
          livenessProbe:
            httpGet:
              path: /healthz
              port: http
          readinessProbe:
            httpGet:
              path: /ready
              port: http
          resources:
            requests:
              cpu: "2000m"
              memory: 4G
            limits:
              cpu: "4000m"
              memory: 8G
---
apiVersion: v1
kind: Service
metadata:
  name: wandb-service
spec:
  type: NodePort
  selector:
    app: wandb
  ports:
    - protocol: TCP
      port: 80
      targetPort: 8080
---
apiVersion: extensions/v1beta1
kind: Ingress
metadata:
  name: wandb-ingress
  annotations:
    kubernetes.io/ingress.class: nginx
spec:
  backend:
    serviceName: wandb-service
    servicePort: 80`}
      </HighlightedCode>
    </div>
  )
}
