import { useTheme } from '@wandb/ui'
import { Card, CardContent, CardHeader } from 'common/Card'
import { Section } from 'common/Section'
import { OrganizationQuery } from 'generated/gorilla'

const Info: React.FC<{ name: string; value?: React.ReactNode }> = ({
  name,
  value
}) => {
  const { spacing, colors, fontWeights } = useTheme()
  return (
    <div style={{ margin: spacing(2, 0) }}>
      <div style={{ fontWeight: fontWeights.bold, color: colors.grey[600] }}>
        {name}
      </div>
      <div>{value}</div>
    </div>
  )
}

export const OrganizationCardInfo: React.FC<{
  organization: OrganizationQuery['organization']
}> = ({ organization }) => {
  return (
    <Section>
      <Card>
        <CardContent>
          <CardHeader>Organization</CardHeader>
          <Info name="ID" value={organization?.id} />
          <Info name="Members" value={organization?.members.length} />
          <Info name="Invites" value={organization?.pendingInvites.length} />
        </CardContent>
      </Card>
    </Section>
  )
}
