import { Box } from '@mui/material'
import { styled, useTheme } from '@wandb/ui'
import { Flex } from 'common/Flex'
import { useAuth } from 'common/hooks/useAuth'
import { WBLogo } from 'common/navbar/WBLogo'
import { Page } from 'common/Page'
import { useHistory } from 'react-router-dom'
import { Button, Header, Icon, Popup } from 'semantic-ui-react'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.white};
  font-size: ${p => p.theme.fontSizes.xl};
  letter-spacing: ${p => p.theme.letterSpacings.wide};
`

const Feature: React.FC = ({ children }) => {
  const { spacing, colors } = useTheme()
  return (
    <div style={{ margin: spacing(4, 0) }}>
      <Icon
        name="check"
        style={{
          marginTop: 1,
          width: spacing(12),
          color: colors.green[400]
        }}
      />
      <span>{children}</span>
    </div>
  )
}

const WhyButton: React.FC = () => {
  const { colors, fontSizes } = useTheme()
  return (
    <Popup
      position="bottom center"
      content={
        <>
          Your W&amp;B cloud account is used to create and manage self-hosted
          deployments and get licenses, but your self-hosted deployment itself
          is not connected to the W&amp;B Cloud.
        </>
      }
      trigger={
        <Box
          color={colors.gray[500]}
          mt={15}
          fontSize={fontSizes.md}
          style={{ cursor: 'pointer' }}
        >
          <Icon name="question circle outline" />
          <span>Why do I need a W&amp;B cloud account to setup local?</span>
        </Box>
      }
    />
  )
}

const LocalSignUp: React.FC = () => {
  const { spacing, colors, fontSizes, fontWeights } = useTheme()
  const { loading, login, isLoggedIn } = useAuth()

  const history = useHistory()
  if (!loading && isLoggedIn) {
    history.push('/deploy')
  }

  return (
    <Page title="Local Sign Up">
      <div
        style={{
          position: 'absolute',
          color: colors.white,
          minWidth: '100vw',
          minHeight: '100vh',
          backgroundColor: colors.gray[900],
          textAlign: 'center'
        }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <WBLogo />
          <Box px={5}>
            <Button
              as="a"
              href="https://wandb.ai/site/contact"
              secondary
              style={{
                color: colors.gray[500],
                fontWeight: fontWeights.normal
              }}
            >
              Contact Us
            </Button>
            <Button basic color="yellow" onClick={() => login()}>
              Login
            </Button>
          </Box>
        </Flex>
        <Box p={8} mt={10}>
          <WBLogo scale={1.2} />
          <Flex
            style={{ margin: spacing(14, 0, 8, 0) }}
            justifyContent="center"
            alignItems="center"
          >
            <h1 style={{ fontSize: fontSizes.xl5 }}>W&amp;B Deploy Manager</h1>
          </Flex>

          <Content>
            <Header
              style={{
                color: colors.gray[500],
                textAlign: 'center',
                fontWeight: fontWeights.normal,
                maxWidth: spacing(150)
              }}
            >
              Log in or sign up with your W&amp;B cloud account to manage
              self-hosted versions of W&amp;B.
            </Header>

            <Box textAlign="left" margin="auto" display="block" pr={8}>
              <Feature>Manage and create teams</Feature>
              <Feature>Get your local license</Feature>
              <Feature>Add cloud storage buckets</Feature>
            </Box>

            <Button
              size="big"
              primary
              style={{ marginTop: spacing(12), width: spacing(80) }}
              content="Continue"
              onClick={() => login()}
            />
            <WhyButton />
          </Content>
        </Box>
      </div>
    </Page>
  )
}

export const publicRoute = true
export const hideNavbar = true
export default LocalSignUp
