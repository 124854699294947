import { useOrganizationByIdsLoader } from 'common/hooks/useOrganizationByIdsLoader'
import { useDeploymentQuery } from 'generated/deploy'
import { useMemo } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbSectionProps,
  SemanticShorthandCollection
} from 'semantic-ui-react'

import * as S from './Navbar.styles'

type ParamProperties = {
  deployment: string
  release: string
}

const deployPath = '/:deployment'

function useParams() {
  const { pathname } = useLocation()
  const matchDeploy = matchPath<ParamProperties>(pathname, {
    path: deployPath
  })
  return { ...matchDeploy?.params }
}

const useRouteDeployment = () => {
  const { deployment: deploymentId } = useParams()
  const deploymentQuery = useDeploymentQuery({
    variables: { id: deploymentId ?? '' },
    skip: deploymentId == null
  })
  return useMemo(() => deploymentQuery.data?.deployment, [deploymentQuery])
}

/**
 * Create navbar breadcrumbs sections based on url
 */
const useSections = () => {
  const history = useHistory()

  const deployment = useRouteDeployment()
  const organizationId = deployment?.organizationId
  const { getOrganization } = useOrganizationByIdsLoader([organizationId])
  const organization =
    organizationId != null ? getOrganization(organizationId) : null

  return useMemo(() => {
    const sections: SemanticShorthandCollection<BreadcrumbSectionProps> = []

    if (deployment)
      sections.push({
        key: 'Deployments',
        content: 'Deployments',
        link: true,
        onClick: () => history.push('/')
      })

    if (organizationId)
      sections.push({
        key: 'Organization',
        content: organization?.name ?? organizationId,
        link: true,
        onClick: () => history.push(`/org/${organizationId}`)
      })

    if (deployment)
      sections.push({
        key: 'Deployment',
        content: deployment.name,
        link: true,
        onClick: () => history.push(`/${deployment.id}`)
      })

    return sections
  }, [organizationId, organization, deployment, history])
}

export const NavbarBreadcrumbs: React.FC = () => {
  const sections = useSections()
  return (
    <S.NavbarBreadcrumbWrapper>
      <Breadcrumb sections={sections} icon="right angle" />
    </S.NavbarBreadcrumbWrapper>
  )
}
