import { useTheme } from '@wandb/ui'
import { Card, CardContent } from 'common/Card'
import { useMatchSorter } from 'common/hooks/useMatchSorter'
import { useOrganizationByIdsLoader } from 'common/hooks/useOrganizationByIdsLoader'
import { Page, PageContainer } from 'common/Page'
import { ReactTable } from 'common/ReactTable'
import { Section } from 'common/Section'
import { formatDistanceToNow, parseISO } from 'date-fns/esm'
import { Deployment, useDeploymentsQuery } from 'generated/deploy'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Column, useTable } from 'react-table'
import { Input } from 'semantic-ui-react'

import { AdminHeader } from './components/AdminHeader'

const useDeploymentTable = (search: string) => {
  const { data } = useDeploymentsQuery()
  const deployments = useMemo(
    () => (data?.deployments?.nodes ?? []) as unknown as Deployment[],
    [data]
  )

  const { getOrganization } = useOrganizationByIdsLoader(
    deployments.map(d => d.organizationId)
  )

  const columns = useMemo<Array<Column<Deployment>>>(
    () => [
      {
        Header: 'Deployment',
        accessor: 'organizationId',
        Cell: ({ value, row }) => (
          <>
            <Link to={`/org/${value}`}>
              {getOrganization(value)?.name ?? <i>Unknown Organization</i>}
            </Link>{' '}
            /{' '}
            <Link to={`/${row.original.id}`} target="_blank">
              {row.original.name}
            </Link>
          </>
        )
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Terraform',
        accessor: 'terraformWorkspaceId',
        Cell: ({ value }) =>
          value == null ? (
            '-'
          ) : (
            <a href={`https://app.terraform.com/${value}`}>{value}</a>
          )
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: ({ value }) => <>{formatDistanceToNow(parseISO(value))}</>
      }
    ],
    [getOrganization]
  )

  const deploymentsFiltered = useMatchSorter(deployments, search, {
    keys: ['name', 'organization.name'],
    debounce: 400
  })

  return useTable({
    data: deploymentsFiltered,
    columns
  })
}

const AdminDeployments: React.FC = () => {
  const [search, setState] = useState('')
  const table = useDeploymentTable(search)
  const { colors, fontSizes } = useTheme()
  return (
    <Page title="Admin Deployments">
      <PageContainer maxWidth="xl">
        <AdminHeader />
        <Section>
          <Card>
            <CardContent>
              <Input
                value={search}
                onChange={e => setState(e.target.value)}
                size="small"
                placeholder="Search by organization or deployment name"
                style={{ width: '100%' }}
              />
              <span style={{ color: colors.gray[500], fontSize: fontSizes.sm }}>
                This only filter deployments that are loaded in the table below.
                This doesnt search over all organizations or deployments that
                exist.
              </span>
            </CardContent>
            <ReactTable
              {...table}
              style={{
                border: 'none',
                borderTop: `1px solid ${colors.gray[300]}`,
                marginTop: 0
              }}
            />
          </Card>
        </Section>
      </PageContainer>
    </Page>
  )
}

export default AdminDeployments
