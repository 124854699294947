/* eslint-disable @typescript-eslint/ban-types */
import { createGlobalStyle } from 'styled-components'

export const WBTheme = createGlobalStyle(({ theme }) => {
  return `
    body {
      background: ${(theme as any).colors.gray[100]};
    }
  `
})
