import styled, { CSSProperties } from 'styled-components'

export const Flex = styled.div<
  Pick<
    CSSProperties,
    'flexDirection' | 'alignItems' | 'alignContent' | 'justifyContent'
  >
>`
  display: flex;
  flex-direction: ${p => p.flexDirection};
  align-items: ${p => p.alignItems};
  justify-content: ${p => p.justifyContent};
  align-content: ${p => p.alignContent};
`

export const Spacer = styled.div`
  flex-grow: 1;
`
