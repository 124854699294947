import { HighlightedCode } from 'common/HighlightedCode'

import { InfoSection } from './Utils'

export const GcpComputeEngine: React.FC = () => {
  return (
    <>
      <InfoSection>
        You can run wandb/local on any Compute Engine instance that also has
        Docker installed. We suggest at least 8GB of RAM and 4vCPU&apos;s.
        Simply run the following command to launch the container:
      </InfoSection>
      <HighlightedCode language="bash">
        {`docker run --rm -d \\
    -v wandb:/vol -p 8080:8080 \\
    --name wandb-local wandb/local`}
      </HighlightedCode>
    </>
  )
}
