import Box from '@mui/material/Box'
import { styled, useTheme } from '@wandb/ui'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { useCopyToClipboard } from 'react-use'
import { Icon } from 'semantic-ui-react'
import { createGlobalStyle } from 'styled-components'

const Style = createGlobalStyle(() => {
  return `
    .syntaxhighlighter { 
      overflow-y: hidden !important; 
      overflow-x: auto !important; 
    }
  `
})

export type HighlightedCodeProps = {
  language: string
  children: string
  showCopyButton?: boolean
}

const CopyIcon = styled(Icon as any)`
  position: absolute;
  right: 0;
  top: 0;
  margin: ${p => p.theme.spacing(4)} !important;
  color: gray;
  cursor: pointer;
  &:hover {
    color: white;
  }
`

export const HighlightedCode: React.FC<HighlightedCodeProps> = props => {
  const { language, children, showCopyButton } = props
  const { spacing } = useTheme()
  const [{ value }, copyToClipboard] = useCopyToClipboard()
  return (
    <Box position="relative">
      {showCopyButton && (
        <CopyIcon
          name={value === children ? 'check' : 'clone'}
          onClick={() => copyToClipboard(children)}
        />
      )}
      <Style />
      <SyntaxHighlighter
        language={language}
        style={darcula}
        customStyle={{
          overflowY: 'auto !important',
          padding: spacing(6),
          borderRadius: spacing(1)
        }}
      >
        {children}
      </SyntaxHighlighter>
    </Box>
  )
}
